import React, { useEffect, useState } from "react";
import FormCotizador1 from "../componentes/FormCotizador1";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import styled from "styled-components";
import trama from "../images/trama.png";
import "../styles.css";
import FooterMobile from "../componentes/FooterMobile";
import ModalConsignar from "../componentes/ModalConsignar";
import WhatsAppBtn from "../componentes/WhatsAppBtn";

const MarginNav = styled.div`
  display: block;
  background-color: black;
  padding-top: 114px;
  @media (max-width: 576px) {
    padding-top: 48px;
  }
`;
const ContainerStyles = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;

  .margin-div {
    height: 60px;
    width: 100%;
  }
  @media (max-width: 576px) {
    .margin-div {
      height: 30px;
      width: 100%;
    }
  }
`; // así le quito el espacio blanco de los bordes

const TramaContainer = styled.div`
  background-image: url(${trama}); /* Utiliza la imagen trama como fondo */
  background-size: cover; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
`;
const StyledBannerTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 288px;
  align-items: center;
  font-family: var(--font-family);
  text-align: center;

  .title {
    padding: 0;
    margin-top: 80px;
    margin-bottom: 22px;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  p {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
  @media (max-width: 576px) {
    height: 165px;
    .title {
      padding: 0;
      margin-top: 22px;
      margin-bottom: 14px;
      font-weight: 700;
      font-size: 21px;
      line-height: 21px;
    }
    p {
      padding: 0;
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }
  }
`;
const ContainerForm = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  margin-bottom: 60px;

  @media (max-width: 576px) {
    display: block;
    margin-bottom: 0px;
  }
`;

const FormMobile = styled.div`
  display: none;
  flex-direction: column;
  font-family: var(--font-family);
  width: 100%;
  height: 371px;

  .title {
    color: var(--prime-color);
    font-weight: 700;
  }
  .title-form {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 18.5px;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    margin-top: 34px;
    flex-direction: wrap;
  }

  .options-container {
    display: flex;
    /* padding: 0; */
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  @media (max-width: 576px) {
    display: flex;
    align-items: center;
    max-height: 280px;
    height: auto;
    width: 100%;

    .title {
      color: var(--prime-color);
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
    }
    .info-container {
      display: flex;
      flex-direction: column;
      padding-right: 30px;
      height: auto;
      justify-content: left;
      font-size: 14px;
      line-height: 14px;
    }
    .title-form {
      font-size: 15px;
      line-height: 15px;
      font-weight: 700;
    }
    .consignar-btn {
      background-color: transparent;
      border: none;
      font-family: var(--font-family);
      outline: none;
      width: 137px;
      height: 32px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      text-decoration: underline;
      z-index: 1000;
      margin-left: -25px;
    }
  }
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  width: 100%;
  height: 371px;

  .consignar-btn {
    background-color: transparent;
    border: none;
    font-family: var(--font-family);
    outline: none;
    width: 137px;
    height: 32px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    text-decoration: underline;
    margin-left: -15px;
  }
  .title {
    color: var(--prime-color);
    font-weight: 700;
  }
  .title-form {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 18.5px;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    margin-top: 34px;
    flex-direction: wrap;
    height: 100%;
  }

  .options-container {
    display: flex;
    /* padding: 0; */
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

const BotonOpcion = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  button {
    width: 30%;
    outline: none;
    height: 42px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .option-btn {
    /* padding: 8px 20px; */
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 6px;
    color: var(--gris-medio);
    background-color: #ffffff;
    border: 1px solid #595e62;
  }
  .option-btn:hover,
  .option-btn:focus {
    color: white !important;
    background-color: #595e62 !important;
    border-color: black !important;
    font-weight: 400;
  }
  .active-btn {
    /* padding: 8px 20px; */
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 6px;
    color: #ffffff !important;
    background-color: var(--prime-color) !important;
    border: none;
  }
  .option-btn:disabled {
    color: #b5b5b5 !important;
    background-color: #ffffff !important;
    border-color: #b5b5b5 !important;
    font-weight: 500;
  }
  @media (max-width: 576px) {
    button {
      width: 29%;
      outline: none;
      height: 42px;
    }
    /* gap: 20px; */
  }
`;

const CotizadorScreen1 = () => {
  const [modalConsignar, setModalConsignar] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Vender");
  const [refreshWindow, setRefreshWindow] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleConsignarClick = () => {
    setModalConsignar(true);
  };
  const closeModalConsignar = () => {
    setModalConsignar(false);
  };
  useEffect(() => {
    if (refreshWindow) {
      window.location.reload();
      setRefreshWindow(false);
    }
  }, [refreshWindow]);
  // Textos correspondientes a cada opción
  const infoTexts = {
    Vender: {
      desktop: {
        title: "¿Cómo funciona la venta?",
        content:
          "Para aquellos casos que quieran aprovechar la oferta de dinero de manera INMEDIATA, en la moneda de cambio que desee, Pesos o Dólares.",
      },
      mobile: {
        title: "Vender",
        content:
          "Para aquellos casos que quieran aprovechar la oferta de dinero de manera INMEDIATA, en la moneda de cambio que desee, Pesos o Dólares.",
      },
    },
    Permutar: {
      desktop: {
        title: "¿Cómo funciona la permuta?",
        content:
          "Procedimiento en el que usted hace entrega de su unidad como parte de pago de otro vehículo que este en nuestro STOCK, ya sea Usado o 0km.",
      },
      mobile: {
        title: "Permutar",
        content:
          "Procedimiento en el que usted hace entrega de su unidad como parte de pago de otro vehículo que este en nuestro STOCK, ya sea Usado o 0km.",
      },
    },
    Consignar: {
      desktop: {
        title: "¿Cómo funciona la consignación?",
        content:
          "El contrato de consignación de un auto usado es un acuerdo entre el cliente, quien es el propietario del vehículo, y la concesionaria. Bajo este contrato, el cliente otorga a la concesionaria el derecho de vender su auto en su nombre. Ambas partes acuerdan un precio de venta para el vehículo. La concesionaria retiene una comisión por sus servicios de venta. Durante el período de consignación, el cliente tiene la responsabilidad de mantener el vehículo en buen estado. La concesionaria asume la tarea de promocionar y vender el vehículo en nombre del cliente. Se establece una duración específica para el contrato. Es importante destacar que la propiedad del vehículo sigue siendo del cliente hasta que se complete la venta y se realice la transferencia del título al nuevo comprador.",
      },
      mobile: {
        title: "Consignar",
        content:
          "Desde Nave.com.ar, vamos a ofrecerles un servicio, el cual consiste en poder venderle a usted un vehículo de su propiedad, utilizando todas nuestras plataformas y nuestra red de contactos. TODO, para facilitarle el trabajo “engorroso” de publicar su vehículo como particular y el deber de hacer todos los trámites correspondientes.",
      },
    },
  };

  return (
    <ContainerStyles className="container-fluid">
      {modalConsignar && (
        <ModalConsignar closeModalConsignar={closeModalConsignar} />
      )}
      <Navbar />
      <MarginNav />
      <TramaContainer>
        <div className="container">
          <div className="row">
            <StyledBannerTitle>
              <div className="col-md-6 col-10">
                <p className="title ">Obtené el valor que merecés</p>

                <p>
                  Ingresá la información de tu vehículo y descubrí su valor en
                  el mercado. <br /> ¡Obtené la mejor oferta para tu auto!
                </p>
              </div>
            </StyledBannerTitle>
          </div>
        </div>
      </TramaContainer>
      <div className="margin-div"></div>
      <div className="container">
        <div className="row">
          <ContainerForm>
            <FormMobile className="col-12">
              <div>
                <p className="title-form">Motivo por el que querés cotizar</p>
              </div>
              <div className="options-container">
                <BotonOpcion>
                  {["Vender", "Permutar", "Consignar"].map((option) => (
                    <button
                      key={option}
                      className={`${
                        selectedOption === option ? "active-btn" : "option-btn"
                      }`}
                      onClick={() => handleOptionClick(option)}
                    >
                      {option}
                    </button>
                  ))}
                </BotonOpcion>
              </div>
              <div className="info-container">
                {selectedOption && (
                  <>
                    <p className="title">
                      {infoTexts[selectedOption].mobile.title}
                    </p>
                    <p>{infoTexts[selectedOption].mobile.content}</p>
                  </>
                )}
                {selectedOption === "Consignar" && (
                  <button
                    className="consignar-btn"
                    onClick={handleConsignarClick}
                  >
                    Seguir leyendo
                  </button>
                )}
              </div>
            </FormMobile>
            <div className="offset-md-1 col-md-4">
              <Form>
                <div>
                  <p className="title-form">Motivo por el que querés cotizar</p>
                </div>
                <div className="options-container">
                  <BotonOpcion>
                    {["Vender", "Permutar", "Consignar"].map((option) => (
                      <button
                        key={option}
                        className={`${
                          selectedOption === option
                            ? "active-btn"
                            : "option-btn"
                        }`}
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </BotonOpcion>
                </div>
                <div className="info-container">
                  {selectedOption && (
                    <>
                      <p className="title">
                        {infoTexts[selectedOption].desktop.title}
                      </p>
                      <p>{infoTexts[selectedOption].desktop.content}</p>
                    </>
                  )}
                  {selectedOption === "Consignar" && (
                    <button
                      className="consignar-btn"
                      onClick={handleConsignarClick}
                    >
                      Más información
                    </button>
                  )}
                </div>
              </Form>
            </div>
            <FormCotizador1
              refreshWindow={refreshWindow}
              setRefreshWindow={setRefreshWindow}
              motivo={selectedOption}
            />
          </ContainerForm>
        </div>
      </div>
      <WhatsAppBtn />
      <Footer />
      <FooterMobile />
    </ContainerStyles>
  );
};

export default CotizadorScreen1;
