import React from "react";
import styled from "styled-components";

const StyledLocationsDiv = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 130px;
  padding-right: 0px !important;

  .suc-container {
    display: flex;
    flex-direction: column;

    @media (max-width: 578px) {
      width: 100%;
      margin-top: 35px;
      justify-content: center;
      align-items: center;
      text-align: center;

      .info {
        font-family: var(--roboto);
        font-size: 13px;
        line-height: 13px;
        width: 60%;
      }
      .title-info {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 15px;
      }
    }
  }

  .map-container {
    position: relative; /* Añade esta propiedad */
  }

  .map-img-container {
    position: relative; /* Añade esta propiedad */
    justify-content: center;
    padding: 0;
    margin: 0;
    align-items: center;
    height: 310px;
    width: 100%;
    overflow: hidden; /* Añade esta propiedad */
    border-radius: 16px;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
  }
  p {
    margin-bottom: 5px;
  }
  button {
    margin-top: 24px;
    width: 50%;
    height: 32px;
    outline: none;
    border: none;
    justify-content: center;
    padding: 0;
    align-items: center;

    @media (max-width: 578px) {
      height: 42px;
    }
  }

  @media (max-width: 578px) {
    padding-bottom: 10px;
  }
`;

const Title = styled.h3`
  padding-top: 64px;
  margin-bottom: 24px;

  @media (max-width: 578px) {
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    padding-top: 36px;
  }
`;

const NosotrosMapLocation = () => {
  const handleWhatsAppClick = () => {
    // Constrye el mensaje para WhatsApp
    const mensaje = `Deseo coordinar una visita a la agencia`;

    const whatsappNumber = "5491131138421";
    // Construye la URL de WhatsApp
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      mensaje
    )}`;

    // Abre la URL de WhatsApp
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="container">
      <div className="row">
        <StyledLocationsDiv>
          <div className="row">
            <div className="offset-md-1 col-md-5">
              <Title className="tt-second b-text ">¿Dónde encontrarnos?</Title>
            </div>
          </div>
          <div className="row">
            <div className="offset-md-1 col-md-5 col-10 offset-1">
              <div className="map-container">
                <div className="map-img-container">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3278.1642132591046!2d-58.28557339999999!3d-34.751456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a32c2b8287e919%3A0x1f8bf7f6e7aa4d60!2sAv.%20Calchaqu%C3%AD%202705%2C%20Quilmes%20Oeste%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1715141276806!5m2!1ses!2sar"
                    width="400"
                    height="300"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="suc-container">
                <p className="b-text tx-medium title-info">
                  ¡Visítanos en nuestra sucursal!
                </p>
                <p className="r-text tx-medium info">
                  Estamos ubicados en Av. Calchaquí 2705 (Quilmes, Buenos
                  Aires), y te invitamos a conocernos en persona. Nuestro equipo
                  estará encantado de ayudarte a encontrar el automóvil
                  perfecto. ¡Te esperamos!
                </p>
                <button
                  className="medium-btn primary-btn"
                  onClick={handleWhatsAppClick}
                >
                  Coordiná tu visita
                </button>
              </div>
            </div>
          </div>
        </StyledLocationsDiv>
      </div>
    </div>
  );
};

export default NosotrosMapLocation;
