import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PolarizadoImg from "../images/polarizado_icon.png";
import ServiciosImg from "../images/seguros_icon.png";
import GestoriaImg from "../images/gestor_icon.png";

const StyledContainer = styled.div`
  display: none;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 60px;
  @media (max-width: 576px) {
    display: block;
  }
`;

const ServiceCardContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 151px;
`;

const ServiceCardWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
`;

const ServiceCard = styled.div`
  flex: 0 0 100%;
  justify-content: center;
  text-align: center;
  padding: 0 15px;
  font-size: var(--font-family);
  h3 {
    padding-top: 5px;
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
  }
  p {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    flex-wrap: wrap;
  }
`;

const CardImage = styled.img`
  max-width: 130px;
  max-height: 65px;
  height: 100%;
`;

const NavRadioContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const NavRadio = styled.input`
  display: none;
`;

const NavLabel = styled.label`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: ${(props) => (props.checked ? "black" : "white")};
  margin: 0 5px;
  cursor: pointer;
`;

const CarouselServiceCard = () => {
  const [currentCard, setCurrentCard] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const cards = [
    {
      image: ServiciosImg,
      title: "Seguros para vehículos",
      description:
        "Tu tranquilidad en cada kilómetro. Asegurá tu auto con nosotros.",
    },
    {
      image: PolarizadoImg,
      title: "Polarizado profesional",
      description: "Personalizamos tu vehículo según tus gustos y estilo.",
    },
    {
      image: GestoriaImg,
      title: "Gestoria automotor",
      description:
        "Simplificamos tus trámites. Ahorrá tiempo, ganá tranquilidad.",
    },
  ];

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (event) => {
    if (!isDragging) return;
    setTouchEndX(event.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    const touchDiff = touchStartX - touchEndX;
    if (touchDiff > 50 && currentCard < cards.length - 1) {
      setCurrentCard(currentCard + 1);
    } else if (touchDiff < -50 && currentCard > 0) {
      setCurrentCard(currentCard - 1);
    }
    setIsDragging(false);
    setTouchStartX(0);
    setTouchEndX(0);
  };

  return (
    <StyledContainer className="container">
      <ServiceCardContainer className="offset-2 col-8">
        <ServiceCardWrapper
          style={{ transform: `translateX(-${currentCard * 100}%)` }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {cards.map((card, index) => (
            <ServiceCard key={index}>
              <CardImage src={card.image} alt="service" />
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </ServiceCard>
          ))}
        </ServiceCardWrapper>
        <NavRadioContainer>
          {cards.map((_, index) => (
            <React.Fragment key={index}>
              <NavRadio
                type="radio"
                id={`nav-radio-${index}`}
                name="nav-radio"
                value={index}
                checked={currentCard === index}
                onChange={() => setCurrentCard(index)}
              />
              <NavLabel
                htmlFor={`nav-radio-${index}`}
                checked={currentCard === index}
              />
            </React.Fragment>
          ))}
        </NavRadioContainer>
      </ServiceCardContainer>
    </StyledContainer>
  );
};

export default CarouselServiceCard;
