import { createContext, useState } from "react";

const FilterContext = createContext();

const FilterProvider = ({ children }) => {
  const [selectedValues, setSelectedValues] = useState({});

  return (
    <FilterContext.Provider value={{ selectedValues, setSelectedValues }}>
      {children}
    </FilterContext.Provider>
  );
};

export { FilterProvider, FilterContext };
