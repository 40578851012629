import React, { useEffect, useRef, useState } from 'react'

const InputPrice = (props) => {
    const { value, onChange, placeholder, name } = props;
    const [formatPrice, setFormatPrice] = useState(null);
    const inputRef = useRef(null);
    
    useEffect(()=> {
        if(value)
            setFormatPrice(formatNumber(value.toString()))
    }, [value])

    const formatNumber = (value) => {
        // Eliminar cualquier punto previo y formatear el número
        const cleanedValue = value.replace(/\./g, "");
        
        // Aplicar formato de puntos de miles
        return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

    const handleOnChange = (e) => {
        const { value, selectionStart } = e.target;
        // Eliminar cualquier carácter no numérico excepto puntos
        const onlyNumbers = value.replace(/[^0-9]/g, "");
        // Eliminar cualquier punto previo para poder aplicar el nuevo formato
        const cleanedValue = value.replace(/\./g, "");
        const priceWithFormat = formatNumber(onlyNumbers)
        // Guardar la posición original del cursor
        const originalCursorPosition = selectionStart;

        setFormatPrice(priceWithFormat)
        onChange(e)
        setTimeout(() => {
            const newCursorPosition = originalCursorPosition + (priceWithFormat.length - cleanedValue.length);
            inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
          }, 0);
    }

    return (
        <input
            type="text"
            value={formatPrice}
            name={name}
            ref={inputRef}
            onChange={handleOnChange}
            placeholder={placeholder}
        />
    )
}

export default InputPrice