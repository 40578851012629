import React from "react";
import ExitImg from "../images/icon_exitmarca.png";
import styled from "styled-components";
import "../styles.css";
import { BASE_URL } from "../utils/ContextAxios";

const StyledCarItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  line-height: 14px;
  border: 2px dashed var(--prime-color);
  background-color: var(--gris-claro);
  font-family: var(--font-family);
  font-size: 14px;
  cursor: pointer;
  overflow-x: hidden;
  transition: opacity 0.5s ease;

  .info-car {
    height: 40px;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    p {
      padding: 0;
      margin: 0;
    }
  }
  .img-container {
    img {
      object-fit: cover;
      width: 65px;
      max-height: 50px;
    }
  }
  span {
    font-weight: 700;
    font-size: 16px;
  }

  .close-btn {
    position: absolute;
    top: 0px;
    right: 5px;
    height: 20px;
    width: 20px;
  }
  @media (max-width: 576px) {
    height: 65px;
    width: 100%;
    .image-container {
      height: 45px;
    }
    .info-car {
      display: none;
    }
    .close-btn {
      height: 14px;
      width: 14px;
    }
  }
`;

const ComparadorCarItem = ({ auto, onRemove }) => {
  return (
    <StyledCarItem onClick={onRemove}>
      <img src={ExitImg} alt="x" className="close-btn" />
      <div className="img-container">
        <img
          src={`${BASE_URL}/${auto.multimedia[0].path}`}
          alt="car-img"
        />
      </div>
      <div className="info-car">
        <p>
          <span className="bold">{auto.brand.name}</span>
        </p>
        <p> {auto.category.name}</p>
      </div>
    </StyledCarItem>
  );
};

export default ComparadorCarItem;
