import axios from "axios";
import { useAuth } from "./AuthContext";

// Define the base URL
const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";
const FRONTEND_URL = process.env.REACT_APP_ENV || "http://localhost:3000";
//OBTENER EL TOKEN
export const token = localStorage.getItem("token");

// Create an Axios instance with the new base URL
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": FRONTEND_URL,
  },
});

const useAxiosWithAuth = () => {
  const { token } = useAuth();

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": FRONTEND_URL,
    },
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return axiosInstance;
};

export default axiosInstance;
export { BASE_URL, useAxiosWithAuth };
