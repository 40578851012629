import React from "react";
import styled from "styled-components";
import WhatsApp from "../images/whatsapp_icon.png";

const Button = styled.button`
  position: fixed;
  bottom: 50px;
  right: 40px;
  z-index: 1001;
  outline: none;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: none;
  background: #25d366;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 720px) {
    width: 65px;
    height: 65px;
    right: 15px;
    bottom: 15px;
  }
`;

const WhatsAppBtn = () => {
  const whatsappNumber = "5491131138421";

  const handleClick = () => {
    const url = `https://wa.me/${whatsappNumber}?text=Hola!`;
    window.open(url, "_blank");
  };

  return (
    <Button onClick={handleClick}>
      <img src={WhatsApp} alt="img-whatsapp" className="img-icon" />
    </Button>
  );
};

export default WhatsAppBtn;
