import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FlechaCard from "../images/FlechaMenuCatalogo.png";
import "../styles.css";
import axiosInstance from "../utils/ContextAxios";

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 204px;
  transition: height 0.3s ease;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    list-style: none;
  }
  li {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--gris-claro);
    max-height: 100%;
    width: 100%;
    margin-bottom: 15px;
    padding: 0;
  }

  .menu-item {
    font-family: var(--font-family);
    font-size: 18px;
    height: 32px;
    width: 100%;
    line-height: 20px;
    font-weight: 700;
    display: flex;
    padding-left: 6px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .sub-menu {
    width: 100%;
    border: none;
    top: 100%;
    left: 0;
    display: flex;
  }

  .filter-section {
    width: 100%;
    align-items: center;
    margin-top: 6px;
    justify-content: center;
  }

  .filter-option {
    padding-left: 4px;
    color: #080c0d;
    font-size: 16px;
    align-items: center;
    line-height: 18.5px;
    input {
      margin-right: 4px;
    }
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

const categoryTitles = {
  doors: "Cant. de Puertas",
  seating: "Cant. de Asientos",
  seatMaterial: "Material de Asientos",
  mileage: "Kilometraje",
  traction: "Tracción",
  transmission: "Transmisión",
  category: "Tipo",
  fuel: "Combustible",
  color: "Color",
};

const CatalogoMenu = ({ onSelectedValuesChange }) => {
  const [selectedBrands, setSelectedBrands] = useState([]); // Ahora es un array
  const [selectedModelos, setSelectedModelos] = useState([]);

  const [expandedItems, setExpandedItems] = useState({
    mileage: false,
    brands: false,
    modelos: false,
    versions: false,
    category: false,
    fuel: false,
    traction: false,
    transmission: false,
    doors: false,
    seating: false,
    seatMaterial: false,
    color: false,
  });

  const [selectedValues, setSelectedValues] = useState({});
  const [options, setOptions] = useState({
    brands: [],
    modelos: [],
    versions: [],
    doors: [],
    seating: [],
    seatMaterial: [],
    mileage: [],
    traction: [],
    transmission: [],
    category: [],
    fuel: [],
    color: [],
  });

  // Simulación de datos locales
  const data = {
    brands: [],
    modelos: [],
    versions: [],
    doors: ["2", "3", "4", "5"],
    seating: ["2", "3", "4", "5"],
    seatMaterial: ["Tela", "Cuero", "Nylon", "Alcantra", "Vinilo", "Poliéster"],
    mileage: ["Menos de 50,000 km", "50,000 - 100,000 km", "Más de 100,000 km"],
    traction: ["Delantera", "Trasera", "Total", "4x4"],
    transmission: ["Manual", "Automática"],
    category: ["Auto", "SUV", "Camioneta", "Pick-Up", "Utilitario"],
    fuel: ["GNC", "Diésel", "Nafta", "Gasoil", "Eléctrico", "Biocombustible"],
    color: ["Rojo", "Azul", "Blanco", "Verde", "Negro", "Gris", "Amarillo"],
  };
  useEffect(() => {
    setOptions({
      brands: data.brands,
      modelos: data.modelos,
      versions: data.versions,
      doors: data.doors,
      seating: data.seating,
      seatMaterial: data.seatMaterial,
      mileage: data.mileage,
      traction: data.traction,
      transmission: data.transmission,
      category: data.category,
      fuel: data.fuel,
      color: data.color,
    });
  }, []);

  useEffect(() => {
    axiosInstance
      .get("/api/v1/car/brands")
      .then((response) => {
        const brands = response.data;
        //console.log(response.data);
        setOptions((prevOptions) => ({ ...prevOptions, brands }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  //carga opciones
  useEffect(() => {
    setOptions({
      brands: data.brands,
      modelos: data.modelos,
      versions: data.versions,
      doors: data.doors,
      traction: data.traction,
      transmission: data.transmission,
      category: data.category,
      fuel: data.fuel,
      color: data.color,
      seating: data.seating,
      seatMaterial: data.seatMaterial,
      mileage: data.mileage,
    });
  }, []);

  //fetch para las marcas
  useEffect(() => {
    axiosInstance
      .get("/api/v1/car/brands")
      .then((response) => {
        const brands = response.data;
        //console.log(response.data);
        setOptions((prevOptions) => ({ ...prevOptions, brands }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleItemClick = (category) => {
    setExpandedItems((prevItems) => ({
      ...prevItems,
      [category]: !prevItems[category],
    }));
  };

  const handleFilterChange = (category, option) => {
    setSelectedValues((prevSelectedValues) => {
      let categoryValues = prevSelectedValues[category] || [];

      // Si la opción ya está seleccionada, la eliminamos; si no, la añadimos
      if (categoryValues.includes(option)) {
        categoryValues = categoryValues.filter((value) => value !== option);
      } else {
        categoryValues = [...categoryValues, option];
      }

      const newSelectedValues = {
        ...prevSelectedValues,
        [category]: categoryValues,
      };

      onSelectedValuesChange(newSelectedValues);
      //console.log(newSelectedValues);
      return newSelectedValues;
    });
  };

  const handleMarcaChange = (selectedOption) => {
    setSelectedValues((prevSelectedValues) => {
      const newSelectedValues = { ...prevSelectedValues };

      if (prevSelectedValues.brand?.includes(selectedOption)) {
        newSelectedValues.brand = prevSelectedValues.brand.filter(
          (brand) => brand !== selectedOption
        );
        newSelectedValues.modelo = [];
        newSelectedValues.version = [];
      } else {
        newSelectedValues.brand = [
          ...(prevSelectedValues.brand || []),
          selectedOption,
        ];
        newSelectedValues.modelo = [];
        newSelectedValues.version = [];
      }

      const selectedBrand = options.brands.find(
        (brand) => brand.name === selectedOption
      );

      if (newSelectedValues.brand.includes(selectedOption)) {
        setSelectedBrands((prevSelectedBrands) => [
          ...prevSelectedBrands,
          selectedBrand,
        ]);
      } else {
        setSelectedBrands((prevSelectedBrands) =>
          prevSelectedBrands.filter((brand) => brand.name !== selectedOption)
        );
      }

      onSelectedValuesChange(newSelectedValues);
      //console.log(newSelectedValues);
      return newSelectedValues;
    });
  };

  const handleModeloChange = (selectedOption) => {
    setSelectedValues((prevSelectedValues) => {
      const newSelectedValues = { ...prevSelectedValues };
      const modelos = prevSelectedValues.modelo || [];

      if (modelos.includes(selectedOption)) {
        // Si el modelo ya está seleccionado, lo quitamos
        newSelectedValues.modelo = modelos.filter(
          (modelo) => modelo !== selectedOption
        );
      } else {
        // Si el modelo no está seleccionado, lo añadimos
        newSelectedValues.modelo = [...modelos, selectedOption];
      }

      const selectedModelo = options.modelos.find(
        (modelo) => modelo.name === selectedOption
      );

      if (newSelectedValues.modelo.includes(selectedOption)) {
        setSelectedModelos((prevSelectedModelos) => [
          ...prevSelectedModelos,
          selectedModelo,
        ]);
      } else {
        setSelectedModelos((prevSelectedModelos) =>
          prevSelectedModelos.filter((modelo) => modelo.name !== selectedOption)
        );
      }

      onSelectedValuesChange(newSelectedValues);
      //console.log(newSelectedValues);

      return newSelectedValues;
    });
  };
  const handleVersionChange = (selectedOption) => {
    setSelectedValues((prevSelectedValues) => {
      const newSelectedValues = { ...prevSelectedValues };
      const versions = prevSelectedValues.version || [];

      if (versions.includes(selectedOption)) {
        // Si la versión ya está seleccionada, la quitamos
        newSelectedValues.version = versions.filter(
          (version) => version !== selectedOption
        );
      } else {
        // Si la versión no está seleccionada, la añadimos
        newSelectedValues.version = [...versions, selectedOption];
      }

      onSelectedValuesChange(newSelectedValues);
      //console.log(newSelectedValues);
      return newSelectedValues;
    });
  };

  //acumular modelos
  useEffect(() => {
    if (selectedBrands.length > 0) {
      const allModelos = selectedBrands.reduce((acc, brand) => {
        return [...acc, ...brand.modelos];
      }, []);
      setOptions((prevOptions) => ({ ...prevOptions, modelos: allModelos }));
    }
  }, [selectedBrands]);

  //acumular versiones
  useEffect(() => {
    if (selectedModelos.length > 0) {
      const allVersions = selectedModelos.reduce((acc, modelo) => {
        return [...acc, ...modelo.versions];
      }, []);
      setOptions((prevOptions) => ({ ...prevOptions, versions: allVersions }));
    }
  }, [selectedModelos]);

  //console.log("versiones", options.versions);

  const renderFilterOptions = (category) => {
    return options[category].map((option) => {
      const optionName = option.name || option;
      return (
        <div className="filter-option" key={optionName || option._id}>
          <input
            type="checkbox"
            id={`${category}-${optionName}`}
            onChange={() => handleFilterChange(category, optionName)}
            checked={selectedValues[category]?.includes(optionName) || false}
          />
          <label htmlFor={`${category}-${optionName}`}>{optionName}</label>
        </div>
      );
    });
  };

  return (
    <StyledMenu>
      <ul>
        <li className="item-brand">
          <div className="menu-item" onClick={() => handleItemClick("brands")}>
            Marca
            <img
              src={FlechaCard}
              alt="Flecha"
              style={{
                transform: expandedItems["brands"] ? "rotate(180deg)" : "",
              }}
            />
          </div>
          {expandedItems["brands"] && (
            <div className="sub-menu">
              <div className="filter-section">
                {options.brands.map((brand) => (
                  <div className="filter-option" key={brand._id}>
                    <input
                      type="checkbox"
                      id={`brand-${brand.name}`}
                      onChange={() => handleMarcaChange(brand.name)}
                      checked={
                        selectedValues.brand?.includes(brand.name) || false
                      }
                    />
                    <label htmlFor={`brand-${brand.name}`}>{brand.name}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </li>
        {selectedValues.brand && selectedValues.brand.length > 0 && (
          <>
            <li className="item-modelo">
              <div
                className="menu-item"
                onClick={() => handleItemClick("modelos")}
              >
                Modelo
                <img
                  src={FlechaCard}
                  alt="Flecha"
                  style={{
                    transform: expandedItems["modelos"] ? "rotate(180deg)" : "",
                  }}
                />
              </div>
              {expandedItems["modelos"] && (
                <div className="sub-menu">
                  <div className="filter-section">
                    {options.modelos.map((modelo) => (
                      <div className="filter-option" key={modelo._id}>
                        <input
                          type="checkbox"
                          id={`modelo-${modelo.name}`}
                          onChange={() => handleModeloChange(modelo.name)}
                          checked={
                            selectedValues.modelo?.includes(modelo.name) ||
                            false
                          }
                        />
                        <label htmlFor={`modelo-${modelo.name}`}>
                          {modelo.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </li>
            {selectedValues.modelo && selectedValues.modelo.length > 0 && (
              <li className="item-version">
                <div
                  className="menu-item"
                  onClick={() => handleItemClick("versions")}
                >
                  Versión
                  <img
                    src={FlechaCard}
                    alt="Flecha"
                    style={{
                      transform: expandedItems["versions"]
                        ? "rotate(180deg)"
                        : "",
                    }}
                  />
                </div>
                {expandedItems["versions"] && (
                  <div className="sub-menu">
                    <div className="filter-section">
                      {options.versions.map((version) => (
                        <div className="filter-option" key={version._id}>
                          <input
                            type="checkbox"
                            id={`version-${version.name}`}
                            onChange={() => handleVersionChange(version.name)}
                            checked={
                              selectedValues.version?.includes(version.name) ||
                              false
                            }
                          />
                          <label htmlFor={`version-${version.name}`}>
                            {version.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>
            )}
          </>
        )}
        {Object.keys(categoryTitles).map((category) => (
          <li key={category}>
            <div
              className="menu-item"
              onClick={() => handleItemClick(category)}
            >
              {categoryTitles[category]}
              <img
                src={FlechaCard}
                alt="Flecha"
                style={{
                  transform: expandedItems[category] ? "rotate(180deg)" : "",
                }}
              />
            </div>
            {expandedItems[category] && (
              <div className="sub-menu">
                <div className="filter-section">
                  {renderFilterOptions(category)}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </StyledMenu>
  );
};

export default CatalogoMenu;
