import React from "react";
import styled from "styled-components";
import IconSantander from "../images/icon_santander.png";
import IconOne from "../images/icon_1blue.png";
import { useForm } from "react-hook-form";
import "../styles.css";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const StyledFormContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 6px;
  z-index: 1000;
  padding: 50px;
  width: 697px;
  height: 647px;
  overflow-y: auto;
  scrollbar-width: thin;
  p {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
  }

  label {
    font-size: 15px;
    font-weight: 700;
    font-family: var(--font-family);
    line-height: 18px;
    margin-right: 15px;
  }
  .error-message {
    font-size: 12px;
    color: red;
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 0;
  }
  .form-item {
    display: flex;
    flex-direction: column;
  }

  .radio-inputs {
    align-items: center;
    padding: 0;
    margin-top: 6px;
    display: flex;
  }
  input[type="radio"] {
    margin-right: 5px;
  }
  .radio-inputs label {
    font-weight: 400;
    font-size: 16px;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
  }
  .title {
    display: flex;
    img {
      width: 18px;
      height: 18px;
      margin-right: 6px;
      margin-top: 4px;
    }
  }

  .santander-logo {
    margin-top: -40px;
  }

  button {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    width: 176px;
  }

  .btn-container-desktop {
    display: flex;
    width: 100%;
    justify-content: right;
    margin-right: 15px;
    margin-top: 12px;
  }
  .return-btn {
    width: 85px;
    background-color: transparent;
    color: #595e62;
    border-radius: 6px;
    height: 32px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    border: none;
    line-height: 20px;
    text-decoration: underline;
    outline: none;
    margin-top: 5px;
  }
  .return-btn:hover {
    color: var(--prime-color);
  }
`;

const ModalFinanciacion = ({ closeModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <Overlay onClick={closeModal} />
      <div className="container">
        <StyledFormContainer className="col-md-8">
          <form className="user-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="title-row">
              <div className="title">
                <img src={IconOne} alt="icon1" />
                <p>Datos Personales</p>
              </div>
              <div className="logo">
                <img
                  className="santander-logo"
                  src={IconSantander}
                  alt="santander_logo"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="documento" className="form-label">
                    Número de Documento
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="documento"
                    {...register("documento", { required: "Campo requerido" })}
                  />
                  {errors.documento && (
                    <p className="error-message">{errors.documento.message}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="fechaNacimiento" className="form-label">
                    Fecha de Nacimiento
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="fechaNacimiento"
                    {...register("fechaNacimiento", {
                      required: "Campo requerido",
                      validate: {
                        isAdult: (value) => {
                          // Validar si el usuario es mayor de edad (18 años o más)
                          const today = new Date();
                          const birthDate = new Date(value);
                          const age =
                            today.getFullYear() - birthDate.getFullYear();

                          return age >= 18 || "Debes ser mayor de edad";
                        },
                      },
                    })}
                  />
                  {errors.fechaNacimiento && (
                    <p className="error-message">
                      {errors.fechaNacimiento.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="apellido" className="form-label">
                    Apellido
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="apellido"
                    {...register("apellido", { required: "Campo requerido" })}
                  />
                  {errors.apellido && (
                    <p className="error-message">{errors.apellido.message}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="nombre" className="form-label">
                    Nombre
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nombre"
                    {...register("nombre", { required: "Campo requerido" })}
                  />
                  {errors.nombre && (
                    <p className="error-message">{errors.nombre.message}</p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="codArea" className="form-label">
                    Código de Area
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="codArea"
                    {...register("codArea", { required: "Campo requerido" })}
                  />
                  {errors.codArea && (
                    <p className="error-message">{errors.codArea.message}</p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="telefono" className="form-label">
                    Teléfono
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="telefono"
                    {...register("telefono", { required: "Campo requerido" })}
                  />
                  {errors.telefono && (
                    <p className="error-message">{errors.telefono.message}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    {...register("email", { required: "Campo requerido" })}
                  />
                  {errors.email && (
                    <p className="error-message">{errors.email.message}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="codigoPostal" className="form-label">
                    Código Postal
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="codigoPostal"
                    {...register("codigoPostal", {
                      required: "Campo requerido",
                    })}
                  />
                  {errors.codigoPostal && (
                    <p className="error-message">
                      {errors.codigoPostal.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="relacionLaboral" className="form-label">
                    ¿Trabajas en relación de dependencia?
                  </label>
                  <div className="radio-inputs">
                    <label>
                      <input
                        type="radio"
                        {...register("opcion", {
                          required: "Campo requerido",
                        })}
                      />
                      Sí
                    </label>
                    <label>
                      <input
                        type="radio"
                        {...register("opcion", {
                          required: "Campo requerido",
                        })}
                      />
                      No
                    </label>
                  </div>
                  {errors.opcion && (
                    <p className="error-message">{errors.opcion.message}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="ingresosMensuales" className="form-label">
                    Ingresos Mensuales
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="ingresosMensuales"
                    {...register("ingresosMensuales", {
                      required: "Campo requerido",
                    })}
                  />
                  {errors.ingresosMensuales && (
                    <p className="error-message">
                      {errors.ingresosMensuales.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="btn-container-desktop">
                <button className="return-btn" onClick={closeModal}>
                  Omitir
                </button>
                <button type="submit" className="btn primary-btn-big">
                  Continuar
                </button>
              </div>
            </div>
          </form>
        </StyledFormContainer>
      </div>
    </>
  );
};

export default ModalFinanciacion;
