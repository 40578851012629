import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../src/screens/Home";
import LoginForm from "./screens/Login";
import Nosotros from "./screens/Nosotros";
import CatalagoUsados from "./screens/CatalagoUsados";
import Catalago0km from "./screens/Catalogo0km";
import ProductoUsado from "./screens/ProductoUsado";
import Producto0km from "./screens/Producto0km";
import Cotizador1 from "./screens/Cotizador";
import Editar0kmScreen from "./screens/AdminEditarProductNuevoScreen";
import AgregarUsadoScreen from "./screens/AdminAgregarUsadosScreen";
import Agregar0kmScreen from "./screens/AdminAgregar0kmScreen";
import Financiacion from "./screens/Financiacion";
import AdminHome from "./screens/AdminHome";
import EditarUsadoScreen from "./screens/AdminEditarProductUsadoScreen";
import ProtectedRoutes from "./componentes/router/ProtectedRoutes";
import { AuthProvider } from "./utils/AuthContext";
import { FilterProvider } from "./utils/FilterMobileContext";
import OnlyPublicRoutes from "./componentes/router/OnlyPublicRoutes";

function App() {
  return (
    <AuthProvider>
      <FilterProvider>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<Home />} />
                <Route path="nosotros" element={<Nosotros />} />
                <Route path="usados" element={<CatalagoUsados />} />
                <Route path="nuevos" element={<Catalago0km />} />
                <Route path="usados/:productId" element={<ProductoUsado />} />
                <Route path="nuevos/:productId" element={<Producto0km />} />
                <Route path="cotizador" element={<Cotizador1 />} />
                <Route path="financiacion" element={<Financiacion />} />
                <Route path="/" element={<OnlyPublicRoutes />}>
                  <Route path="login" element={<LoginForm />} />
                </Route>
                <Route path="/" element={<ProtectedRoutes />}>
                  <Route path="/admin/home" element={<AdminHome />} />
                  <Route path="/admin/add0km" element={<Agregar0kmScreen />} />
                  <Route path="admin/addusado" element={<AgregarUsadoScreen />} />
                  <Route
                    path="admin/edit0km/:productId"
                    element={<Editar0kmScreen />}
                  />
                  <Route
                    path="admin/editusado/:id"
                    element={<EditarUsadoScreen />}
                  />
                </Route>
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </div>
      </FilterProvider>
    </AuthProvider>
  );
}

export default App;
