import React from "react";
import { useNavigate } from "react-router-dom";
import Slide from "./Slider";
import Carousel from "./CarouselServiceCard";
import PolarizadoImg from "../images/polarizado_icon.png";
import ServiciosImg from "../images/seguros_icon.png";
import GestoriaImg from "../images/gestor_icon.png";
import BannerImg2 from "../images/Service_Card_Mobile2.png";
import BannerImg from "../images/Service_Card_Mobile.png";
import "../styles.css";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 !important;
  /* margin-bottom: 35px; */
  overflow: hidden;
  @media (max-width: 576px) {
    .banner-cards-box {
      margin-bottom: 30px;
    }
  }
`;

const StyledSlide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-bottom: 80px;
  @media (max-width: 576px) {
    display: none;
  }
`;

const ServiceCardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 372px;
  align-items: center;
  /* padding: 0 20px; */
  @media (max-width: 576px) {
    display: none;
  }
`;
const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 215px;
  text-align: center;
  width: 100%;
  padding: 0;

  .info-text {
    margin-top: 16px;
    justify-content: center;
    align-items: center;
  }

  h3 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    align-items: center;
  }

  p {
    /* padding: 8px; */
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    white-space: normal;
    max-width: 100%; /* Establece el ancho máximo para que no se extienda más allá de su contenedor */
  }
`;

const BannerCard = styled.div`
  display: none;
  font-family: var(--font-family);
  justify-content: center;
  flex-direction: column;
  height: 230px;
  width: 264px;
  font-weight: 400;
  border-radius: 6px;
  overflow: hidden; /* Añadido para que el border-radius tenga efecto */

  .info-text {
    height: 100%;
    text-align: center;
    color: white;
    display: flex; /* Añadido */
    flex-direction: column; /* Añadido */
    justify-content: flex-end; /* Añadido */
    padding: 0 20px;
  }

  .financiar {
    background-image: url(${BannerImg});
    background-position: center;
    background-size: cover;
  }

  .cotizar {
    background-image: url(${BannerImg2});
    background-position: center;
    background-size: cover;
  }

  h3 {
    font-size: 17px;
    line-height: 17px;
    font-weight: 700;
    margin: 0;
  }

  button {
    width: 100%;
    height: 45px;
    border-radius: 0px;
    outline: none;
    border: none;
  }

  @media (max-width: 576px) {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    align-items: center;
  }
`;

const HomeSlider = () => {
  const navigate = useNavigate();

  return (
    <StyledContainer>
      <div className="row">
        <ServiceCardContainer className="offset-md-2 col-md-8">
          <ServiceCard className="col-md-3">
            <img src={ServiciosImg} alt="service" />
            <div className="info-text">
              <h3>Seguros para vehículos</h3>
              <p>
                Tu tranquilidad en cada kilómetro. Asegurá tu auto con nosotros.
              </p>
            </div>
          </ServiceCard>
          <ServiceCard className="col-md-3">
            <img src={PolarizadoImg} alt="service" />
            <div className="info-text">
              <h3>Polarizado profesional</h3>
              <p>Personalizamos tu vehículo según tus gustos y estilo.</p>
            </div>
          </ServiceCard>
          <ServiceCard className="col-md-3">
            <img src={GestoriaImg} alt="service" />
            <div className="info-text">
              <h3>Gestoria automotor</h3>
              <p>
                Simplificamos tus trámites. Ahorrá tiempo, ganá tranquilidad.
              </p>
            </div>
          </ServiceCard>
        </ServiceCardContainer>
        <Carousel />
      </div>
      <div className="row">
        <div className="banner-cards-box">
          <BannerCard>
            <div className="info-text financiar">
              <h3>Lo hacemos posible</h3>
              <p>Te ofrecemos un financiamiento claro y preciso.</p>
            </div>
            <button
              onClick={() => navigate("/financiacion")}
              className="primary-btn"
            >
              Financiá ya
            </button>
          </BannerCard>
          <BannerCard>
            <div className="info-text cotizar">
              <h3>Lo hacemos justo</h3>
              <p>Valoramos tu auto tanto como lo hacés vos.</p>
            </div>
            <button
              onClick={() => navigate("/cotizador")}
              className="primary-btn"
            >
              Cotizá ya
            </button>
          </BannerCard>
        </div>
      </div>
      <div className="row">
        <StyledSlide>
          <Slide />
        </StyledSlide>
      </div>
    </StyledContainer>
  );
};

export default HomeSlider;
