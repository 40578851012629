import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Carousel from "./CarouselOportunityCard";
import IconArrow from "../images/icon_arrow_white.png";
import "../styles.css";
import { useNavigate } from "react-router-dom";
import axiosInstance, { BASE_URL } from "../utils/ContextAxios";
import { Spinner } from "react-bootstrap";
import ENDPOINTS from "../apiEndpoints";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 496px;
  background-color: black;
  font-family: var(--font-family);
  padding: 0px !important;

  .cards-container {
    width: 100%;
  }

  .title-text {
    padding-top: 64px;
    padding-bottom: 32px;
  }
  h1 {
    color: white;
    font-weight: 700;
    font-size: 38px;
    line-height: 40px;
    /* align-items: center; */
    text-align: center;
  }

  @media (max-width: 576px) {
    height: 288px;

    .cards-container {
      display: none;
    }
    .title-text {
      padding-top: 14px;
      padding-bottom: 0;
    }
    h1 {
      font-size: 18px;
      line-height: 20px;
    }
  }
`;

const StyledCard = styled.div`
  height: 288px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-radius: 6px;
  width: 100%;

  .info-card {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 32px;
  }

  .img-box {
    height: 150px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    padding: 0;
    margin-bottom: 0.25rem;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    padding: 0;
  }

  .titles {
    display: flex;
    flex-direction: column;
  }
  .link-arrow {
    cursor: pointer;
    border-radius: 6px;
    background-color: var(--prime-color);
    width: 26px;
    height: 26px;
    margin-top: 10px;
    display: flex; /* Agrega display flex para usar flexbox */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
  }

  .link-arrow img {
    width: 10px;
    height: 10px;
  }
  .link-arrow:hover {
    background-color: var(--gris-medio);
  }
`;
const HomeOportunity = () => {
  const [oportunityAuto, setOportunityAuto] = useState(null);
  const navigate = useNavigate();

  /*------api-------*/
  //pedir datos

  const fetchAdditionalCars = async (existingCars) => {
    try {
      const response = await axiosInstance.get(ENDPOINTS.GET_NEW_CARS);
      let additionalCars = response.data.data;

      // Invertir el orden de los autos adicionales
      additionalCars = additionalCars.reverse();

      // Concatenar los autos adicionales a los existentes para completar 4
      const updatedCars = [...existingCars, ...additionalCars].slice(0, 4);
      setOportunityAuto(updatedCars);
    } catch (error) {
      console.log("Error fetching additional cars:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(ENDPOINTS.GET_FAVOURITE_CARS);
      const carsData = response.data.data;

      if (carsData.length < 4) {
        // Si hay menos de 4 autos, hacer otra petición para obtener más
        fetchAdditionalCars(carsData);
      } else {
        setOportunityAuto(carsData.slice(0, 4));
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!oportunityAuto) {
    return <Spinner />;
  }

  const slicedCardData = oportunityAuto.slice(0, 4);

  const handleClick = (producto) => {
    const ruta = producto.newCar;

    // Definir las rutas de edición para nuevos y usados
    const rutaNuevos = `/nuevos/${producto._id}`;
    const rutaUsados = `/usados/${producto._id}`;

    // Determinar la ruta de edición basada en el valor de 'ruta'
    const rutaProduct = ruta === 1 ? rutaNuevos : rutaUsados;

    localStorage.setItem("ProductoId", JSON.stringify(producto._id));
    console.log("Id original", producto._id);
    navigate(rutaProduct);
  };

  return (
    <div style={{ backgroundColor: "black" }}>
      <div className="container">
        <StyledContainer>
          <div className="row title-text">
            <h1>Nuestros destacados</h1>
          </div>
          <div className="row cards-container">
            {slicedCardData.map((producto, index) => (
              <div className="col-md-3" key={index}>
                <StyledCard>
                  <div className="img-box">
                    {producto.multimedia && producto.multimedia.length > 0 ? (
                      producto.multimedia.some((media) => media.favorite) ? (
                        <img
                          src={`${BASE_URL}/${
                            producto.multimedia.find((media) => media.favorite)
                              .path
                          }`}
                          alt="Imagen del producto favorita"
                        />
                      ) : (
                        <img
                          src={`${BASE_URL}/${producto.multimedia[0].path}`}
                          alt="Imagen del producto"
                        />
                      )
                    ) : (
                      <img
                        src={`./images/image-default.jpg`}
                        alt="Imagen del producto"
                      />
                    )}
                  </div>
                  <div className="info-card">
                    <div className="titles">
                      <h3>
                        {producto.brand.name} {producto.modelo.name}
                      </h3>
                      <p>{producto.version.name}</p>
                    </div>
                    <div
                      className="link-arrow"
                      onClick={() => handleClick(producto)}
                    >
                      <img src={IconArrow} alt="icon_arrow" />
                    </div>
                  </div>
                </StyledCard>
              </div>
            ))}
          </div>
          <Carousel sliceData={slicedCardData} />
        </StyledContainer>
      </div>
    </div>
  );
};

export default HomeOportunity;
