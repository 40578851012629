import React, { useState } from "react";
import styled from "styled-components";
import "../styles.css";
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const Container = styled.div`
  display: flex;
  position: fixed;
  background-color: white;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  width: 400px;
  height: 350px;
  font-family: var(--font-family);
  font-weight: 700;
  z-index: 1001;
  transform: translate(-50%, -55%);
  top: 50%;
  left: 50%;

  .title {
    display: flex;
    width: 100%;
    height: 81px;
    font-size: 22px;
    line-height: 26px;
    background-color: var(--prime-color);
    color: white;
    justify-content: center;
    align-items: center;
    border-radius: 6px 6px 0 0;
    p {
      padding-top: 25px;
    }
  }

  button {
    border-radius: 6px;
    width: 43px;
    height: 32px;
    font-weight: 700;
    border: none;
    align-items: center;
  }
  input {
    height: 32px;
    width: 196px;
    border-radius: 6px;
    padding-left: 8px;
    margin: 5px 0;
    font-size: 16px;
    line-height: 18.5px;
    font-weight: 500;
    border: transparent;
    background-color: var(--gris-invisible);
  }
  .color-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    input {
      width: 156px;
      margin-right: 4px;
    }
  }
  .item {
    align-items: center;
    justify-content: center;
    margin: 8px 0px;
  }
  input:focus {
    border: none;
  }
  .options {
    align-items: center;
  }
  .color-sample {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid black;
  }
`;
const AgregarUbicacion = ({ closeModal, onLocationAdded }) => {
  const [locationName, setLocationName] = useState("");
  const [locationNumber, setLocationNumber] = useState("");
  const [locationStreet, setLocationStreet] = useState("");
  const [locationCity, setLocationCity] = useState("");

  const handleLocationNameChange = (e) => {
    setLocationName(e.target.value);
  };

  const handleLocationCityChange = (e) => {
    setLocationCity(e.target.value);
  };

  const handleLocationStreetChange = (e) => {
    setLocationStreet(e.target.value); // Actualiza correctamente el estado
  };

  const handleLocationNumberChange = (e) => {
    setLocationNumber(e.target.value); // Actualiza correctamente el estado
  };

  const handleAddLocation = () => {
    const locationData = {
      name: locationName,
      city: locationCity,
      street: locationStreet,
      number: locationNumber,
    };
    onLocationAdded(locationData);
    closeModal();
  };

  return (
    <>
      <Overlay onClick={closeModal} />
      <Container>
        <div className="title">
          <p>Agregar nueva ubicación</p>
        </div>
        <div className="options">
          <div className="item location-name">
            <input
              type="text"
              placeholder="Nombre"
              value={locationName}
              onChange={handleLocationNameChange}
            />
          </div>
          <div className="item location-city">
            <input
              type="text"
              placeholder="Ciudad"
              value={locationCity}
              onChange={handleLocationCityChange}
            />
          </div>
          <div className="item location-street">
            <input
              type="text"
              placeholder="Calle"
              value={locationStreet}
              onChange={handleLocationStreetChange}
            />
          </div>
          <div className="item location-number">
            <input
              type="number"
              placeholder="Numero"
              value={locationNumber} // Corrige el valor
              onChange={handleLocationNumberChange}
            />
          </div>
          <button onClick={handleAddLocation} className="button primary-btn">
            ok
          </button>
        </div>
      </Container>
    </>
  );
};

export default AgregarUbicacion;
