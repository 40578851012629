import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axiosInstance, { token, useAxiosWithAuth } from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";
import SelectArquetipo from "../utils/SelectArq";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 457px;
  height: 494px;
  border-radius: 6px;
  font-family: var(--font-family);
  z-index: 1001;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: white;
  align-items: center;

  .error-msg {
    color: red;
    margin: 0;
    font-size: 14px;
    padding-top: 8px;
    text-align: center;
  }

  .title {
    background-color: var(--prime-color);
    text-align: center;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    color: white;
    width: 100%;
    padding-top: 30px;
    border-radius: 6px 6px;
    margin-bottom: 20px;
  }

  .form {
    margin-top: 20px;
  }

  .select-container {
    width: 305px;
    margin-bottom: 12px;
  }
  button {
    outline: none;
    width: 195px;
    height: 42px;
    font-size: 16px;
    margin-top: 20px;
    border: none;
  }
  .label {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  .item-form {
    font-style: italic;
    font-size: 16px;
    height: 42px;
    border-radius: 8px;
  }
`;

const ModalAdminAgregarNuevoModelo = ({ closeModal }) => {
  const navigate = useNavigate();
  const [reloadKey, setReloadKey] = useState(Date.now());
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(null);
  const [selectedMarca, setSelectedMarca] = useState("");
  const [selectedModelo, setSelectedModelo] = useState("");
  const [selectedVersion, setSelectedVersion] = useState("");
  const [modelosArray, setModelosArray] = useState([]);
  const [versionesArray, setVersionesArray] = useState([]);
  const axiosInstanceWithAuth = useAxiosWithAuth();
  /*-----------------*/
  const reloadOptions = () => {
    setReloadKey(Date.now()); // Update the key to trigger a reload
  };

  //cargar datos en localstorage para avanzar de pagina
  useEffect(() => {
    localStorage.setItem("selectedMarca", JSON.stringify(selectedMarca));
    localStorage.setItem("selectedModelo", JSON.stringify(selectedModelo));
    localStorage.setItem("selectedVersion", JSON.stringify(selectedVersion));
  }, [selectedMarca, selectedModelo, selectedVersion]);

  const handleMarcaChange = (selectedOption) => {
    console.log(selectedOption);
    const modelosAvailable = selectedOption.modelos
      ? selectedOption.modelos.map((modelo) => ({
          label: modelo.name,
          _id: modelo._id,
          versions: modelo.versions
            ? modelo.versions.map((version) => ({
                name: version.name,
                _id: version._id,
              }))
            : [],
        }))
      : [];
    setSelectedMarca(selectedOption);
    setModelosArray(modelosAvailable);
    setSelectedModelo("")
    setSelectedVersion("")
  };
  const handleModeloChange = (selectedOption) => {
    const versionsAvailable = selectedOption.versions
      ? selectedOption.versions.map((version) => ({
          label: version.name,
          _id: version._id,
        }))
      : [];

    setSelectedModelo(selectedOption);
    setVersionesArray(versionsAvailable);
    setSelectedVersion("")
  };

  const handleVersionChange = (selectedOption) => {
    setSelectedVersion(selectedOption);
  };

  const handleCreateModelo = async (inputValue) => {
    try {
      await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_NEW_MODEL,
        {
          name: inputValue,
          brand: selectedMarca.value,
          versions: [],
        }
      ).then(response => {
        const { data } = response;
        const { data: model } = data;

        const newModelo = {
          label: model.name,
          value: model._id,
          name: model.name,
          _id: model._id,
          versions: []
        };
        setModelosArray((prevModelosArray) => [...prevModelosArray, newModelo]);
        setSelectedModelo(newModelo);
        handleModeloChange(newModelo);
        reloadOptions();
      })
    } catch (error) {
      console.error("Error creating new modelo:", error);
    }
  };
  const handleCreateVersion = async (inputValue) => {
    try {
      await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_NEW_VERSION,
        {
          name: inputValue,
          modelo: selectedModelo._id,
        }
      ).then(response => {
        const { data } = response
        const { data:version } = data
        
        const newVersion = {
          label: version.name,
          value: version._id,
          name: version.name,
          _id: version._id,
        };
        setVersionesArray((prevVersionesArray) => [...prevVersionesArray, newVersion]);
        setSelectedVersion(newVersion);
        handleVersionChange(newVersion);
        reloadOptions();
      })
    } catch (error) {
      console.error("Error creating new version:", error);
    }
  };
  const handleCreateMarca = async (inputValue) => {
    try {
      await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_NEW_BRAND,
        {
          name: inputValue,
          modelos: [],
        },
      ).then(response => {
        const { data } = response;
        const { data:brand } = data;

        const newMarca = {
          label: brand.name,
          value: brand._id,
          name: brand.name,
          _id: brand._id,
        };

      setSelectedMarca(newMarca);
      handleMarcaChange(newMarca);
      reloadOptions();
    });
    } catch (error) {
      console.error("Error creating new marca:", error);
    }
  };

  useEffect(() => {
    const checkIfProductExists = async () => {
      if (selectedVersion) {
        try {
          const response = await axiosInstance.get(ENDPOINTS.GET_NEW_CARS);

          const data = response.data.data;
          const checkVersion = data.find(
            (auto) => auto.version._id === selectedVersion._id
          );
          if (checkVersion) {
            setErrorMessage("Este producto ya existe.");
            setIsButtonDisabled(true);
          } else {
            setErrorMessage("");
            setIsButtonDisabled(false);
          }
        } catch (error) {
          console.error("Error checking if product exists:", error);
          setErrorMessage("Error al verificar el producto.");
          setIsButtonDisabled(true);
        }
      } else {
        setIsButtonDisabled(true);
      }
    };

    checkIfProductExists();
  }, [selectedMarca, selectedModelo, selectedVersion]);

  const handleButtonClick = () => {
    if (!isButtonDisabled) {
      navigate("/admin/add0km");
    }
  };
  return (
    <>
      <Overlay onClick={closeModal} />
      <ModalContainer>
        <div className="title">
          <p>Agregar Nuevo Modelo</p>
        </div>
        <div className="form">
          <SelectArquetipo
            label="Marcas"
            endpoint={`/api/v1/car/brands`}
            onCreateOption={handleCreateMarca}
            useDatabaseOptions={true}
            value={selectedMarca}
            reload={reloadKey}
            onChange={(option) => handleMarcaChange(option)}
          />
          <SelectArquetipo
            label="Modelos"
            onCreateOption={handleCreateModelo}
            useDatabaseOptions={false}
            presetOptions={modelosArray}
            reload={reloadKey}
            value={selectedModelo}
            onChange={(option) => handleModeloChange(option)}
          />
          <SelectArquetipo
            label="Versiones"
            useDatabaseOptions={false}
            presetOptions={versionesArray}
            reload={reloadKey}
            value={selectedVersion}
            onCreateOption={handleCreateVersion}
            onChange={(option) => handleVersionChange(option)}
          />
        </div>
        {errorMessage && selectedVersion && (
          <p className="error-msg">{errorMessage}</p>
        )}
        <button className="primary-btn" onClick={handleButtonClick}>
          Continuar
        </button>
      </ModalContainer>
    </>
  );
};

export default ModalAdminAgregarNuevoModelo;
