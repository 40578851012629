import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../componentes/AdminNavbar";
import IconBack from "../images/icon_flecha_back.png";
import ModalAdminSalir from "../componentes/ModalAdminSalir";
import AgregarVideo from "../componentes/ModalAdminAgregarVideo";
import AgregarColor from "../componentes/ModalAdminColor";
import SelectArquetipo, { SelectArquetipoMulti } from "../utils/SelectArq";
import styled from "styled-components";
import "../styles.css";
import { token, useAxiosWithAuth } from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";
import UploadImages from "../componentes/UploadImages";
import IconDelete from "../images/icon_delete.png";

import Swal from "sweetalert2";
import AdminAlert from "../componentes/AdminResponsiveError";

const MainContainer = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  button {
    outline: none;
  }
  input {
    outline: none;
  }
  select {
    outline: none;
  }
  option:disabled {
    display: none;
  }
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  font-family: var(--font-family);
  margin-top: 10px;
  padding: 0;
`;

const DobleItemRow = styled.div`
  display: flex;
  height: 70px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -510px;

  .color-item-box {
    height: 100px;
  }
  .accesorios-input {
    height: 170px;
    margin-top: 15px;
    textarea {
      background-color: #f6f6f6;
      outline: none;
      border: none;
      height: 100%;
      padding-top: 5px;
    }
  }
  .garantia {
    margin-top: 45px;
  }
`;
const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
  width: 100%;
  padding: 0;
  margin-bottom: 5px;
  font-family: var(--font-family);

  .label {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  input {
    height: 42px;
    width: 100%;
    border-radius: 6px;
    padding-left: 8px;
    border: none;
    background-color: var(--gris-invisible);
  }

  input:focus {
    border: 1px solid var(--prime-color);
  }

  .select-box {
    height: 42px;
  }
  ::placeholder {
    font-style: italic;
    color: black;
  }
`;

const FirstRow = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  width: 100%;
  font-family: var(--font-family);
  margin-top: 42px;
  margin-bottom: 32px;

  h1 {
    font-weight: 700;
    font-size: 34px;
    line-height: 34px;
    margin: 0;
    span {
      color: var(--prime-color);
      font-weight: 400;
    }
  }

  .return-img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-top: 4px;
    cursor: pointer;
  }

  .ficha-tecnica {
    font-weight: 700;
    text-decoration: underline;
    line-height: 20px;
    font-size: 16px;
    cursor: pointer;
  }

  img {
    position: absolute;
    left: 5%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: right;
  button {
    width: 162px;
  }
`;

const Agregar0kmScreen = () => {
  //Tipo y Color
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedMatAsientos, setSelectedMatAsientos] = useState("");
  //ficha tecnica
  const [file, setFile] = useState(null);
  const [pdf, setPdf] = useState([]);
  //abrir modal salir
  const [isModalSalirOpen, setModalSalirOpen] = useState();
  //abrir modal color
  const [isModalColorOpen, setModalColorOpen] = useState(false);
  //color
  const [colorsSelected, setColorsSelected] = useState([])

  //abrir modal video
  const [modalVideo, setModalVideo] = useState();

  // Estado para almacenar las imágenes cargadas
  const [loadedImages, setLoadedImages] = useState([]);
  const [colorReturn, setColorReturn] = useState();
  const [destacarImg, setDestacarImg] = useState();

  const [brand, setBrand] = useState("");
  const [modelo, setModelo] = useState("");
  const [version, setVersion] = useState("");

  const axiosInstanceWithAuth = useAxiosWithAuth();

  //actualizar los inputs cuando se crea una nueva opcion
  const [reloadKey, setReloadKey] = useState(Date.now());

  const reloadOptions = () => {
    setReloadKey(Date.now());
  };

  /************** */
  const [filesUpload, setFilesUpload] = useState([]);

  //ficha tecnica
  const handleFichaTecnica = (e) => {
    const file = e.target.files[0];
    const filePath = file.name;
    setFormulario({
      ...formulario,
      ficha_tec: filePath,
    });
  };

  const handleCreateObjectImages = () => {
    const imageMetadata = loadedImages.map((image) => ({
      url: image.name,
      tipo: image.type,
      // color_id: colorReturn,
      // destacada: destacarImg,
    }));
    console.log("imageMetadata:", imageMetadata);
    setFormulario({
      ...formulario,
      multimedia: imageMetadata,
    });
    console.log("Formulario Multimedia", formulario.multimedia);
    console.log(filesUpload);
    //filesUpload.append('file', )
  };

  useEffect(() => {
    handleCreateObjectImages();
  }, [loadedImages, colorReturn, destacarImg]);
  // Opciones practicas para cada select
  const opciones = {
    category: ["Auto", "SUV", "Camioneta", "Pick-Up", "Utilitario"],
    motor: [
      "1.0",
      "1.2",
      "1.4",
      "1.5",
      "1.6",
      "1.8",
      "2.0",
      "2.2",
      "2.3",
      "2.5",
      "2.8",
      "3.0",
      "3.2",
      "3.4",
      "3.5",
      "4.0",
      "5.0",
      "5.7",
      "6.0",
    ],
    transmission: ["Automática", "Manual", "Hidráulica", "EVT", "CVT", "DSG"],
    seatMaterial: ["Tela", "Cuero", "Vinilo", "Ante", "Microfibra"],
    traction: ["Delantera", "Trasera", "Total", "4x4"],
    fuel: ["GNC", "Diésel", "Nafta", "Gasoil", "Eléctrico", "Biocombustible"],
  };

  //CARGA DE DATOS DE CADA INPUT
  const [formulario, setFormulario] = useState({
    brand: "",
    modelo: "",
    version: "",
    category: "",
    motor: "",
    transmission: [],
    traction: "",
    fuel: "",
    seatMaterial: "",
    passengers: "",
    cv: "",
    colors: [],
    warranty: "",
    accessories: "",
    ficha_tec: "",
    multimedia: [],
  });

  //traer data de modelo
  useEffect(() => {
    const storedMarca = localStorage.getItem("selectedMarca");
    const storedModelo = localStorage.getItem("selectedModelo");
    const storedVersion = localStorage.getItem("selectedVersion");
    if (storedMarca && storedModelo && storedVersion) {
      const brand = JSON.parse(storedMarca);
      const modelo = JSON.parse(storedModelo);
      const version = JSON.parse(storedVersion);

      setBrand(brand.label);
      setModelo(modelo.label);
      setVersion(version.label);

      setFormulario({
        ...formulario,
        brand: brand.value,
        modelo: modelo._id,
        version: version._id,
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormulario({
      ...formulario,
      [name]: value,
    });
    console.log("Formulario actualizado:", { ...formulario, [name]: value });
  };

  //manejador de Color
  const handleOpenColor = () => {
    setModalColorOpen(true);
  };

  //maneja el boton confirmar
  const closeModalVideo = () => {
    setModalVideo(false);
  };
  //maneja el boton confirmar
  const closeModalColor = () => {
    setModalColorOpen(false);
  };
  //boton regresar
  const handleReturnButton = () => {
    setModalSalirOpen(true);
  };
  //cerrar boton regresar
  const handleReturnButtonClose = () => {
    setModalSalirOpen(false);
  };

  //Crear tipos:
  const handleCreateCategory = async (inputValue) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_CATEGORY,
        {
          name: inputValue,
          isMain: inputValue.isMain || false,
        },
        {
          headers: `Bearer ${token}`,
        }
      );
      const newTipo = {
        label: response.data.data.nombre,
        name: inputValue.label,
        isMain: inputValue.isMain || false,
      };
      console.log(response);
      setSelectedCategory(newTipo);
      reloadOptions();
    } catch (error) {
      console.error("Error creating new marca:", error);
    }
  };

  //Crear tipos:
  const handleCreateColor = async (colorData) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_COLOR,
        {
          name: colorData.name,
          code: colorData.code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newColor = {
        label: response.data.data.name,
        name: response.data.data.name,
        _id: response.data.data._id,
        code: response.data.data.code,
      };
      console.log(response);
      setSelectedColor(newColor);
      const mapColor ={
          label: newColor.name,
          value: newColor._id,
          code: newColor.code,
          key: newColor.value,
        };
        
      setColorsSelected(prev => [...prev, mapColor]);

    } catch (error) {
      console.error("Error creating new color:", error);
    }
  };

  //llamado para crear auto

  const handleCreateVehiculo = async () => {
    try {
      const response = await axiosInstanceWithAuth
        .post(ENDPOINTS.CREATE_NEW_CAR, {
          newCar: 1,
          brand: formulario.brand,
          modelo: formulario.modelo,
          version: formulario.version,
          warranty: formulario.warranty,
          passengers: formulario.passengers,
          accessories: formulario.accessories,
          motor: formulario.motor,
          cv: formulario.cv,
          traction: formulario.traction,
          fuel: formulario.fuel,
          transmission: formulario.transmission,
          seatMaterial: formulario.seatMaterial,
          // ficha_tec: formulario.ficha_tec,
          colors: formulario.colors.map((color) => color.value),
          //colors: formulario.colors.map((color) => color.value),
          category: formulario.category,
          //  multimedia: formulario.multimedia,
        })
        .then(async ({ data }) => {
          const newCarData = data.data;

          const formData = new FormData();


          if (filesUpload.length > 0) {
            let filesData = []
            filesUpload.forEach((file, index) => {
              formData.append("files", file);
              if (file.type === "text/plain") {
                filesData.push({
                  url: file.url
                })
              } else {
                filesData.push({
                  favorite: file.favorite,
                  colorId: file.color,
                })
              }
            });
            formData.append("data", JSON.stringify(filesData));

            await axiosInstanceWithAuth
              .post(ENDPOINTS.UPLOAD_MULTIMEDIA(newCarData._id), formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .catch((err) => console.log(err));
          }

        }).then((e) => Swal.fire({
          icon: 'success',
          title: '¡Nuevo vehículo añadido al catálogo!',

          iconColor: '#1A3EEA',
          showConfirmButton: false,
          timer: 2000,
        }).then(() => navigate("/admin/home")))
        .catch((err) => Swal.fire({
          icon: 'error',
          title: '¡Ha ocurrido un error!',
          iconColor: '#dc3545',
          showConfirmButton: false,
          timer: 2000,
        }));

      //
    } catch (error) {
      console.error("Error creating new vehiculo:", error);
    }
  };
  const handleAddPDF = (e) => {
    const files = Array.from(e.target.files);
    setPdf(files);
  }
  const navigate = useNavigate();

  return (
    <>
      <AdminAlert />
      <MainContainer className="container-fluid">
        <Navbar />
        {isModalSalirOpen && (
          <ModalAdminSalir
            handleReturnButtonClose={handleReturnButtonClose}
            closeModal={handleReturnButtonClose}
          />
        )}
        {modalVideo && <AgregarVideo closeModal={closeModalVideo} />}
        {isModalColorOpen && (
          <AgregarColor
            closeModal={closeModalColor}
            onColorAdded={handleCreateColor}
          />
        )}
        <div className="container">
          <div className="row">
            <FirstRow>
              <img
                src={IconBack}
                alt="return-img"
                className="return-img"
                onClick={handleReturnButton}
              />
              <div className=" offset-1 col-8">
                <div className="title">
                  <h1>
                    Nuevo Modelo/
                    <span>
                      {brand} {modelo} {version}
                    </span>
                  </h1>
                </div>
              </div>

              <div className="ficha-tecnica">
                <label htmlFor="add-file" className="btn-pdf">
                  {pdf.length > 0 ?
                    <div style={{display:"flex", alignItems:"center"}}>
                      <span>{pdf[0].name}</span>
                      <button style={{ position:"relative", border:"none", background:"none", display:"flex", justifyContent:"center", alignItems:"center"}}  type="button" onClick={() => setPdf([])}>
                        <img src={IconDelete} alt="Eliminar" style={{position:"relative"}}  />
                      </button></div>
                  :<>
                  + <span>Ficha técnica (pdf)</span>
                  
                  <input
                    type="file"
                    id="add-file"
                    multiple
                    style={{ display: "none"}}
                    onChange={handleAddPDF}
                    accept=".pdf"
                  /></>}
                </label>
              </div>
            </FirstRow>
          </div>
          <div className="row">
            <StyledContainer>
              <div className="offset-1 col-3">
                <ItemBox>
                  <div className="select-box">
                    <SelectArquetipo
                      label="Categoria"
                      endpoint="/api/v1/car/attributes"
                      useDatabaseOptions={true}
                      reload={reloadKey}
                      onCreateOption={handleCreateCategory}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        setFormulario({
                          ...formulario,
                          category: selectedOption.value,
                        });
                      }}
                    />
                  </div>
                </ItemBox>
              </div>
              <div className=" col-3">
                <ItemBox>
                  <SelectArquetipo
                    label="Traccion"
                    useDatabaseOptions={false}
                    presetOptions={opciones.traction.map((opcion) => ({
                      label: opcion,
                      value: opcion,
                    }))}
                    onChange={(selectedOption) =>
                      setFormulario({
                        ...formulario,
                        traction: selectedOption.label,
                      })
                    }
                  />
                </ItemBox>
              </div>
              <div className="col-4">
                <ItemBox>
                  <SelectArquetipoMulti
                    label="Transmision"
                    isMulti={true}
                    useDatabaseOptions={false}
                    presetOptions={opciones.transmission.map((opcion) => ({
                      value: opcion,
                      label: opcion,
                    }))}
                    onChange={(selectedOptions) => {
                      console.log(selectedOptions);
                      const mapOptions = selectedOptions.map(
                        (opcion) => opcion.value
                      );
                      setFormulario({
                        ...formulario,
                        transmission: mapOptions,
                      });
                    }}
                  />
                </ItemBox>
              </div>
            </StyledContainer>
          </div>
          <div className="row">
            <StyledContainer>
              <div className="offset-1 col-3">
                <ItemBox>
                  <SelectArquetipo
                    label="Motor"
                    presetOptions={opciones.motor.map((opcion) => ({
                      label: opcion,
                      value: opcion,
                    }))}
                    useDatabaseOptions={false}
                    onChange={(selectedOption) =>
                      setFormulario({
                        ...formulario,
                        motor: selectedOption.label,
                      })
                    }
                  />
                </ItemBox>
              </div>
              <div className=" col-3">
                <ItemBox>
                  <label htmlFor="potencia" className="label">
                    Potencia
                  </label>
                  <input
                    type="number"
                    name="potencia"
                    value={formulario.cv}
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        cv: e.target.value,
                      })
                    }
                  />
                </ItemBox>
              </div>
              <div className=" col-4">
                <ItemBox>
                  <SelectArquetipo
                    label="Combustible"
                    useDatabaseOptions={false}
                    presetOptions={opciones.fuel.map((opcion) => ({
                      label: opcion,
                      value: opcion,
                    }))}
                    onChange={(selectedOption) =>
                      setFormulario({
                        ...formulario,
                        fuel: selectedOption.label,
                      })
                    }
                  />
                </ItemBox>
              </div>
            </StyledContainer>
          </div>
          <div className="margin-container" style={{ height: "40px" }}></div>

          <div className="row">
            <StyledContainer>
              <div className="offset-1 col-5">
                <ItemBox className="color-item-box">
                  <SelectArquetipoMulti
                    label="Color"
                    endpoint="/api/v1/car/color"
                    isMulti={true}
                    reload={colorsSelected}
                    //value={colorsSelected}
                    useDatabaseOptions={true}
                    onChange={(selectedOption) => {
                      const mapColor = selectedOption.map((color) => {
                        return {
                          label: color.name,
                          value: color._id,
                          code: color.code,
                          key: color.value,
                        };
                      });
                      console.log(mapColor)
                      setColorsSelected([...mapColor])
                      setFormulario({
                        ...formulario,
                        colors: mapColor,
                      });
                    }}
                    onCreateOption={handleOpenColor}
                  />
                </ItemBox>
              </div>
              <UploadImages
                className={"offset-1 col-4"}
                filesUpload={filesUpload}
                setFilesUpload={setFilesUpload}
                colors={formulario.colors}
                warningColor={true}
              >
                <ButtonContainer>
                  <button
                    onClick={handleCreateVehiculo}
                    className="primary-btn-big"
                  >
                    Confirmar
                  </button>
                </ButtonContainer>
              </UploadImages>
            </StyledContainer>
            <Container>
              <DobleItemRow>
                <div className="offset-1 col-2">
                  <ItemBox>
                    <label className="label">Pasajeros</label>
                    <input
                      type="number"
                      name="Pasajeros"
                      value={formulario.passengers}
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          passengers: parseInt(e.target.value),
                        })
                      }
                    />
                  </ItemBox>
                </div>
                <div className="col-3">
                  <ItemBox>
                    <SelectArquetipo
                      label="Material de Asientos"
                      presetOptions={opciones.seatMaterial.map((opcion) => ({
                        label: opcion,
                        value: opcion,
                      }))}
                      useDatabaseOptions={false}
                      onChange={(selectedOption) =>
                        setFormulario({
                          ...formulario,
                          seatMaterial: selectedOption.label,
                        })
                      }
                    />
                  </ItemBox>
                </div>
              </DobleItemRow>
              <div className="offset-1 col-5">
                <ItemBox className="garantia">
                  <label className="label">Garantía</label>
                  <input
                    type="text"
                    name="warranty"
                    value={formulario.warranty}
                    onChange={handleChange}
                    placeholder="Garantía"
                  />
                </ItemBox>
              </div>
              <div className="offset-1 col-5">
                <ItemBox className="accesorios-input">
                  <label className="label">Accesorios</label>
                  <textarea
                    value={formulario.accessories}
                    name="accessories"
                    onChange={handleChange}
                    placeholder="| Escribe información sobre accesorios complementarios"
                  />
                </ItemBox>
              </div>
            </Container>
          </div>
        </div>
      </MainContainer>
    </>
  );
};

export default Agregar0kmScreen;
