import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

const AuthProvider = (props) => {
    const {children} = props

    const [token, setToken] = useState(localStorage.getItem('token') || null);

    const login = (token) => {
        setToken(token);
        localStorage.setItem('token', token);
    }

    const logout = () => {
        setToken(null);
        localStorage.removeItem('token');
    }

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setToken(storedToken);
        }
    },[]);

    return (
        <AuthContext.Provider value={{token, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = () => useContext(AuthContext);

export {
    AuthProvider,
    useAuth
}