import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const OnlyPublicRoutes = () => {
  const {token} = useAuth();
  
  if (token) {
    return <Navigate to="/admin/home" />;
  }
  return <Outlet />;
};

export default OnlyPublicRoutes;
