import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BASE_URL } from "../utils/ContextAxios";

const StyledCard = styled.div`
  display: flex;
  justify-content: space-evenly;

  .card {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    text-align: center;
    height: 250px;
    width: 100%;
    margin-bottom: 30px;
    cursor: pointer;
    text-decoration: none;
    list-style: none;
    border: 3px solid transparent;
    transition: box-shadow 0.3s ease, border 0.3s ease;
  }
  .card:hover {
    box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border: 3px solid transparent;
    border-radius: 6px;
    background: #f6f6f6;
  }
  .img-box {
    width: 100%;
    height: 138px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .card h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #080c0d;
    margin: 0;
  }

  .card h5 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    color: #080c0d;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }

  @media (max-width: 576px) {
    .card {
      height: 158px;
      box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.2);
    }
    .card h2 {
      text-align: center;
      font-size: 15px;
      font-weight: 700;
      line-height: 15px;
      color: #080c0d;
    }

    .card h5 {
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      margin: 5px 0;
      color: #080c0d;
    }
    .img-box {
      width: 100%;
      height: 95px;
      object-fit: cover;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      margin-top: 8px;
    }
  }
`;

const Card = ({
  producto,
  comparadorAbierto,
  originalId,
  autosSeleccionados,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (autosSeleccionados.length > 0) {
      // Revisa si el producto actual está en la lista de autos seleccionados
      const isAutoSeleccionado = autosSeleccionados.some(
        (auto) => auto._id === producto._id
      );
      setIsSelected(isAutoSeleccionado);
    } else {
      setIsSelected(false);
    }
  }, [autosSeleccionados, producto._id]);

  const handlePassId = () => {
    localStorage.setItem("ProductoId", JSON.stringify(originalId));
    if (comparadorAbierto) {
      setIsSelected(!isSelected); // Alterna el estado de selección
    }
  };

  return (
    <StyledCard
      key={producto._id}
      $comparadorAbierto={comparadorAbierto}
      $isSelected={isSelected}
      onClick={handlePassId}
    >
      {comparadorAbierto ? (
        <div className="card">
          <div className="img-box">
            {producto.multimedia && producto.multimedia.length > 0 ? (
              producto.multimedia.some((media) => media.favorite) ? (
                <img
                  src={`${BASE_URL}/${
                    producto.multimedia.find((media) => media.favorite).path
                  }`}
                  alt="Imagen del producto favorita"
                />
              ) : (
                <img
                  src={`${BASE_URL}/${producto.multimedia[0].path}`}
                  alt="Imagen del producto"
                />
              )
            ) : (
              <img
                src={`./images/image-default.jpg`}
                alt="Imagen del producto"
              />
            )}
          </div>
          <div className="info">
            <h2>
              {producto.brand.name} {producto.modelo.name}
            </h2>
            <h5>{producto.version.name}</h5>
          </div>
        </div>
      ) : (
        <Link to={`/nuevos/${originalId}`} className="card">
          <div className="img-box">
            {producto.multimedia && producto.multimedia.length > 0 ? (
              producto.multimedia.some((media) => media.favorite) ? (
                <img
                  src={`${BASE_URL}/${
                    producto.multimedia.find((media) => media.favorite).path
                  }`}
                  alt="Imagen del producto favorita"
                />
              ) : (
                <img
                  src={`${BASE_URL}/${producto.multimedia[0].path}`}
                  alt="Imagen del producto"
                />
              )
            ) : (
              <img
                src={`./images/image-default.jpg`}
                alt="Imagen del producto"
              />
            )}
          </div>
          <div className="info">
            <h2>
              {producto.brand.name} {producto.modelo.name}
            </h2>
            <h5>{producto.version.name}</h5>
          </div>
        </Link>
      )}
    </StyledCard>
  );
};

export default Card;
