import React, { useState, useEffect, useCallback } from "react";
import AsyncSelect from "react-select/async-creatable";
import axiosInstance from "../utils/ContextAxios";

const SelectArquetipo = ({
  label,
  endpoint,
  onCreateOption,
  onChange,
  value,
  useDatabaseOptions,
  presetOptions,
  reload,
}) => {
  const [optionsBack, setOptionsBack] = useState([]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#f6f6f6",
      borderRadius: "6px",
      height: "42px",
      padding: "0",
      border: "none",
    }),
    container: (provided) => ({
      ...provided,
      padding: "0",
      height: "42px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid var(--prime-color)",
    }),
    valueContainer: (provided, styles) => ({
      ...provided,
      height: "42px",
      fontSize: "16px",
      alignItems: "center",
      textAlign: "left",
      padding: "0",
      paddingLeft: "6px",
    }),
    placeholder: (provided, styles) => ({
      ...provided,
      fontSize: "16px",
      fontStyle: "italic",
      color: "black",
      padding: "0",
    }),
    input: (provided) => ({
      ...provided,
      height: "42px",
      padding: "0",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      width: "35px",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "42px",
      color: "black",
      fontFamily: "Figtree",
      "&:hover": {
        backgroundColor: "#d9d9d9",
      },
      // Cambiar color de fondo de la opción activa
      backgroundColor: state.isSelected ? "white" : "transparent",
    }),
  };

  const loadOptions = useCallback(
    async (inputValue, callback) => {
      if (useDatabaseOptions) {
        try {
          const response = await axiosInstance.get(endpoint);

          let data = [];

          if (label === "Categoria") {
            data = response.data.data.categories.map((category) => ({
              label: category.name,
              value: category._id,
              optionLabel: category.name,
            }));
          } else if (label === "Ubicación") {
            data = response.data.data.locations.map((location) => ({
              label: location.name,
              value: location._id,
              optionLabel: location.name,
            }));
          } else if (label === "Color") {
            data = response.data.data.map((color) => ({
              name: color.name,
              _id: color._id,
              code: color.code,
              label: color.name,
              value: color._id,
            }));
          } else {
            data = response.data.map((item) => {
              const modelos = item.modelos
                ? item.modelos.map((modelo) => ({
                    name: modelo.name,
                    _id: modelo._id,
                    versions: modelo.versions
                      ? modelo.versions.map((version) => ({
                          name: version.name,
                          _id: version._id,
                        }))
                      : [],
                  }))
                : [];

              return {
                label: item.name ? item.name : item,
                value: item._id,
                optionLabel: item.name ? item.name : item,
                modelos,
              };
            });
          }
          const filteredData = inputValue
            ? data.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              )
            : data;

          callback(filteredData);
        } catch (error) {
          console.error(`Error fetching options for ${label}:`, error);
          callback([]);
        }
      } else {
        const filteredData = inputValue
          ? presetOptions.filter((option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            )
          : presetOptions;

        callback(filteredData);
      }
    },
    [endpoint, label, useDatabaseOptions, presetOptions, reload]
  );

  const handleChange = (option) => {
    onChange(option || "");
  };

  useEffect(() => {
    if (!useDatabaseOptions) {
      setOptionsBack(presetOptions);
    } else {
      const fetchData = async () => {
        loadOptions("", setOptionsBack);
      };
      fetchData();
    }
  }, [endpoint, label, loadOptions, useDatabaseOptions, presetOptions, reload]);

  return (
    <div className="select-container">
      <div className="label">{label}</div>
      <AsyncSelect
        className="item-form"
        onChange={handleChange}
        loadOptions={loadOptions}
        defaultOptions={optionsBack}
        placeholder={` ${label}...`}
        value={value}
        isClearable
        isSearchable
        defaultInputValue={value}
        styles={customStyles}
        onCreateOption={onCreateOption}
      />
    </div>
  );
};

export const SelectArquetipoMulti = ({
  label,
  endpoint,
  onCreateOption,
  onChange,
  value,
  useDatabaseOptions,
  presetOptions,
  reload,
}) => {
  const [optionsBack, setOptionsBack] = useState([]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#f6f6f6",
      borderRadius: "6px",
      padding: "0",
      border: "none",
      maxHeight: "auto",
      overflowY: "auto",
    }),
    container: (provided) => ({
      ...provided,
      padding: "0",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid var(--prime-color)",
    }),
    valueContainer: (provided, styles) => ({
      ...provided,
      fontSize: "16px",
      alignItems: "center",
      textAlign: "left",
      padding: "0",
      paddingLeft: "6px",
      overflowY: "auto",
    }),
    placeholder: (provided, styles) => ({
      ...provided,
      fontSize: "16px",
      fontStyle: "italic",
      color: "black",
      padding: "0",
    }),
    input: (provided) => ({
      ...provided,
      height: "42px",
      padding: "0",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      width: "35px",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "42px",
      color: "black",
      fontFamily: "Figtree",
      "&:hover": {
        backgroundColor: "#d9d9d9",
      },
      // Cambiar color de fondo de la opción activa
      backgroundColor: state.isSelected ? "white" : "transparent",
    }),
  };

  const loadOptions = useCallback(
    async (inputValue, callback) => {
      if (useDatabaseOptions) {
        try {
          const response = await axiosInstance.get(endpoint);
          let data = [];

          if (label === "Color") {
            data = response.data.data.map((color) => ({
              name: color.name,
              _id: color._id,
              code: color.code,
              label: color.name,
              value: color._id,
            }));
          } else {
            data = response.data.data.map((item) => ({
              label: item.name || item,
              value: item._id,
              optionLabel: item.name || item,
            }));
          }

          const filteredData = data.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
          );
          callback(filteredData);
        } catch (error) {
          console.error(`Error fetching options for ${label}:`, error);
          callback([]);
        }
      } else {
        callback(presetOptions);
      }
    },
    [reload]
  );

  const handleChange = (option) => {
    onChange(option || "");
  };

  useEffect(()=> {
      console.log("value", value);
      const fetchData = async () => {
        loadOptions("", setOptionsBack);
      };
      fetchData();
  }, [reload])

  useEffect(() => {
    if (!useDatabaseOptions) {
      setOptionsBack(presetOptions);
    } else {
      const fetchData = async () => {
        loadOptions("", setOptionsBack);
      };
      fetchData();
    }
  }, []);

  return (
    <div className="select-container">
      <div className="label">{label}</div>
      <AsyncSelect
        className="item-form"
        onChange={handleChange}
        loadOptions={loadOptions}
        defaultOptions={optionsBack}
        placeholder={` ${label}...`}
        isClearable
        isSearchable
        isMulti
        value={value}
        styles={customStyles}
        onCreateOption={onCreateOption}
      />
    </div>
  );
};

// export const SelectArquetipoEdit = ({
//   label,
//   endpoint,
//   onCreateOption,
//   value,
//   onChange,
//   useDatabaseOptions,
//   presetOptions,
// }) => {
//   const [optionsBack, setOptionsBack] = useState([]);

//   const customStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       backgroundColor: "#f6f6f6",
//       borderRadius: "6px",
//       height: "42px",
//       padding: "0",
//       border: "none",
//     }),
//     container: (provided) => ({
//       ...provided,
//       padding: "0",
//       height: "42px",
//     }),
//     menu: (provided) => ({
//       ...provided,
//       border: "1px solid var(--prime-color)",
//     }),
//     valueContainer: (provided, styles) => ({
//       ...provided,
//       height: "42px",
//       fontSize: "16px",
//       alignItems: "center",
//       textAlign: "left",
//       padding: "0",
//       paddingLeft: "6px",
//     }),
//     placeholder: (provided, styles) => ({
//       ...provided,
//       fontSize: "16px",
//       fontStyle: "italic",
//       color: "black",
//       padding: "0",
//     }),
//     input: (provided) => ({
//       ...provided,
//       height: "42px",
//       padding: "0",
//     }),
//     dropdownIndicator: (provided, state) => ({
//       ...provided,
//       width: "35px",
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       height: "42px",
//       color: "black",
//       fontFamily: "Figtree",
//       "&:hover": {
//         backgroundColor: "#d9d9d9",
//       },
//       // Cambiar color de fondo de la opción activa
//       backgroundColor: state.isSelected ? "white" : "transparent",
//     }),
//   };

//   const loadOptions = useCallback(
//     async (inputValue, callback) => {
//       if (useDatabaseOptions) {
//         try {
//           const response = await axiosInstance.get(endpoint);
//           const data = response.data.map((item) => ({
//             label: item.nombre,
//             value: item.id,
//             optionLabel: item.nombre,
//           }));
//           callback(data);
//         } catch (error) {
//           console.error(`Error fetching options for ${label}:`, error);
//           callback([]);
//         }
//       } else {
//         callback(presetOptions);
//       }
//     },
//     [endpoint, label, useDatabaseOptions, presetOptions]
//   );

//   const handleChange = (option) => {
//     onChange(option || "");
//   };

//   useEffect(() => {
//     if (!useDatabaseOptions) {
//       setOptionsBack(presetOptions);
//     } else {
//       const fetchData = async () => {
//         loadOptions("", setOptionsBack);
//       };
//       fetchData();
//     }
//   }, [endpoint, label, loadOptions, useDatabaseOptions, presetOptions]);

//   return (
//     <div className="select-container">
//       <div className="label">{label}</div>
//       <AsyncSelect
//         className="item-form"
//         onChange={handleChange}
//         value={value}
//         loadOptions={loadOptions}
//         defaultOptions={optionsBack}
//         placeholder={` ${label}...`}
//         isClearable
//         isSearchable
//         styles={customStyles}
//         onCreateOption={onCreateOption}
//       />
//     </div>
//   );
// };

export default SelectArquetipo;
