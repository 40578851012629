import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import ImgRow from './AdminImgChargeRow';
import ImgBox from "../images/icon_images_box.png";
import AgregarVideo from './ModalAdminAgregarVideo';
import VideoRow from './VideoRow';

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 30px;

  .empty-img-box {
    display: flex;
    flex-direction: column;
    height: 415px;
    width: 100%;
    text-align: center;
    color: var(--prime-color);
    align-items: center;
    border: none;
    justify-content: center;
    gap: 34px;
    font-family: italic;
    border: 2px dashed var(--gris-medio);
    border-radius: 6px;
  }

  .row-box {
    display: flex;
    height: 415px;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--gris-invisible);
    border: 1px solid var(--prime-color);
    border-radius: 6px;
    overflow-y:scroll;
  }

  .input-container {
    margin-bottom: 12px;

    input {
      border-radius: 50%;
      margin-bottom: 5px;
      width: 10px;
      height: 10px;
      margin: 0px 6px;
      cursor: pointer;
    }
  }
  .add-btn {
    font-weight: 700;
    cursor: pointer;
    width: 160px;
    height: 42px;
    align-items: center;
    justify-content: center;
    text-align: center;
    label {
      padding-top: 8px;
    }
    input {
      display: none;
      cursor: pointer;
    }
  }

  .upload-video {
    width: 160px;
    border: 1px solid var(--prime-color);
  }
  .upload-video:hover {
    border: none;
  }

  .add-btn-box {
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
  }

  p {
    font-family: var(--roboto);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 170px;
  }
  .advertencia {
    font-family: var(--roboto);
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 264px;
    margin-top: 8px;
    margin-bottom: 6px;
    background-color: #f02e27;
    padding: 7px;
    color: white;
    border-radius: 6px;
  }
`;

const UploadImages = (props) => {
    const {
        className,
        children,
        filesUpload,
        setFilesUpload,
        colors,
        setWasUpdated,
        warningColor
    } = props;

    const [open, setOpen] = useState(false);
    const [isFavorite, setIsFavorite] = useState(-1);

    useEffect(()=> {
        const hasFavorite = filesUpload.map( (elem, index) => elem.favorite ? index : -1).filter(num => num >= 0)
        if (hasFavorite.length > 0) {
          setIsFavorite(hasFavorite[0])
        }
    },[filesUpload])

    const handleAddImages = (e) => {
        const files = Array.from(e.target.files);
        setFilesUpload([...filesUpload, ...files]);
    };

    const handleAddVideo = (file) => {
        setFilesUpload([...filesUpload, file]);
    }

    const handleDeleteImage = (imgName) => {
        const updatedFilesUpload = filesUpload.filter((img) => img.name !== imgName);
        setFilesUpload(updatedFilesUpload);
        if (setWasUpdated)
            setWasUpdated(true);
    };

    //DATOS QUE REGRESAN DE IMGROW
    const handleColorReturn = (value, index) => {
        filesUpload[index].color = value;
        if (filesUpload[index].id) {
            if (setWasUpdated)
                setWasUpdated(true);
            filesUpload[index].updated = true;
        }
    };
    const handleDestacarReturn = (value) => {
        const file = filesUpload.find(item => item.favorite)
        if(file) 
            file.favorite = false;
        filesUpload[value].favorite = !filesUpload[value].favorite;
        if (filesUpload[value].id) {
            if (setWasUpdated)
                setWasUpdated(true);
            filesUpload[value].updated = true;
        }
        setIsFavorite(value)
    };
    return (
        <>
            <ImagesContainer className={className}>
                <div className="">
                    {/* Renderizar la caja vacía o ImgRow según el estado */}
                    {filesUpload && filesUpload.length > 0 ? (
                        <div className="row-box">
                            {warningColor && 
                                <span className="advertencia">
                                    IMPORTANTE: Todas las imágenes deben tener asignado un color
                                </span>
                            }
                            {filesUpload.map((file, index) => (
                                file.type === 'text/plain' ?
                                    <VideoRow
                                        key={index}
                                        index={index}
                                        file={file}
                                        deleteImage={handleDeleteImage}
                                        setDestacadaImg={handleDestacarReturn} />
                                    :
                                    <ImgRow
                                        key={index}
                                        index={index}
                                        image={file}
                                        imageUrl={URL.createObjectURL(file)}
                                        deleteImage={handleDeleteImage}
                                        colorValue={colors}
                                        colorReturnImg={handleColorReturn}
                                        setDestacadaImg={handleDestacarReturn}
                                        favorite={isFavorite}
                                    />
                            ))}
                        </div>
                    ) : (
                        <div className="empty-img-box">
                            <img src={ImgBox} alt="images-box" />
                            <div>
                                <p>
                                    Agregá imágenes para previsualizarlas y asignarles el
                                    color correspondiente
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                <div className="add-btn-box">
                    <div className="add-btn primary-btn-reverse">
                        <label
                            htmlFor="upload-images"
                            name="upload-images"
                            className="label"
                        >
                            Agregar fotos
                            <input
                                id="upload-images"
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={handleAddImages}
                            />
                        </label>
                    </div>
                    <button
                        className="primary-btn-reverse-big  upload-video"
                        onClick={() => setOpen(true)}
                    >
                        Video
                    </button>
                </div>
                {children}
            </ImagesContainer>
            {open && <AgregarVideo closeModal={() => setOpen(false)} addData={handleAddVideo} />}
        </>
    )
}

export default UploadImages;
