import React, { useEffect, useState } from 'react'

const Price = (props) => {
    const { value } = props
    const [price, setPrice] = useState("0")

    useEffect(() => {
        if(value){
            const priceString = value.toString()
            return setPrice(priceString.replace(/\B(?=(\d{3})+(?!\d))/g, "."));
        }
    },[value])

    return (
        <>$ {price}</>
    )
}

export default Price