import React from "react";
import styled from "styled-components";
import "../styles.css";
import { useNavigate } from "react-router-dom";
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;
const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  justify-content: center;
  width: 554px;
  height: 180px;
  border-radius: 6px;
  background-color: white;
  font-family: var(--font-family);
  z-index: 1001;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  .container-info {
    width: 350px;
    height: 44px;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    text-align: center;
    margin-top: -12px;
  }

  .btn-container {
    display: flex;
    width: 420px;
    height: 44px;
    justify-content: space-between;
    margin-top: 16px;
  }
  button {
    width: 192px;
  }
`;

const ModalAdminSalir = ({ handleReturnButtonClose, closeModal }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/admin/home");
  };
  return (
    <>
      <Overlay onClick={closeModal} />
      <Modal>
        <div className="container-info">
          <p>
            Los cambios no se aplicarán. ¿Deseás volver atrás de todos modos?
          </p>
        </div>
        <div className="btn-container">
          <button
            onClick={handleButtonClick}
            className="primary-btn-reverse-big"
          >
            SI
          </button>
          <button onClick={handleReturnButtonClose} className="primary-btn-big">
            NO
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ModalAdminSalir;
