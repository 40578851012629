import React, { useState } from "react";
import IconEdit from "../images/icon_edit_blue.png";
import IconDest from "../images/icon_destacado.png";
import IconDestBLue from "../images/icon_star_blue.png";
import IconDelete from "../images/icon_delete.png";
import IconMirar from "../images/icon_mirar.png";
import IconEditGray from "../images/icon_edit_gray.png";
import IconDeleteBlue from "../images/icon_delete_blue.png";
import IconMirarGray from "../images/icon_prev_gray.png";
import styled from "styled-components";
import "../styles.css";
import { useNavigate } from "react-router-dom";
import ModalAdminEliminar from "./ModalAdminEliminar";
import { token, useAxiosWithAuth } from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";

const Container = styled.div`
  width: 100%;
  .icons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .btn-icon {
    height: 20px;
    border: none;
    margin-left: 10px;
    background-color: transparent;
    outline: none; /* Elimina el borde de enfoque */
    cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
    user-select: none; /* Evita la selección de texto al hacer clic */
    img {
      height: auto;
      width: 20px;
    }
    .icon-delete {
      max-height: 20px;
    }
  }
`;
const ProductActionMenu = ({
  openModalView,
  producto,
  updateList
}) => {
  const navigate = useNavigate();

  const [destacado, setDestacado] = useState(producto.favorite);
  const [hovered, setHovered] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const axiosInstanceWIthAuth = useAxiosWithAuth();

  // Función para manejar la actualización del estado destacado
  const handleDestacarButton = async () => {
    const id = producto._id;
    const typeOfCar = producto.newCar;

    const endpoint =
      typeOfCar === 1 ? ENDPOINTS.UPDATE_NEW_CAR : ENDPOINTS.UPDATE_USED_CAR;

    try {
      const response = await axiosInstanceWIthAuth.put(
        `${endpoint}/${id}`,
        {
          favorite: !destacado,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error actualizando el estado destacado:", error);
    }
  };

  //navegar a la pagina de edicion
  const handleEditButton = () => {
    localStorage.setItem("ProductoId", JSON.stringify(producto._id));
    // Obtener el valor de la propiedad 'ruta' del producto
    const ruta =
      producto.newCar === 1
        ? `/admin/edit0km/${producto._id}`
        : `/admin/editusado/${producto._id}`;

    navigate(ruta);
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
  }

  return (
    <Container>
      {deleteModal && (
        <ModalAdminEliminar
          closeModalDelete={handleCloseModal}
          producto={producto}
          updateList={updateList}
        />
      )}
      <div className="icons">
        <button
          className="btn-icon"
          onClick={openModalView}
          onMouseEnter={() => setHovered("IconMirar")}
          onMouseLeave={() => setHovered(null)}
        >
          <img
            src={hovered === "IconMirar" ? IconMirarGray : IconMirar}
            alt="previa"
            className="icon-previa"
          />
        </button>
        <button
          className="btn-icon"
          onClick={handleDestacarButton}
          onMouseEnter={() => setHovered("IconDest")}
          onMouseLeave={() => setHovered(null)}
        >
          <img
            src={
              destacado
                ? IconDestBLue
                : hovered === "IconDest"
                ? IconDestBLue
                : IconDest
            }
            alt="Destacar"
            className="icon-destacar"
          />
        </button>
        <button
          className="btn-icon"
          onClick={handleEditButton}
          onMouseEnter={() => setHovered("IconEdit")}
          onMouseLeave={() => setHovered(null)}
        >
          <img
            src={hovered === "IconEdit" ? IconEditGray : IconEdit}
            alt="Editar"
            className="icon-editar"
          />
        </button>
        <button
          className="btn-icon"
          onClick={() => setDeleteModal(true)}
          onMouseEnter={() => setHovered("IconDelete")}
          onMouseLeave={() => setHovered(null)}
        >
          <img
            src={hovered === "IconDelete" ? IconDeleteBlue : IconDelete}
            alt="Eliminar"
            className="icon-delete"
          />
        </button>
      </div>
    </Container>
  );
};

export default ProductActionMenu;
