import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BASE_URL } from "../utils/ContextAxios";
import ReactPlayer from "react-player";

const MainContainer = styled.div`
  display: none;
  margin-top: 0;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  @media (max-width: 576px) {
    display: block;
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  max-height: 300px;
`;

const Slide = styled.div`
  min-width: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const SliderDots = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: center;
  z-index: 1;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid blue;
  background-color: ${(props) => (props.$active ? "blue" : "white")};
  margin: 0 5px;
  cursor: pointer;
`;

const SliderCar = ({ producto, productoImg, relatedColor }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    // Lógica para encontrar la imagen favorita
    const favoriteImage = productoImg.find((img) => img.favorite);
    const defaultImageList = favoriteImage
      ? [favoriteImage, ...productoImg.filter((img) => img !== favoriteImage)]
      : productoImg;

    setFilteredImages(defaultImageList);

    // Si hay un color relacionado, mostrar la imagen correspondiente
    if (relatedColor) {
      const index = defaultImageList.findIndex(
        (img) => img.color === relatedColor._id
      );
      if (index !== -1) {
        setCurrentIndex(index);
      }
    }
  }, [relatedColor, productoImg, producto]);

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (event) => {
    if (!isDragging) return;
    setTouchEndX(event.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    const touchDiff = touchStartX - touchEndX;

    if (touchDiff > 50 && currentIndex < filteredImages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else if (touchDiff < -50 && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }

    setIsDragging(false);
    setTouchStartX(0);
    setTouchEndX(0);
  };

  return (
    <MainContainer>
      <SliderWrapper
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {filteredImages.map((item, index) => (
          <Slide key={index}>
            {item.filetype === 'image' ?
            <Image
              src={`${BASE_URL}/${item.path}`}
              alt={`Slide ${index}`}
            />:
            <div style={{objectFit:"cover"}}>
            <ReactPlayer
                  width={400}
                  height={300}
                  url={item.path}
                />
            </div>}
          </Slide>
        ))}
      </SliderWrapper>
      <SliderDots>
        {filteredImages.map((_, index) => (
          <Dot
            key={index}
            $active={currentIndex === index}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </SliderDots>
    </MainContainer>
  );
};

export default SliderCar;
