import React from "react";
import styled from "styled-components";
import "../styles.css";
import logofooter from "../images/logo-footer.svg";
import iconfacef from "../images/iconfacef.svg";
import icontweetf from "../images/icontweetf.svg";
import IconWhats from "../images/icon_whatsapp_black.png";
import banderas from "../images/banderas.svg";
import { Link } from "react-router-dom";

const Container = styled.div`
  position: relative; /* Agregar posición relativa para que absolute funcione dentro de este contenedor */
  display: flex;
  height: 100px;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
  .bandera-icon {
    position: absolute; /* Cambiar a posición absoluta */
    left: 0; /* Colocar en el extremo izquierdo */
    margin: 0;
    width: 69px;
    height: 74px;
    align-items: left;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

const StyledFooter = styled.footer`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  /* justify-content: space-between; */
  background-color: transparent;
  color: black;
  font-size: 14px;
  line-height: 14px;

  p {
    margin-top: 25px;
  }
  .telefonos {
    padding: 0;
    align-items: center;
    margin-top: 4px;
  }
  span {
    font-weight: 700;
  }
  .redes {
    display: flex;
    width: 100%;
  }
  .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      img {
        margin-right: 5px;
        margin-top: 6px;
        width: 18px; // Ajusta el tamaño de la imagen según sea necesario
        height: 18px;
        // Puedes ajustar más estilos según tus necesidades
      }
    }
  }
  .whats-logo {
    margin-right: 5px;
    margin-bottom: 4px;
  }
  .logo-box {
    height: 100%;
    padding: 0;
  }
  .logo {
    width: 150px;
  }
`;

const Footer = () => {
  return (
    <Container className="container-fluid">
      <img src={banderas} alt="banderas" className="bandera-icon" />

      <div className="container">
        <StyledFooter>
          <div className="redes col-md-1">
            <div className="row">
              {/* <p>Seguinos en nuestras redes</p> */}
              <div className="icons col">
                <Link
                  to="https://www.facebook.com/Nave.com.ar"
                  rel="noopener"
                  target="_blank"
                >
                  <img src={icontweetf} alt="Facebook" />
                </Link>
                <Link
                  to="https://www.instagram.com/nave.com.ar/"
                  rel="noopener"
                  target="_blank"
                >
                  <img src={iconfacef} alt="Instagram" />
                </Link>
              </div>
            </div>
          </div>
          <div className="telefonos offset-1 col-md-8">
            <div className="row">
              <p>
                <img
                  src={IconWhats}
                  alt="icon_whatsapp"
                  className="whats-logo"
                />
                <span>Área Ventas:</span> +54 9 11 3113-8421 |
                <span> Administración: </span> +54 9 11 2627-1875 |
                <span> Postventa:</span> +54 911 6621-1172
              </p>
            </div>
          </div>
          <div className=" logo-box col-md-2 text-right">
            {" "}
            {/* Cambiar la clase col-md-2 a col-md-4 y agregar la clase text-right */}
            <Link to="/">
              <img src={logofooter} alt="Logo" className="logo" />
            </Link>
          </div>
        </StyledFooter>
      </div>
    </Container>
  );
};

export default Footer;
