import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import NavLogo from "../images/NavLogo.png";
import "../styles.css";
import { useAuth } from "../utils/AuthContext";

const Container = styled.div`
  background-color: black;
  padding-right: 0px !important;
  padding-left: 0px !important;
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;
const NavMenu = styled.nav`
  .menu-nav {
    display: flex;
    align-items: center;
    height: 113px;
    justify-content: space-between;
    width: 100%;
    font-family: var(--font-family);
    font-weight: 600;
  }

  .logo {
    /* margin-right: 20px; */
    width: 245px;
    height: 50px;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    list-style: none;
  }

  ul li {
    display: flex;
    margin-top: 20px;
  }

  ul li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

const Navbar = () => {
  const navigate = useNavigate();

  const {token} = useAuth();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <Container className="container-fluid">
      <div className="container">
        <NavMenu>
          <div className="row">
            <div className="menu-nav">
              <div className="logo col-2">
                <Link to={"/"}>
                  <img src={NavLogo} alt="Logo" />
                </Link>
              </div>
              { token && 
              <div className="col-2">
                <ul>
                  <li>
                    <a href="/" onClick={handleLogout}>
                      Salir
                    </a>
                  </li>
                </ul>
              </div> }
            </div>
          </div>
        </NavMenu>
      </div>
    </Container>
  );
};

export default Navbar;
