import React, { useEffect, useState } from "react";
import "../styles.css";
import styled from "styled-components";
import ComparadorModal from "./ComparadorModal";
import ComparadorCarItem from "./ComparadorCarItem";

const Container = styled.div`
  display: flex;
  position: sticky;
  flex-direction: column;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  align-items: center;
  button {
    outline: none;
  }
  .btn-container {
    width: 100%;
    background-color: transparent;
  }
  .background-container-fluid {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
  }

  .blue-line {
    background-color: blue;
    height: 100px;
    width: 2px;
    margin-left: 50%;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;
const ComparadorContainer = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  height: 160px;
  width: 100%;
  align-items: center;
  background-color: #f6f6f6;
  font-family: var(--font-family);

  .comparador-empty-car {
    height: 100px;
    width: 100%;
    border-radius: 6px;
    border: 2px dashed var(--prime-color);
    background-color: var(--gris-claro);
    transition: opacity 0.5s ease;
  }
  .info-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;

    button {
      width: 159px;
      height: 36px;
      border-radius: 6px;
      border: none;
    }
  }

  .text-box {
    flex-direction: column;
    padding: 0;
    gap: 5px;

    p {
      font-size: 16px;
      line-height: 18px;
      margin: 8px 0px;
    }
    .bold {
      font-weight: 700;
      font-size: 21px;
      line-height: 18px;
    }
  }
`;

const BotonAbrirComparador = styled.button`
  width: 100%;
  height: 36px; /* Ajusta la altura del botón */
  border: none;
  background-color: var(--prime-color);
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: Center;
  clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%);
`;

const Comparador = ({
  isOpen,
  toggleComparador,
  autosSeleccionados,
  onRemoveCar,
}) => {
  //Estado para abrir y cerrar el modal de comparador
  const [openModal, setOpenModal] = useState(false);

  //no hay cards
  const noCards = autosSeleccionados.length < 2;

  //Abrir Modal Comparador
  const handleModalOpen = () => {
    if (autosSeleccionados && !noCards) setOpenModal(true);
  };
  //cerrar el comparador
  const closeComparadorModal = () => {
    setOpenModal(false);
  };

  return (
    <Container>
      <div className="container">
        {openModal && (
          <ComparadorModal
            autosSeleccionados={autosSeleccionados}
            closeModal={closeComparadorModal}
          />
        )}
        <div className="row">
          <div className="btn-container">
            <div className="offset-5 col-2">
              <BotonAbrirComparador onClick={toggleComparador}>
                {isOpen ? "Cerrar Comparador" : "Abrir Comparador"}
              </BotonAbrirComparador>
            </div>
          </div>
        </div>
      </div>
      <div className="background-container-fluid">
        <div className="container">
          <div className="row">
            <ComparadorContainer $isOpen={isOpen}>
              {[...Array(4)].map((_, index) => (
                <div className="col-2" key={index}>
                  <div className="comparador-card">
                    {autosSeleccionados && autosSeleccionados[index] ? (
                      <ComparadorCarItem
                        key={autosSeleccionados[index]._id}
                        auto={autosSeleccionados[index]}
                        onRemove={() => onRemoveCar(autosSeleccionados[index]._id)}
                      />
                    ) : (
                      <div className="comparador-empty-car">
                        {/* Aquí coloca el contenido de la empty-card */}
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <div className="col">
                <div className="blue-line"></div>
              </div>
              <div className="info-box col-3">
                <div className="text-box">
                  <p className="bold">Descubrí la mejor opción.</p>
                  <p>Seleccioná hasta 4 vehículos.</p>
                </div>
                <button
                  className="primary-btn"
                  onClick={handleModalOpen}
                  disabled={noCards}
                >
                  Comparar
                </button>
              </div>
            </ComparadorContainer>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Comparador;
