import React, { useState } from "react";
import styled from "styled-components";
import "../styles.css";
import Swal from "sweetalert2";
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 490px;
  height: 624px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-top: 22px solid var(--prime-color);
  border-radius: 16px;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 1001;
  font-family: var(--font-family);

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding: 0px 25px;
    padding-top: 10px;
  }
  form {
    width: 305px;
    display: flex;
    flex-direction: column;
    margin: 5px;
    height: 390px;
  }
  .item-box {
    font-size: 16px;
    border-radius: 6px;
    width: 300px;
    height: 40px;
    padding: 6px 10px;
    margin: 6px 10px;
  }
  select {
    height: 40px;
    width: 280px;
    background: transparent;
    border: 1px solid #eaedf4;
    border-radius: 6px;
    color: black;
    outline: none;
    padding: 6px;
    /* Estilos para las opciones de los selectores */
  }
  option {
    font-size: 14px;
  }

  .confirm-btn {
    background-color: var(--prime-color);
    color: white;
    border-radius: 6px;
    height: 32px;
    width: 190px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    border: none;
    line-height: 20px;
    transition: all 0.3s ease-in-out;
    outline: none;
  }
  .confirm-btn:hover {
    background-color: #595e62;
  }
  input {
    font-size: 400;
    border: 1px solid #eaedf4;
    border-radius: 6px;
    width: 280px;
    height: 40px;
    background: transparent;
    outline: none;
    padding: 6px;
  }
  .return-btn {
    width: 85px;
    background-color: transparent;
    color: black;
    border-radius: 6px;
    height: 32px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    border: none;
    line-height: 20px;
    text-decoration: underline;
    outline: none;
  }
  .return-btn:hover {
    color: var(--prime-color);
  }
  .btn-container {
    margin-top: 30px;
  }
  .btn-container-mobile {
    display: none;
  }
  option:disabled {
    display: none;
  }

  @media (max-width: 576px) {
    width: 264px;
    height: 614px;
    align-items: center;
    padding: 10px;

    p {
      font-weight: 700;
      font-size: 15px;
      line-height: 15px;
      padding: 0px 12px;
      padding-top: 20px;
    }
    .item-box {
      border-color: 2px solid var(--gris-medio);
      border-radius: 6px;
      width: 225px;
      height: 40px;
    }
    select {
      height: 36px; /* Altura reducida para dispositivos móviles */
      padding: 6px 10px; /* Espaciado interno */
      font-size: 14px; /* Tamaño de fuente */
      width: 100%;
      border: 1px solid #9e9e9e;
    }

    option {
      padding: 6px 8px; /* Espaciado interno para opciones */
    }
    form {
      width: 100%;
      height: 390px;
      display: flex;
      flex-direction: column;
      margin: 5px;
      line-height: 13px;
    }

    input {
      font-family: var(--roboto);
      border: 1px solid var(--gris-medio);
      border-radius: 6px;
      background: transparent;
      width: 200px;
      height: 40px;
      font-size: 13px;
      font-weight: 400;
    }
    .btn-container-mobile {
      display: block;
      margin-top: -25;
    }
    .btn-container-desktop {
      display: none;
    }
  }
`;

const ModalCotizador = ({ closeModal, onSubmit }) => {
  const [formulario, setFormulario] = useState({
    brand: "",
    modelo: "",
    version: "",
    year: "",
    transmission: "",
    color: "",
    mileage: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formulario);
    Swal.fire({
      icon: 'success',
      title: 'Tus datos están listos',
      text: 'Finalizá tu solicitud contactando a un asesor',

      iconColor: '#1A3EEA',
      showConfirmButton: false,
      timer: 2000,
    });
    console.log("Datos del formulario enviados", formulario);
    closeModal();
  };

  // Función para manejar el cambio en los inputs
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [id]: value,
    }));
  };

  // Opciones para cada select
  const opciones = {
    transmission: ["Automática", "Manual", "Hidraulica"],

    year: [
      "2021",
      "2020",
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012",
      "2011",
      "2010",
      "2009",
      "2008",
      "2007",
      "2006",
      "2005",
      "2004",
      "2003",
      "2002",
      "2001",
      "2000",
      "1999",
      "1998",
      "1997",
      "1996",
      "1995",
      "1994",
      "1993",
      "1992",
      "1991",
      "1990",
    ],
  };

  return (
    <>
      <Overlay onClick={closeModal} />
      <ModalContainer>
        <div className="container-info">
          <p>
            Ingresá los datos del vehículo que querés entregar como parte de
            pago
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="item-box">
            <select
              id="year"
              value={formulario.year}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Año
              </option>
              {opciones.year.map((opcion) => (
                <option key={opcion} value={opcion} className="">
                  {opcion}
                </option>
              ))}
            </select>
          </div>
          <div className="item-box">
            <input
              type="text"
              id="brand"
              required
              placeholder=" Indicá la Marca"
              value={formulario.brand}
              onChange={handleChange}
            />
          </div>
          <div className="item-box">
            <input
              type="text"
              id="modelo"
              required
              placeholder=" Indicá el Modelo"
              value={formulario.modelo}
              onChange={handleChange}
            />
          </div>
          <div className="item-box">
            <input
              type="text"
              id="version"
              placeholder=" Indicá la Versión"
              value={formulario.version}
              onChange={handleChange}
              required
            />
          </div>
          <div className="item-box">
            <select
              id="transmission"
              value={formulario.transmission}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Transmisión
              </option>
              {opciones.transmission.map((opcion) => (
                <option key={opcion} value={opcion}>
                  {opcion}
                </option>
              ))}
            </select>
          </div>
          <div className="item-box">
            <input
              type="text"
              id="color"
              placeholder=" Indicá el Color"
              value={formulario.color}
              onChange={handleChange}
              required
            />
          </div>
          <div className="item-box">
            <input
              type="number"
              id="mileage"
              placeholder=" Indicá el kilometraje"
              value={formulario.mileage}
              onChange={handleChange}
              required
            />
          </div>
          <div className="btn-container btn-container-desktop">
            {/* Botón de Envío */}
            <button className="return-btn" onClick={closeModal}>
              Omitir
            </button>
            <button className="confirm-btn" type="submit">
              Continuar
            </button>
          </div>
          <div className="btn-container btn-container-mobile">
            {/* Botón de Envío */}
            <button type="submit" className="confirm-btn">
              Continuar
            </button>
            <button className="return-btn" onClick={closeModal}>
              Omitir
            </button>
          </div>
        </form>
      </ModalContainer>
    </>
  );
};

export default ModalCotizador;
