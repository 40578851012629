import React, { useCallback } from "react";
import styled from "styled-components";
import ComparadorImg from "../images/ComparadorImgCar.png";
import ExitImg from "../images/icon_exitmarca.png";
import "../styles.css";
import { Link } from "react-router-dom";
import { BASE_URL } from "../utils/ContextAxios";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const Comparador = styled.div`
  scale: 0.9;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1000;
  transform: translate(-60%, -55%);
  top: 50%;
  left: 50%;
  width: 932px;
  height: 637px;
  background-color: white;
  font-family: var(--roboto);
  font-size: 14px;
  line-height: 16px;
  border-radius: 6px;
  padding: 48px;
  overflow-y: scroll;
  button {
    height: 32px;
    width: 135px;
  }
  .close-btn {
    position: absolute;
    justify-content: right;
    width: 30px;
    height: 30px;
    top: 15px;
    cursor: pointer;
    right: 35px;
  }
  .img-container {
    img {
      object-fit: cover;
      width: 85px;
      max-height: 75px;
    }
  }

  @media (max-width: 576px) {
    height: 520px;
    width: 303px;
    padding: 15px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    border-radius: 16px;
    .close-btn {
      height: 20px;
      width: 20px;
      right: 8px;
    }
    img {
      height: 50px;
      width: auto;
      margin: 5px 0px;
    }
  }
`;

const TableContainer = styled.table`
  border-collapse: collapse;
  height: 100%;
  width: 100%;
  table-layout: fixed;
`;

const TableRow = styled.tr`
  border: 1px solid black;
  height: 30px;
  border-right: none;

  &:first-child,
  &:last-child {
    height: 100px;
  }
  &:first-child {
    vertical-align: bottom;
    border-top: none;
  }
  &:last-child {
    vertical-align: baseline;
    border-bottom: none;
  }

  @media (max-width: 576px) {
    height: 25px;
    &:first-child,
    &:last-child {
      height: 80px;
    }
  }
`;
const ColumanCarCell = styled.td`
  width: auto;
  padding: 0;
  font-weight: 400;
  p {
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 4px;
  }
  .accessories-cell {
    padding: 8px 10px;
    min-height: 100%;
    text-align: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .first-row {
    font-weight: 700;
    border-left: linear-gradient(to bottom, transparent 50%, black 50%) 1;
    p {
      padding: 5px 0;
      bottom: 0;
      margin: 0;
    }
  }
  @media (max-width: 576px) {
    .first-row {
      p {
        padding: 5px 0;
        bottom: 0;
        margin: 0;
      }
    }
    button {
      height: 24px;
      width: 69px;
      margin: 0;
    }
  }
`;
const TableBody = styled.tbody`
  width: auto;
`;

const TableCell = styled.td`
  padding: 0;
  height: 100%;
  text-align: center;

  @media (max-width: 576px) {
    height: 25px;
  }
`;
const AsideCell = styled.td`
  width: 100px;
  vertical-align: middle;
  padding: 0;
  text-align: left;
  font-weight: 700;
  height: 100%;
  border-left: 1px solid white;
  /* border-left: 0; */
  &:first-child td:first-child {
    border-left: 1px solid white;
  }

  p {
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 4px;
    text-align: left;
  }
  @media (max-width: 576px) {
    width: 70px;
  }
`;

const ComparadorColumn = ({
  producto,
  isLastColumn,
  autoClase,
  originalId,
}) => {

  const renderContent = (value) => {
    if (value != null && value !== "") {
      if (value.length > 60) {
        return `${value.slice(0, 60)}...`; // Limita el texto a 60 caracteres y agrega "..."
      } else {
        return value; // Retorna el valor completo si es menor o igual a 60 caracteres
      }
    } else {
      return "-";
    }
  };

  return (
    <ColumanCarCell $isLastColumn={isLastColumn}>
      <TableContainer>
        <TableBody>
          <TableRow>
            <TableCell className="first-row">
              <div className="img-container">
                <img
                  src={`${BASE_URL}/${producto.multimedia[0].path}`}
                  alt="car-img"
                />
              </div>
              <p style={{ fontWeight: "700" }}>
                {renderContent(producto.brand.name)}
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{renderContent(producto.version.name)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{renderContent(producto.category.name)}</TableCell>
          </TableRow>
          {autoClase !== 1 && (
            <>
              <TableRow>
                <TableCell>{renderContent(producto.domain)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.price)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.warranty)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.transmission)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.traction)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.motor)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.cv)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.fuel)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.seating)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.color.name)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {renderContent(producto.gnc === "true" ? "Si" : "No")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.doors)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.seating)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.seatMaterial)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.airbag)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {renderContent(producto.alarm === "true" ? "Si" : "No")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {renderContent(
                    producto.parkingSensor === "true" ? "Si" : "No"
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {renderContent(
                    producto.backupCamera === "true" ? "Si" : "No"
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.entertainment)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {renderContent(producto.radio === "true" ? "Si" : "No")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="accessories-cell">
                  {renderContent(producto.accessories)}
                  <Link to={`/usados/${producto._id}`}>
                    <button className="primary-btn-reverse">Más info...</button>
                  </Link>
                </TableCell>
              </TableRow>
            </>
          )}
          {autoClase === 1 && (
            <>
              <TableRow>
                <TableCell>{renderContent(producto.transmission)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.traction)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.motor)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.cv)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.fuel)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.seating)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.seatMaterial)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{renderContent(producto.warranty)}</TableCell>
              </TableRow>
           
         
          <TableRow>
            <TableCell className="accessories-cell">
              {renderContent(producto.accessories)}

              <Link
                to={`/nuevos/${producto._id}`}
                className="primary-btn-reverse"
              >
                 <button className="primary-btn-reverse">Más info...</button>
              </Link>
            </TableCell>
          </TableRow>  </>)}
        </TableBody>
      </TableContainer>
    </ColumanCarCell>
  );
};

const ComparadorAside = () => {
  return (
    <AsideCell>
      <TableContainer>
        <TableBody>
          <TableRow>
            <TableCell>
              <p>Marca/Modelo</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Versión</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Tipo</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Transmisión</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Tracción</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Motor</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Potencia/CV</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Combustible</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Pasajeros</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Interior</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Garantía</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Accesorios</p>
            </TableCell>
          </TableRow>
        </TableBody>
      </TableContainer>
    </AsideCell>
  );
};
const ComparadorAsideUsado = () => {
  return (
    <AsideCell>
      <TableContainer>
        <TableBody>
          <TableRow>
            <TableCell>
              <p>Marca/Modelo</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Versión</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Tipo</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Patente</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Precio</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Garantia</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Transmisión</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Tracción</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Motor</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Potencia/CV</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Combustible</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Pasajeros</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Color</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>GNC</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Puertas</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Asientos</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Asientos Int.</p>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <p>Airbags</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Alarma</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Sensor</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Camara</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Entretenimiento</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Radio</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p>Accesorios</p>
            </TableCell>
          </TableRow>
        </TableBody>
      </TableContainer>
    </AsideCell>
  );
};
const ComparadorModal = ({ autosSeleccionados, closeModal }) => {
  const comparadorData = autosSeleccionados.slice(0, 4);

  const idCars = comparadorData.reduce((acc, auto, index) => {
    acc[index] = auto._id;
    return acc;
  }, {});
  const autoClase = comparadorData.length > 0 ? comparadorData[0].newCar : null;
  return (
    <>
      <Overlay onClick={closeModal} />
      <Comparador>
        <img
          src={ExitImg}
          alt="close-x"
          className="close-btn"
          onClick={closeModal}
        />
        <TableContainer>
          <TableBody>
            <TableRow>
              {autoClase === 1 ? <ComparadorAside /> : <ComparadorAsideUsado />}
              {comparadorData.map((auto, index) => (
                <ComparadorColumn
                  key={index}
                  producto={auto}
                  isLastColumn={index === comparadorData.length - 1}
                  autoClase={autoClase}
                  originalId={idCars[index]}
                />
              ))}
            </TableRow>
          </TableBody>
        </TableContainer>
      </Comparador>
    </>
  );
};
export default ComparadorModal;
