import React, { useState } from "react";
import styled from "styled-components";
import ImgTestimonio1 from "../images/Testimonio1.jpg";
import ImgTestimonio2 from "../images/Testimonio2.jpg";
import ImgTestimonio3 from "../images/Testimonio3.jpg";
import RightArrow from "../images/SliderRow1.png";

const testimonies = [
  {
    id: 1,
    name: "Norma Perez",
    text: "“Siempre muy buena predisposicion, buen trato, y los trámites muy rápidos.”",
    image: ImgTestimonio1,
  },
  {
    id: 2,
    name: "Julia Molina",
    text: "“¡El auto muy bien! Cumplió mis expectativas y por suerte ningun inconveniente, como siempre el vendedor 10 puntos, al igual que el sector administrativo.”",
    image: ImgTestimonio2,
  },
  {
    id: 3,
    name: "Omar Oro",
    text: "“Excelente experiencia, sigan así que hacen muy bien su trabajo.”",
    image: ImgTestimonio3,
  },
  // Agrega más objetos de testimonios según sea necesario
];

const StyledSlider = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 291px;
  justify-content: center;
  font-family: var(--font-family);
  border-top: 1px solid black;
  button {
    outline: none;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

const Slide = styled.div`
  width: 100%;
  position: relative; /* Agrega posición relativa para las flechas */
`;

const TestimonyImage = styled.img`
  height: 195px;
  max-width: 100%;
  border-radius: 5%;
  object-fit: cover;
  /* margin-left: 35px; */
  @media (max-width: 575px) {
    height: 106px;
  }
`;

const TestimonyText = styled.p`
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  font-family: var(--font-family);
  text-align: left;
  width: 100%;
`;

const TestimonyName = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  font-family: var(--font-family);

  @media (max-width: 576px) {
    font-size: 14px;
    margin-top: 5px;
  }
`;

const SliderArrow = styled.button`
  position: relative !important;
  top: 40%;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0px !important;
  background: transparent; /* Agrega fondo transparente */
  z-index: 1; /* Asegura que las flechas estén sobre el contenido */
`;

const NextArrow = styled(SliderArrow)`
  float: inline-end;
  transform: rotate(180deg);
  outline: none;
`;

const PrevArrow = styled(SliderArrow)`
  float: inline-start;
  outline: none;
`;

const HomeTestimony = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === testimonies.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? testimonies.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="container">
      <StyledSlider>
        <Slide>
          <div className="row">
            <div className="offset-md-2 col-1">
              <PrevArrow onClick={prevSlide} className="">
                <img src={RightArrow} alt="Previous" />
              </PrevArrow>
            </div>

            <div className="col-2">
              <TestimonyImage
                src={testimonies[currentSlide].image}
                alt={testimonies[currentSlide].name}
              />
            </div>

            <div className="col-md-4">
              <TestimonyText>{testimonies[currentSlide].text}</TestimonyText>
              <TestimonyName>{testimonies[currentSlide].name}</TestimonyName>
            </div>

            <div className="col-1">
              <NextArrow onClick={nextSlide}>
                <img src={RightArrow} alt="Next" />
              </NextArrow>
            </div>
          </div>
        </Slide>
      </StyledSlider>
    </div>
  );
};

export default HomeTestimony;
