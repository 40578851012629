import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IconWhatsApp from "../images/icon_whatsapp_white.png";
import IconOne from "../images/icon_1blue.png";
import IconTwo from "../images/icon_2blue.png";
import IconThree from "../images/icon_3blue.png";
import IconFour from "../images/icon_4blue.png";
import IconFive from "../images/icon_5blue.png";
import checkbox from "../images/checkbox.png";
import check from "../images/check.png";
import logofooter from "../images/Nave_logo_white.png";
import icontweetf from "../images/icon_facebook_white.png";
import iconfacef from "../images/icon_instagram_white.png";
import ArrowRight from "../images/icon_arrow_aside.png";
import ArrowDown from "../images/icon_arrowblue_down.png";

import { Link } from "react-router-dom";
import axiosInstance from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";

const AsideProduct = styled.aside`
  display: none;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0;
  background-color: var(--prime-color);
  button {
    outline: none;
  }
  @media (max-width: 576px) {
    display: flex;
  }
  .form-btns {
    margin-top: 32px;
  }
  .slang {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 17px;
    line-height: 17px;
    height: 67px;
    background-color: white;
    border-radius: 6px;
    margin-top: 25px;
    cursor: pointer;
    padding: 15px;
    p {
      padding-top: 10px;
    }
    img {
      height: 10px;
      width: 10px;
      margin-right: 5px;
      margin-bottom: 20px;
    }
  }
`;

const InfoForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  row-gap: 5px;
  background-color: white;
  margin-bottom: 35px;
  border-radius: 0px 0px 8px 8px;
  margin-top: -4px;

  .title {
    display: flex;
    height: 20px;
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 14px;
    line-height: 14px;
    align-items: center;
    p {
      padding-top: 17px;
    }
    img {
      margin-right: 8px;
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;

    button {
      width: 100%;
      /* padding: 10px; */
      height: 37px;
      background-color: var(--gris-claro);
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      color: var(--prime-color);
      line-height: 14px;
      border: 1px solid transparent;
      cursor: pointer;
      border-radius: 6px;
      outline: none;
    }

    button:disabled{
      opacity:0.8;
      color:#9E9E9E;
    }

    button:disabled:hover{
      cursor:not-allowed;
      color:#9E9E9E;
      background-color:#EAEDF4;
      border: 1px solid transparent;
    }

    button:hover {
      background-color: white;
      border: 1px solid var(--prime-color);
    }

    button.isActive {
      color: white;
      background-color: #595e62;
      border-color: transparent;
    }
  }

  .colors-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  /* Estilo para ocultar el input */
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* Estilo para el contenedor del checkbox personalizado */
  .polarizado-option {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  /* Estilo para la imagen del checkbox */
  .polarizado-option label img {
    max-width: 19px;
    min-width: 19px;
    min-height: 19px;
    max-height: 19px;

    margin-right: 8px;
    cursor: pointer;
    margin-bottom: 4px;
  }

  .polarizado-option label {
    cursor: pointer;
    font-size: 16px;
    line-height: 18px;
  }

  .form-financiador {
    margin-bottom: 25px;
  }
`;

const StyledColorOption = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    border: 4px solid #1a3eea;
    height: 45px;
    width: 45px;
  }
  &.active {
    border: 4px solid #1a3eea;
    height: 45px;
    width: 45px;
  }
`;
const StyledBtnAsesor = styled.button`
  display: flex;
  color: white;
  border-radius: 6px;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;

  background-color: transparent;
  border-color: white;
  height: 42px;
  margin: 15px 0px;

  &:hover {
    background-color: #595e62;
    color: white;
    border: 1px solid transparent;
  }
  img {
    margin-right: 5px;
  }
`;
const StyledBtn = styled.button`
  display: flex;
  background-color: white;
  color: var(--prime-color);
  border-radius: 6px;
  font-weight: 700;
  height: 42px;
  font-size: 15px;
  border: none;
  line-height: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 15px 0px;
  outline:none &:hover {
    background-color: #595e62;
    color: white;
    border: 1px solid transparent;
  }

  .btn-asesor {
    background-color: transparent;
    border-color: var(--prime-color);
  }
`;
const StyledFooter = styled.footer`
  display: none;
  flex-direction: column;
  width: 100%;
  /* height: 120px; */
  align-items: center;
  justify-content: space-evenly;
  color: black;
  padding: 40px 0px;
  /* margin: 55px 0px; */

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 10px;
    color: white;
    line-height: 7px;
    justify-content: center;
    margin: 10px 0px;

    p {
      margin: 0; // Elimina el margen del párrafo
    }
  }
  .logo {
    height: 11px;
    width: auto;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .redes-icons {
    display: flex;

    // Estilo para los enlaces
    a {
      margin-right: 15px; // Ajusta el espacio entre los iconos
      img {
        // Estilo para las imágenes dentro de los enlaces
        width: 100%; // Ajusta el tamaño de la imagen según sea necesario
        height: auto;
        // Puedes ajustar más estilos según tus necesidades
      }
    }
  }

  @media (max-width: 576px) {
    display: flex;
  }
`;
const AsideProduct0kmMobile = ({
  handleCotizadorClick,
  handleMeInteresaClick,
  producto,
  formData,
  versionId,
  versionsBack,
  setRelatedColor,
}) => {
  const [formulario, setFormulario] = useState({
    transmission: "",
    version: "",
    color: "",
    plotted: false,
    cotization: "",
    financiation: "",
  });

  // const [versionesBack, setVersionesBack] = useState([]);
  const [plotted, setPlotted] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [selectedColor, setSelectedColor] = useState("");
  const [transmission, setTransmision] = useState("");
  const [allTransmissions, setAllTransmissions] = useState(producto.transmission);
  const [version, setVersion] = useState("");
  const [versions, setVersions] = useState([]);
  const [cotizacion, setCotizacion] = useState("");
  const [financiacion, setFinanciacion] = useState("");
  const [showForm, setShowForm] = useState(false); // Nuevo estado para controlar la visibilidad del formulario

  useEffect(() => {
    axiosInstance.get(ENDPOINTS.GET_TRANSMISSIONS_BY_MODELO(producto.modelo._id))
    .then((res) => {
      const { data } = res.data
      setAllTransmissions(data.transmission)
    });
    axiosInstance.get(ENDPOINTS.GET_VERSIONS_BY_MODELID(producto.modelo._id))
    .then((res) => {
      const { data } = res
      data.map(version => {
        return {...version, isActive:false}
      })
      setVersions(data);
    }).catch((err) => console.error(err));
  }, [])

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName === activeButton ? null : buttonName);
  };
  const handleColorClick = (color) => {
    if (selectedColor === color) {
      setFormulario({ ...formulario, selectedColor: "" });
      setSelectedColor("");
      setRelatedColor("");
    } else {
      setFormulario({ ...formulario, selectedColor: color });
      setSelectedColor(color);
      setRelatedColor(color);
    }
  };

  const handleSlangClick = () => {
    setShowForm(!showForm); // Alternar entre mostrar y ocultar el formulario
  };
  const handleTransmisionChange = (newTransmision) => {
    setFormulario({ ...formulario, transmission: newTransmision });
    setTransmision(newTransmision);
    setVersion("");
    versions.map( item => {
      if(item.transmissions.includes(newTransmision))
      {
        item.isActive = true;
      }
      else{
        item.isActive = false;
      }
      return item;
    })
  };

  const handleVersionChange = (newVersion) => {
    setFormulario({ ...formulario, version: newVersion.name });
    setVersion(newVersion.name);
    versionId(newVersion._id);
  };

  const handlePolarizadoChange = () => {
    console.log('polarizado change');
    const newPolarizado = !plotted;
    setFormulario({ ...formulario, plotted: newPolarizado });
    setPlotted(newPolarizado);
  };

  const handleCotizacionChange = (option) => {
    const cotizacionValue = option === "Si";
    setFormulario({ ...formulario, cotization: cotizacionValue });
    setCotizacion(option);
  };

  const handleFinanciacionChange = (option) => {
    const financiacionValue = option === "Si";
    setFormulario({ ...formulario, financiation: financiacionValue });
    setFinanciacion(option);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleMeInteresaClick();
  };

  const handleWhatsAppClick = () => {
    const color = (formulario.selectedColor && formulario.selectedColor.name) ? formulario.selectedColor.name : 'Cualquier color';

    let mensaje = `Hola, estoy interesado en ${producto.brand.name} ${
      producto.modelo.name
    }(Nuevo):
    - Transmisión: ${producto.transmission}
    - Versión: ${producto.version.name}
    - Color: ${color}
    - Polarizado: ${formulario.plotted ? "Si" : "No"}`;

    if (formData && cotizacion === "Si") {
      const { brand, modelo, version, year, transmission, color, mileage } =
        formData;
      mensaje += `
 Cotización: ${cotizacion}

- Marca: ${brand}
- Modelo: ${modelo}
- Versión: ${version}
- Año: ${year}
- Transmisión: ${transmission}
- Color: ${color}
- Kilometraje: ${mileage};
      
 Financiación: ${financiacion}`;
    } else {
      // Agregar cotización y financiación al mensaje
      mensaje += `
- Cotización: ${cotizacion}
- Financiación: ${financiacion}`;
    }

    const whatsappNumber = "5491131138421";
    // Construye la URL de WhatsApp
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      mensaje
    )}`;

    // Abre la URL de WhatsApp
    window.open(whatsappURL, "_blank");
  };

  return (
    <AsideProduct className="container-fluid">
      <div className="container">
        <div className="info-title" onClick={handleSlangClick}>
          <div className="slang">
            <img src={showForm ? ArrowDown : ArrowRight} alt="arrow" />
            <p>Personalizá tu vehículo y elegí cómo pagarlo</p>
          </div>
        </div>
        {showForm && (
          <InfoForm>
            <div className="row">
              <div className="offset-1 col-10">
                <div className="form-transmision">
                  <div className="title">
                    <img src={IconOne} alt="" /> <p>Elegí la transimisión</p>
                  </div>

                  <div className="btn-container">
                    {allTransmissions.map((transmisionItem, index) => (
                      <button
                        key={index}
                        className={
                          transmission === transmisionItem ? "isActive" : ""
                        }
                        onClick={() => handleTransmisionChange(transmisionItem)}
                      >
                        {transmisionItem}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="form-version">
                  <div className="title">
                    <img src={IconTwo} alt="" />
                    <p>Elegí la versión</p>
                  </div>
                  <div className="btn-container">
                    {versions.map((versionItem, index) => (
                      <button
                        key={index}
                        className={
                          version === versionItem.name ? "isActive" : "version-btn"
                        }
                        disabled={!versionItem.isActive}
                        onClick={() => {
                          handleVersionChange(versionItem);
                        }}
                      >
                        {versionItem.name}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="form-color">
                  <div className="title">
                    <img src={IconThree} alt="3" />
                    <p>Elegí el color</p>
                  </div>
                  <div className="colors-container">
                    {producto.colors.map((color, index) => (
                      <StyledColorOption
                        key={index}
                        style={{
                          backgroundColor: color.code.toLowerCase(),
                        }}
                        className={color === selectedColor ? "active" : ""}
                        onClick={() => handleColorClick(color)}
                      />
                    ))}
                  </div>

                  <div className="polarizado-option">
                    <label htmlFor="polarizado-option" onTouchStart={handlePolarizadoChange}>
                      <img src={plotted ? check : checkbox} alt="Checkbox" />
                      Vidrios polarizados
                    </label>
                    <input
                      type="checkbox"
                      id="polarizado-option"
                      checked={plotted}
                    />
                  </div>
                </div>
                <div className="form-cotizador">
                  <div className="title title-lg">
                    <img src={IconFour} alt="" />
                    <p>¿Te interesa entregar tu auto como parte de pago?</p>
                  </div>

                  <div className="btn-container">
                    <button
                      onClick={() => {
                        handleCotizacionChange("Si");
                        handleCotizadorClick();
                      }}
                      className={cotizacion === "Si" ? "isActive" : ""}
                    >
                      Sí
                    </button>
                    <button
                      onClick={() => {
                        handleCotizacionChange("No");
                      }}
                      className={cotizacion === "No" ? "isActive" : ""}
                    >
                      No
                    </button>
                  </div>
                </div>
                <div className="form-financiador">
                  <div className="title title-lg">
                    <img src={IconFive} alt="5" />
                    <p>¿Te interesa explorar opciones de financiación?</p>
                  </div>

                  <div className="btn-container">
                    <button
                      onClick={() => {
                        handleButtonClick("Si");
                        handleFinanciacionChange("Si");
                      }}
                      className={financiacion === "Si" ? "isActive" : ""}
                    >
                      Sí
                    </button>
                    <button
                      onClick={() => {
                        handleButtonClick("No");
                        handleFinanciacionChange("No");
                      }}
                      className={financiacion === "No" ? "isActive" : ""}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </InfoForm>
        )}
        <div className="form-btns">
          <div className="row">
            <div className="offset-1 col-10">
              <StyledBtn
                className="btn-whatsapp"
                onClick={handleMeInteresaClick}
              >
                Quiero que me llame un asesor
              </StyledBtn>
              <StyledBtnAsesor onClick={handleWhatsAppClick}>
                <img src={IconWhatsApp} alt="icon_whatsapp" />
                {/*Al cliquear el boton, se enviaran todos los datos del formulario a un numero de whatsapp: +54 9 11-31138421*/}
                Hablar por whatsapp
              </StyledBtnAsesor>
            </div>
          </div>
        </div>
      </div>
      <StyledFooter>
        <div>
          <Link to="/" className="logo">
            <img src={logofooter} alt="Logo" />
          </Link>
        </div>
        <div className="info">
          <p>Área Ventas: +54 9 11 3113-8421</p>
          <br />
          <p>Administración: +54 9 11 2627-1875</p>
        </div>
        <div className="redes-icons">
          <Link
            to="https://www.facebook.com/Nave.com.ar"
            rel="noopener"
            target="_blank"
          >
            <img src={icontweetf} alt="Facebook" />
          </Link>
          <Link
            to="https://www.instagram.com/nave.com.ar/"
            rel="noopener"
            target="_blank"
          >
            <img src={iconfacef} alt="Instagram" />
          </Link>
        </div>
      </StyledFooter>
    </AsideProduct>
  );
};

export default AsideProduct0kmMobile;
