import React from "react";
import styled from "styled-components";
import CloseX from "../images/icon_exitmarca.png";
import "../styles.css";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const Modal = styled.div`
  width: 750px;
  padding: 0px 40px;
  padding-top: 40px;
  padding-bottom: 5px;
  position: fixed;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  font-family: var(--roboto);
  font-size: 14px;
  line-height: 16px;
  height: 393px;
  /* padding: 0px 20px;
  padding-top: 10px;
  padding-bottom: 5px; */
  z-index: 1001;
  overflow-y: auto; /* Agrega scroll si el contenido excede la altura */
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 720px) {
    width: 310px;
    padding-top: 20px;
  }
`;

const CloseIcon = styled.img`
  position: sticky;
  top: 0;
  left: 100%;
  height: 18px;
  width: 18px;
  cursor: pointer;
  z-index: 1002;
  @media (max-width: 720px) {
    display: none;
  }
`;

const ModalConsignar = ({ closeModalConsignar }) => {
  return (
    <>
      <Overlay onClick={closeModalConsignar} />
      <Modal>
        <CloseIcon src={CloseX} alt="close" onClick={closeModalConsignar} />
        <div className="content">
          <p>
            En el contrato se pactará un valor de VENTA, con un respectivo
            porcentaje (%) de comisión. El procedimiento deberá ocurrir de
            manera PRESENCIAL para luego de poder inspeccionar el vehículo de
            manera profesional y a través de un estudio de mercado se pactará un
            precio de venta convenido entre las partes. Desde el lado de
            Nave.com.ar, asumimos el compromiso a efectuar la venta en el plazo
            pactado, comunicando los respectivos aumentos que vayan surgiendo.
            En caso de que no se pueda efectuar la venta, Nave.com.ar, se
            compromete a hacer la compra de su vehículo en la moneda de cambio
            que desee, Pesos o Dólares.
            <br />
            El sistema se basa en que el titular traiga el vehículo en un
            horario pactado, en donde se hará un Inspección del mismo, mientras
            se ven las PAUTAS administrativas (Allí se verán todos los temas
            relacionados con la documentación y los tramites correspondientes).
            En ese tiempo, los mecánicos de Nave.com.ar, harán las pruebas
            correspondientes. Posteriormente, se efectuar lo que sería el
            “Contrato de Consigna”, acordando un precio de venta (según
            inspección y análisis de mercado), % de comisión y el plazo de venta
            (Máximo de 3 meses).  Desde Nave.com.ar, nos comunicaremos
            semanalmente para chequear si es que efectivizo la venta de manera
            privada.  Dentro del contrato estará identificado que, a medida que
            surjan interesados del mismo, se coordinaran citas con el dueño del
            vehículo y el interesado (en Nave.com.ar) para que lo pueda ver.  El
            contrato deberá ser firmado por ambas partes. El dueño del vehículo
            se va a llevar una copia del contrato y de la Inspección de su
            vehículo.  En caso de que se llegue a un acuerdo, el cliente va a
            tener la opción de llevarse el vehículo o dejarlo por un tiempo
            determinado (pactado también en el Contrato).  Cuando el dueño
            quiera llevarse el vehículo, el mismo deberá esperar en nuestra sala
            de esperar por 1 hora, en donde el vehículo es lavado y preparado
            para las FOTOS para subir a la página web. 
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ModalConsignar;
