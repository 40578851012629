import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Error404 from "../images/404img.png";
import Logo404 from "../images/logo404.png";

const Container = styled.div`
  display: none !important;

  @media only screen and (max-width: 576px) {
    display: flex !important;
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
`;

const Title = styled.h1`
  font-family: "Figtree", sans-serif;
  font-weight: 700;
  font-size: 21px;
  color: #1a3eea;
  margin-top: 164px;
`;

const Aclaracion = styled.h2`
  font-family: "Figtree", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #080c0d;
  line-height: 12px;
  text-align: center;
  margin-top: 4px;
`;

const Imagen404 = styled.img`
  margin-top: 48px;
`;

const TextoInferior = styled.p`
  font-family: "Figtree", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #080c0d;
  line-height: 14px;
  text-align: center;
  margin-top: 48px;
`;

const Logo = styled.img`
  margin-top: 32px;
`;

const AdminAlert = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Function to check window width
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 576); // Change 992 to the desired desktop breakpoint
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Conditionally render the component
  if (!isMobileView) {
    return null;
  }

  return (
    <Container>
      <Row>
        <div className="col-4">
          <Title>¡Atención!</Title>
        </div>
        <div className="col-10">
          <Aclaracion>
            El administrador de productos no es compatible con dispositivos
            móviles
          </Aclaracion>
          <Imagen404 src={Error404} alt="Imagen Mediana" />
          <TextoInferior>
            Accedé desde una PC para poder utilizar las funciones de gestión.
          </TextoInferior>
        </div>
        <div className="col-4">
          <Logo src={Logo404} alt="Logo" />
        </div>
      </Row>
    </Container>
  );
};

export default AdminAlert;
