import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ImgCar from "../images/Car_row.png";
import IconDest from "../images/icon_destacado.png";
import IconDestBLue from "../images/icon_star_blue.png";
import IconDelete from "../images/icon_delete.png";
import "../styles.css";
import axiosInstance from "../utils/ContextAxios";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 57px;
  align-items: center;
  justify-content: space-around;
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 4px;
  margin: 4px;

  .img-car {
    width: 57px;
    height: 100%;
  }
  .icons {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }
    button {
      border: none;
      background: transparent;
    }
  }
  .file-name {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 180px;
    height: 50px;
  }
  .color-btn {
    width: 100%;
    border: none;
    border-radius: 6px;
    background-color: #eaedf4;
    height: 22px;
    outline: none;
  }
`;

const ImgRow = ({
  image,
  deleteImage,
  colorValue,
  imageUrl,
  colorReturnImg,
  setDestacadaImg,
  favorite,
  index,
}) => {
  const [selectedColor, setSelectedColor] = useState();
  
  const handleDeleteImg = () => {
    deleteImage(image.name);
  };

  useEffect(() => {
    if(image.color){
      if(colorValue){
        const color = colorValue.find(elem => elem.value === image.color)
        const selectValue = color ? color : "#FFFFFF"
        setSelectedColor(selectValue.value)
      }
    }
  },[image])
  
  const handleDestacarButton = () => {
    setDestacadaImg(index);
  };
  const handleColorChange = (e) => {
    setSelectedColor(e.target.value);
    colorReturnImg( e.target.value, index );
  };

  return (
    <Container>
      <img src={imageUrl} alt="car" className="img-car" />
      <div className="file-name">
        {image.name}
        {colorValue && colorValue.length > 0 &&
          <select
            className="color-btn"
            name="color-img"
            value={selectedColor}
            onChange={handleColorChange}
          >
            <option value="">Sin selección de color...</option>
              {colorValue.map((color, index) => (
                <option key={index} value={color.value} id="">
                  {color.label}
                </option>
              ))}
          </select>}
      </div>
      <div className="icons">
        <button className="btn-icon" type="button" onClick={handleDestacarButton}>
          <img
            src={(favorite ===  index) ? IconDestBLue : IconDest}
            alt="Destacar"
            className="icon-destacar"
          />
        </button>
        <button className="delete-img" type="button" onClick={handleDeleteImg}>
          <img src={IconDelete} alt="Eliminar" />
        </button>
      </div>
    </Container>
  );
};

export default ImgRow;
