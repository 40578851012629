import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../styles.css";
import ProductActionMenu from "./ProductActionMenu";
import { BASE_URL } from "../utils/ContextAxios";

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #d0d4de;
  text-align: left;

  align-items: center;
  justify-content: space-between;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  /* border-color: var(--border-row); */
  .img-car {
    object-fit: cover;
    height: 48px;
    width: 80px;
    left: 0;
  }

  .item-car-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  p {
    margin: 0;
    width: 100%;
  }
  .btn-icon {
    border: none;
    background-color: transparent;
  }
`;

const AdminProductRow = ({
  productoData,
  openModalDelete,
  openModalView,
  closeModalDelete,
  isModalDeleteOpen,
  updateList,
}) => {
  const[ img, setImg] = useState(productoData.multimedia[0]?.path)

  useEffect(()=> {
    const imgFavorite = productoData.multimedia.filter( elem => elem.favorite)
    console.log(imgFavorite)
    if(imgFavorite.length > 0) {
      setImg(imgFavorite[0].path)
    }
  },[])
  return (
    <div>
      <StyledContainer>
        <div className="">
          <img
            src={`${BASE_URL}/${
              productoData.multimedia && productoData.multimedia.length > 0
                ? productoData.multimedia.find((media) => media.favorite)
                    ?.path || productoData.multimedia[0].path
                : "path/to/default-image.jpg"
            }`}
            alt={productoData.name}
            className="img-car"
          />
        </div>
        <div className="col-3">
          <p className="item-car-title">
            {productoData.brand.name} {productoData.modelo.name}
          </p>
        </div>
        <div className="col-2">
          <p>{productoData.domain}</p>
        </div>
        <div className="">
          <ProductActionMenu
            openModalDelete={openModalDelete}
            openModalView={openModalView}
            producto={productoData}
            closeModalDelete={closeModalDelete}
            isModalDeleteOpen={isModalDeleteOpen}
            updateList={updateList}
          />
        </div>
      </StyledContainer>
    </div>
  );
};

export default AdminProductRow;
