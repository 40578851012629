const apiRoute = "/api/v1";
//

const ENDPOINTS = {
  // Users
  GET_USER: `${apiRoute}/users/test`,
  CREATE_USER: `${apiRoute}/users/create`,

  //Login

  LOGIN: `${apiRoute}/auth/login`,
  // Car
  // GET_CAR: `${apiRoute}/car`,

  // Attributes
  CREATE_BRAND: `${apiRoute}/attributes/brand`,
  GET_BRANDS: `/api/v1/car/brands`,
  UPLOAD_MULTIMEDIA: (id) => `${apiRoute}/car/upload/${id}`,
  UPDATE_MULTIMEDIA: (id) => `${apiRoute}/car/updateMedia/${id}`,
  CREATE_COLOR: `${apiRoute}/car/newColor`,
  CREATE_CATEGORY: `${apiRoute}/car/newCategory`,
  CREATE_LOCATION: `${apiRoute}/car/newLocation`,
  GET_COLORS: `${apiRoute}/car/color`,
  GET_ATTRIBUTES: `${apiRoute}/attributes/attributes`,
  CREATE_NEW_BRAND: `${apiRoute}/car/newBrand`,
  CREATE_NEW_MODEL: `${apiRoute}/car/newModel`,
  CREATE_NEW_VERSION: `${apiRoute}/car/newVersion`,
  GET_VERSIONS_BY_MODELID: (modelId) => `${apiRoute}/car/versions_by_modeloId/${modelId}`,


  // Used car
  CREATE_USED_CAR: `${apiRoute}/car/used/create`,
  UPDATE_USED_CAR: `/api/v1/car/used`,
  DELETE_USED_CAR: `${apiRoute}/car/used`,
  GET_USED_CAR_BY_ID: (id) => `/api/v1/car/used/${id}`,
  GET_USED_CAR_BY_DOMAIN: (domain) => `${apiRoute}/used/domain/${domain}`,
  GET_USED_CARS: `${apiRoute}/car/used`,
  UPDATE_PRICES: `${apiRoute}/car/used/updatePrice`,
  UPDATE_ALL_PRICES: `${apiRoute}/car/used/updateAllPrices`,

  // New car
  CREATE_NEW_CAR: `${apiRoute}/car/new/create`,
  UPDATE_NEW_CAR: `/api/v1/car/new`,
  GET_NEW_CARS: `${apiRoute}/car/new`,
  GET_NEW_CAR_BY_ID: (id) => `/api/v1/car/new/${id}`,
  DELETE_NEW_CAR:`${apiRoute}/car/new`,
  GET_NEW_CAR_BY_VERSION_ID: (versionId) => `/api/v1/car/new/version/${versionId}`,
  GET_TRANSMISSIONS_BY_MODELO: (modelId) => `/api/v1/car/new/transmission_by_modeloID/${modelId}`,

  // Car
  GET_FAVOURITE_CARS: `${apiRoute}/car/destacados`,

  //EMAIL
  SEND_EMAIL: `${apiRoute}/mail/send`,

  //CRM
  CRM_API: "https://foxcrm.net/app/api/index.php"

};

export default ENDPOINTS;
