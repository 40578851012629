import React from "react";
import styled from "styled-components";

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Fija el spinner en el centro de la pantalla */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5); /* Fondo semitransparente */
  z-index: 9999; /* Asegura que esté por encima de otros elementos */
`;

const Spinner = () => {
  return <div className="spinner" />;
};

const SpinnerComponent = () => {
  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
};

export default SpinnerComponent;
