import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../styles.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const Container = styled.div`
  display: flex;
  position: fixed;
  background-color: white;
  flex-direction: column;
  border-radius: 6px;
  width: 460px;
  height: 270px;
  font-family: var(--font-family);
  font-weight: 700;
  z-index: 1001;
  transform: translate(-50%, -55%);
  top: 50%;
  left: 50%;

  .error-msg {
    color: red;
    margin: 0;
    font-size: 14px;
    padding-top: 8px;
    text-align: center;
    margin-left: 15px;
    position: absolute;
    bottom: 72px;
  }

  button {
    outline: none;
  }
  .options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    display: flex;
    width: 100%;
    height: 81px;
    font-size: 22px;
    line-height: 26px;
    background-color: var(--prime-color);
    color: white;
    justify-content: center;
    align-items: end;
    border-radius: 6px 6px 0 0;
  }

  .dominio {
    display: flex;
    flex-direction: column;
    padding: 24px;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      text-align: start;
    }
    input {
      width: 305px;
      height: 42px;
      background-color: #f6f6f6;
      font-size: 16px;
      line-height: 18.5px;
      font-weight: 500;
      border: none;
      outline: none;
      padding-left: 10px;
    }
  }

  button {
    border-radius: 6px;
    width: 195px;
    height: 36px;
    font-weight: 700;
    border: none;
  }
`;

const ModalAdminAgregarUsado = ({ closeModal }) => {
  const navigate = useNavigate();
  const [dominio, setDominio] = useState("");
  const [dominioExiste, setDominioExiste] = useState(false);

  useEffect(() => {
    localStorage.setItem("Dominio", JSON.stringify(dominio));
  }, [dominio]);

  const handleContinue = async () => {
    if (dominio.length !== 0) {
      try {
        const response = await axiosInstance.get(ENDPOINTS.GET_USED_CARS);
        const data = response.data.data;
        const findDomain = data.find((auto) => auto.domain === dominio);
        console.log(findDomain);

        if (findDomain) {
          setDominioExiste(true);
        } else {
          navigate("/admin/addusado");
        }
      } catch (error) {
        console.error(error);
      }
    } else return;
  };
  const handleInputChange = (e) => {
    setDominio(e.target.value);
    if (e.target.value === "") {
      setDominioExiste(false);
    }
  };
  return (
    <>
      <Overlay onClick={closeModal} />
      <Container>
        <div className="title">
          <p>Agregar Usado</p>
        </div>
        <div className="options">
          <div className="dominio">
            <h3>Dominio</h3>
            <input
              type="text"
              placeholder="| Ingresar dominio"
              value={dominio}
              onChange={handleInputChange}
              id="dominio"
              required
            />
            {dominioExiste && (
              <p className="error-msg">
                El dominio ya existe, por favor ingrese otro.
              </p>
            )}
          </div>
          <button onClick={handleContinue} className="button primary-btn">
            Continuar
          </button>
        </div>
      </Container>
    </>
  );
};

export default ModalAdminAgregarUsado;
