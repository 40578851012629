import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import IconMotor from "../images/icon_motor.png";
import IconTraccion from "../images/icon_traccion.png";
import IconGarantia from "../images/icon_garantia.png";
import IconAsientos from "../images/icon_asientos.png";
import IconCar from "../images/icon_car.png";
import IconTransmision from "../images/icon_transmision.png";
import IconCombustible from "../images/icon_combustible.png";
import IconPuertas from "../images/icon_puertas.png";
import IconInterior from "../images/icon_interior.png";
import IconPasajeros from "../images/icon_pasajeros.png";
import IconAlarma from "../images/icon_alarma.png";
import IconCombBlack from "../images/icon_combustibleBlack.png";
import IconRetroceso from "../images/icon_retroceso.png";
import IconRadio from "../images/icon_radio.png";
import IconSensor from "../images/icon_sensorEstacionamiento.png";
import IconSeguridad from "../images/icon_security1.png";
import IconVelocidad from "../images/icon_velocidad.png";
import IconAirbag from "../images/icon_airbag.png";
import IconLevantaCristales from "../images/icon_levantaCristales.png";
import IconLlantas from "../images/icon_llantas1.png";
import IconColor from "../images/icon_color.png";
import BannerImg from "../images/BannerProducts.png";
import ModalMeInteresa from "../componentes/ModalMeInteresa";
import ModalCotizador from "../componentes/ModalCotizador";
import ModalFinanciacion from "../componentes/ModalFinanciacion1";
import "../styles.css";
import AsideProductUsados from "../componentes/AsideProductUsados";
import { ProdutcUsadoCaracteristicasMobile } from "../componentes/ProdutcUsadoCaracteristicasMobile";
import SliderCar from "../componentes/CarrouselCarModelo";
import { Link, useParams } from "react-router-dom";
import SliderUsadoMobile from "../componentes/CarrouselCarUsadoMobile";
import Spinner from "../componentes/Loader";
import WhatsAppBtn from "../componentes/WhatsAppBtn";
import ENDPOINTS from "../apiEndpoints";
import axiosInstance from "../utils/ContextAxios";
import Price from "../componentes/Price";

const MarginNav = styled.div`
  display: block;
  background-color: black;
  padding-top: 114px;
  @media (max-width: 576px) {
    padding-top: 48px;
  }
`;
const Container = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;

  .banner {
    height: 149px;
    width: 100%;
    padding: 0;
    margin: 20px 0px;
    margin-top: 30px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .title-mobile {
    display: none;

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 18px;
      }
      .car-name {
        font-size: 19px;
        line-height: 19px;
        font-weight: 700;
      }
      .car-price {
        color: var(--prime-color);
        font-size: 21px;
        line-height: 21px;
        font-weight: 700;
        margin-top: 8px;
      }
    }
  }
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    .banner {
      display: none;
    }
    .margin-mobile {
      display: block;
      width: 100%;
      margin-top: 18px;
      border: 1px solid black;
    }
  }
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
`;
const ItemsCarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  margin-top: 45px;
  width: 100%;
  height: 187px;
  padding: 10px 0px;
  border-radius: 6px;

  .items-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .garantia {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 30px;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
  .card-motor {
    border: transparent;
    border-right: 1px solid var(--gris-medio);
    img {
      margin-top: 4px;
    }
  }
  .card-asientos {
    border: transparent;
    border-right: 1px solid var(--gris-medio);
  }
  .card-transmision {
    border: transparent;
    border-right: 1px solid var(--gris-medio);
  }
  .card-interior {
    border: transparent;
    border-left: 1px solid var(--gris-medio);
  }

  .icon-img {
    max-width: 20px;
    max-height: 20px;
    margin-right: 5px;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;
const ItemsCarContainerMobile = styled.div`
  display: none;

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 30px;
    height: 138px;
    background-color: #f6f6f6;
    padding: 8px;
    border-radius: 6px;
    .side-1 {
      width: 50%;
      height: 100%;
      border-right: 1px solid var(--gris-medio);
    }
    .side-2 {
      width: 50%;
      height: 100%;
    }
    .garantia {
      display: flex;
      justify-content: center;
      text-align: center;
      height: 15px;
      font-family: var(--font-family);
      font-size: 13px;
      font-weight: 400;
      line-height: 13px;
      margin-top: 20px;

      .icon-img {
        max-width: 16px;
        max-height: 16px;
        margin-right: 6px;
      }
    }
  }
`;
const BreadcrumbContainer = styled.div`
  width: 100%;
  align-items: center;
  background-color: #eaedf4;
  display: flex;
  height: 32px;
  @media (max-width: 576px) {
    display: none;
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  width: 300px;
  height: 100%;
  padding-top: 15px;
  align-items: center;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;

  a {
    list-style: none;
    text-decoration: none;
    font-weight: 400;
    color: #595e62;
  }
`;
const ItemCar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 185px;
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 18px;
  font-size: 16px;
  background-color: #f6f6f6;
  text-align: center;
  align-items: center;

  .img-container {
    margin: 6px 0;
    justify-content: center;
    height: 68px;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    /* padding: 6px; */
    /* height: 40%; */
  }

  span {
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
  }

  @media (max-width: 576px) {
    display: flex;
    flex-direction: row;
    height: 50px;
    font-weight: 700;
    line-height: 13px;
    font-size: 13px;
    /* align-items: center; */

    padding: 5px;
    justify-content: space-evenly;
    border: 2px solid green;

    .card-img {
      max-width: 28px;
      height: auto;
      padding: 0;
      margin: 0;
    }
    .info-box {
      display: flex;
      flex-direction: column;
      height: 100%;
      text-align: start;
      font-family: var(--roboto);
      span {
        font-size: 13px;
      }

      p {
        width: 100%;
      }
    }
    .img-container {
      width: auto;
      height: auto;
    }
  }
`;
const ItemCarMobile = styled.div`
  display: none;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: row;
    height: 42px;
    font-weight: 700;
    line-height: 14px;
    font-size: 14px;
    padding: 10px;
    align-items: center;

    .info-box {
      display: flex;
      flex-direction: column;

      height: 100%;
      text-align: left;
      font-family: var(--roboto);
      width: 100%;
      span {
        font-size: 13px;
      }
    }
    .img-container {
      width: auto;
      height: 100%;
      margin: 0px 6px;
    }
  }
`;
const Caracteristicas = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  .title {
    margin-top: 48px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
   .title-caract{
    margin-top:0px;
  }
  .icons {
    display: flex;
    width: 100%;
  }

  .icon-div {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 12px 0px;
  }
  .icon-div p {
    margin-bottom: -5px;
    padding: 0;
  }

  img {
    margin-right: 8px;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;
const ContainerCaracteristicas = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 54px;
  width: 100%;
  height: 100%;
  @media (max-width: 576px) {
    display: none;
  }
`;

const ProductoUsado = () => {
  const [producto, setProducto] = useState(null);
  const [formData, setFormData] = useState(null);
  const [modalAsesorVisible, setModalAsesorVisible] = useState(false);
  const [modalMeInteresaVisible, setModalMeInteresaVisible] = useState(false);
  const [modalCotizadorVisible, setModalCotizadorVisible] = useState(false);
  const [modalFinanciacionVisible, setModalFinanciacionVisible] =
    useState(false);

    const {productId} = useParams();

  //pedir datos
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          ENDPOINTS.GET_USED_CAR_BY_ID(productId)
        );
        console.log(response.data);
        setProducto(response.data.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchData();
  }, []);

  if (!producto) {
    return <Spinner />; // Muestra un mensaje mientras se carga la data
  }

  const closeModalCotizador = () => {
    setModalCotizadorVisible(false);
  };
  const closeModalMeInteresa = () => {
    setModalMeInteresaVisible(false);
  };

  const closeModalFinanciacion = () => {
    setModalFinanciacionVisible(false);
  };

  const handleAsesorClick = () => {
    setModalAsesorVisible(true);
  };

  const handleFinanciacionClick = () => {
    setModalFinanciacionVisible(true);
  };

  const handleCotizadorClick = () => {
    setModalCotizadorVisible(true);
  };

  const handleMeInteresaClick = () => {
    setModalMeInteresaVisible(true);
  };

  const handleCotizadorFormData = (formData) => {
    setFormData(formData);

    //console.log(formData);
  };

  return (
    <Container className="container-fluid">
      <Navbar />
      <MarginNav />
      <BreadcrumbContainer>
        <div className="container">
          <div className="row">
            <div className="col-3">
              <Breadcrumb>
                <p>
                  <Link to={"/usados"}>Vehículos Usados</Link>
                  <span style={{ color: "#595e62" }}>{" > "}</span>
                  {producto.brand.name} {producto.modelo.name}
                </p>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </BreadcrumbContainer>
      <SliderUsadoMobile productoImg={producto.multimedia} />
      <div className="container">
        {modalMeInteresaVisible && (
          <ModalMeInteresa closeModal={closeModalMeInteresa} car={producto}/>
        )}
        {modalCotizadorVisible && (
          <ModalCotizador
            closeModal={closeModalCotizador}
            onSubmit={handleCotizadorFormData}
          />
        )}
        {modalFinanciacionVisible && (
          <ModalFinanciacion closeModal={closeModalFinanciacion} />
        )}

        <div className="row">
          <StyledContainer>
            <div className="col-md-7">
              <div>
                <SliderCar productoImg={producto.multimedia} />

                <div className="title-mobile offset-3 col-6">
                  <p className="car-name">
                    {producto.brand.name} {producto.modelo.name}
                  </p>
                  <p>{producto.version.name}</p>

                  <p className="car-price"><Price value={producto.price} /></p>
                </div>
              </div>
              <div>
                <ItemsCarContainer>
                  <div className="items-container">
                    <ItemCar className=" card-motor">
                      <div className="img-container">
                        <img
                          src={IconMotor}
                          alt="IconMotor"
                          className="card-img img-motor"
                        />
                      </div>
                      <div className="info-box">
                        <p>
                          <span>Motor & Potencia</span>
                          <br />
                          {producto.motor} | {producto.cv}
                        </p>
                      </div>
                    </ItemCar>
                    <ItemCar className="card-transmision">
                      <div className="img-container">
                        <img
                          src={IconTransmision}
                          alt="iconTransmision"
                          className="card-img"
                        />
                      </div>
                      <div className="info-box">
                        <p>
                          <span>Transmision</span>
                          <br />
                          {producto.transmission.map((option, index) => (
                            <>
                              {option}
                              <br />
                            </>
                          ))}
                        </p>
                      </div>
                    </ItemCar>
                    <ItemCar className="card-puertas">
                      <div className="img-container">
                        <img
                          src={IconPuertas}
                          alt="Producto"
                          className="card-img"
                        />
                      </div>
                      <div className="info-box">
                        <p>
                          <span>Puertas</span>
                          <br />
                          {producto.doors}
                        </p>
                      </div>
                    </ItemCar>
                    <ItemCar className="card-interior">
                      <div className="img-container">
                        <img
                          src={IconInterior}
                          alt="Producto"
                          className="card-img"
                        />
                      </div>
                      <div className="info-box">
                        <p>
                          <span>Interior</span>
                          <br />
                          {producto.seatMaterial}
                        </p>
                      </div>
                    </ItemCar>
                  </div>
                </ItemsCarContainer>
                <ItemsCarContainerMobile>
                  <div className="d-flex">
                    <div className="side-1">
                      <ItemCarMobile className="card-motor">
                        <div className="img-container">
                          <img
                            src={IconMotor}
                            alt="IconMotor"
                            className="card-img img-motor"
                          />
                        </div>
                        <div className="info-box">
                          <p>
                            <span>Motor & Potencia</span>
                            <br />
                            {producto.motor} | {producto.cv}
                          </p>
                        </div>
                      </ItemCarMobile>
                      <ItemCarMobile className="card-combustible">
                        <div className="img-container">
                          <img
                            src={IconCombustible}
                            alt="Producto"
                            className="card-img"
                          />
                        </div>
                        <div className="info-box">
                          <p>
                            <span>Combustible</span>
                            <br />
                            {producto.fuel}
                          </p>
                        </div>
                      </ItemCarMobile>
                    </div>
                    <div className="side-2">
                      <ItemCarMobile className="card-asientos">
                        <div className="img-container">
                          <img
                            src={IconAsientos}
                            alt="iconAsientos"
                            className="card-img"
                          />
                        </div>
                        <div className="info-box">
                          <p>
                            <span>Asientos</span>
                            <br />
                            {producto.seating}
                          </p>
                        </div>
                      </ItemCarMobile>
                      <ItemCarMobile className="card-interior">
                        <div className="img-container">
                          <img
                            src={IconInterior}
                            alt="Producto"
                            className="card-img"
                          />
                        </div>
                        <div className="info-box">
                          <p>
                            <span>Interior</span>
                            <br />
                            {producto.seatMaterial}
                          </p>
                        </div>
                      </ItemCarMobile>
                    </div>
                  </div>
                  {/* <div className="garantia">
                    <img
                      src={IconGarantia}
                      alt="icon_garantia"
                      className="icon-img"
                    />
                    Garantía: 3 años o 100.000 km
                  </div> */}
                </ItemsCarContainerMobile>
              </div>

              <div className="row">
                <ProdutcUsadoCaracteristicasMobile producto={producto} />
              </div>
              <div className="margin-mobile"></div>
            </div>
            <div className="offset-md-1 col-md-4 offset-1 col-10">
              <AsideProductUsados
                handleCotizadorClick={handleCotizadorClick}
                handleAsesorClick={handleAsesorClick}
                handleFinanciacionClick={handleFinanciacionClick}
                handleMeInteresaClick={handleMeInteresaClick}
                producto={producto}
                formData={formData}
              />
            </div>
          </StyledContainer>
        </div>

        <ContainerCaracteristicas>
          <Caracteristicas>
            <div className="title title-caract">
              <p>Características Principales</p>
            </div>
            <div className="row">
              <div className="icons">
                <div className="icon-div col-4">
                  <img src={IconCar} alt="iconCar" />
                  <div>
                    <p>Tipo: {producto.category.name}</p>
                  </div>
                </div>
                <div className="icon-div offset-1 col-4">
                  <img src={IconCombBlack} alt="iconCombustible" />
                  <div>
                    <p>Combustible: {producto.fuel}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="icons">
                <div className="icon-div col-4">
                  <img src={IconTraccion} alt="iconTraccion" />
                  <div>
                    <p>Tracción: {producto.traction}</p>
                  </div>
                </div>
                <div className="icon-div offset-1 col-4">
                  <img src={IconPasajeros} alt="iconPasajeros" />
                  <div>
                    <p>Pasajeros: {producto.seating}</p>
                  </div>
                </div>
              </div>
            </div>
          </Caracteristicas>
          <Caracteristicas>
            <div className="title">
              <p>Exterior</p>
            </div>
            <div className="row">
              <div className="icons">
                <div className="icon-div col-4">
                  <img src={IconLlantas} alt="iconLlantas" />
                  <div>
                    <p>Mat. Llantas: {producto.tyreMaterial}</p>
                  </div>
                </div>
                <div className="icon-div offset-1 col-4">
                  <img src={IconColor} alt="iconColor" />
                  <div>
                    <p>Color: {producto.color.name}</p>
                  </div>
                </div>
              </div>
            </div>
          </Caracteristicas>
          <Caracteristicas>
            <div className="title">
              <p>Características adicionales</p>
            </div>
            <div className="row">
              <div className="icons">
                <div className="icon-div col-4">
                  <img src={IconLevantaCristales} alt="iconLevantaCristales" />
                  <div>
                    <p>
                      Levanta cristales eléctricos:{" "}
                      {producto.liftsWindows ? "Si" : "No"}
                    </p>
                  </div>
                </div>
                <div className="icon-div offset-1 col-4">
                  <img src={IconVelocidad} alt="iconVelocidad" />
                  <div>
                    <p>
                      Control de velocidad:{" "}
                      {producto.speedControl ? "Si" : "No"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="icons">
                <div className="icon-div col-4">
                  <img src={IconRetroceso} alt="iconRetroceso" />
                  <div>
                    <p>
                      Cámara de retroceso:{" "}
                      {producto.backupCamera ? "Si" : "No"}
                    </p>
                  </div>
                </div>
                <div className="icon-div offset-1 col-4">
                  <img src={IconAlarma} alt="iconAlarma" />
                  <div>
                    <p>Alarma: {producto.alarm ? "Si" : "No"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="icons">
                <div className="icon-div col-4">
                  <img src={IconSensor} alt="iconSensor" />
                  <div>
                    <p>
                      Sensor de estacionamiento:
                      {producto.parkingSensor ? "Si" : "No"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Caracteristicas>
          <Caracteristicas>
            <div className="title">
              <p>Seguridad</p>
            </div>
            <div className="row">
              <div className="icons">
                <div className="icon-div col-4">
                  <img src={IconSeguridad} alt="iconSeguridad" />
                  <div>
                    <p>ABS: {producto.abs ? "Si" : "No"}</p>
                  </div>
                </div>
                <div className="icon-div offset-1 col-4">
                  <img src={IconAirbag} alt="iconAirbag" />
                  <div>
                    <p>Airbargs: {producto.airbag}</p>
                  </div>
                </div>
              </div>
            </div>
          </Caracteristicas>
          <Caracteristicas>
            <div className="title">
              <p>Entretenimiento</p>
            </div>
            <div className="row">
              <div className="icons">
                <div className="icon-div col-4">
                  <img src={IconRadio} alt="iconRadio" />
                  <div>
                    <p>Radio: {producto.radio ? "Si" : "No"}</p>
                  </div>
                </div>
                <div className="icon-div offset-1 col-4">
                  <img src={IconRadio} alt="iconRadio" />
                  <div>
                    <p>{producto.entertainment}</p>
                  </div>
                </div>
              </div>
            </div>
          </Caracteristicas>
        </ContainerCaracteristicas>

        <div className="banner">
          <img alt="bannerimg" src={BannerImg} />
        </div>
      </div>
      <WhatsAppBtn />
      <Footer />
    </Container>
  );
};

export default ProductoUsado;
