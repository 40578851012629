import React, { useState } from "react";
import styled from "styled-components";
import "../styles.css";
import nave2007 from "../images/nave2007.png";
import nave2012 from "../images/nave2012.png";
import nave2015 from "../images/nave2015.png";
import nave2017 from "../images/nave2017.png";
import nave2020 from "../images/nave2020.png";
import nave2024 from "../images/nave2024.jpg";
const Container = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
`;
const StyledFichero = styled.div`
  padding: 0;
  /* justify-content: center; */
  .file-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    // height: 280px;
    height: max-content;
    min-height: 260px;
    width: 100%;
    border-radius: 16px !important;
    /* content-visibility: auto; */
  }

  .file {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    margin-right: -1px;
    cursor: pointer;
    transition: width 0s;
    overflow: hidden;
    background-color: white;
    min-height: 100%;
    width: 7.1%;
    box-shadow: -3px 0px 3px 0px rgb(0 0 0 / 6%);
  }
  .year-title {
    text-align: left;
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: var(--prime-color);
    transform: rotate(90deg); /* Rotación de 90 grados */
    transform-origin: center; /* Punto de origen de la rotación */
    display: ${(props) => (props.isSelected ? "none" : "block")};
  }
  .year {
    margin-top: 20px;
    text-align: left;
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: var(--prime-color);
    margin-bottom: 35px;
  }
  p {
    color: white;
    text-align: left;
    font-size: 16px;
    line-height: 18.5px;
  }

  .file-content {
    padding-left: 40px;
    width: 80%;
    height: 100%;
    justify-content: center;
    align-items: center; /* Centrar contenido verticalmente */
  }

  .file-content .year-empty {
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-top: 40px;
    color: var(--prime-color);
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    transform: rotate(270deg); /* Rotar el año 90 grados */
    white-space: nowrap; /* Evitar que el texto se divida en varias líneas */
  }

  .file.selected {
    width: 58%;
    background-color: var(--prime-color);
    color: white;
  }

  .file.selected .year {
    color: white;
  }
`;
const StyledImage = styled.img`
  // width: 170px;
  background-color: #ffffff;
  // padding: 10px;
  display: flex;

  padding: ${(props) => (props.paddingEdited ? "0px" : "10px")};
  width: ${(props) => (props.widthEdited ? "120px" : "170px")};
`;
const ficha = [
  {
    year: "2002",
    text: "Navemotors nace en 2002 en medio de la crisis del 2001 en Argentina. Desde el principio, enfrentando la desconfianza generalizada, iniciamos la venta de vehículos usados y 0km. A lo largo de 21 años, hemos mantenido nuestro compromiso de brindar soluciones a los clientes, destacando la transparencia como un objetivo primordial.",
  },
  {
    year: "2007",
    text: "En 2007, dimos un gran paso al abrir nuestro primer concesionario oficial en Quilmes, abocado a la marca Citroën. Este logro fue el resultado de 5 años de trabajo arduo y una apuesta significativa para expandir el Grupo.",
    image: nave2007,
    widthEdited: false,
    paddingEdited: false,
  },
  {
    year: "2012",
    text: "Cinco años después, en 2012, alcanzamos otro hito al inaugurar nuestra segunda concesionaria oficial, Lumiere, especializada en Renault y ubicada también en Quilmes.",
    image: nave2012,
    widthEdited: false,
    paddingEdited: false,
  },
  {
    year: "2015",
    text: " A medida que los años avanzaban, el grupo creció, llegando a ser entre las 3 empresas hasta el momento, más de 200 personas trabajando. En 2015, se incorporó la tercera marca oficial, Fiat, con Verona en Avellaneda.",
    image: nave2015,
    widthEdited: false,
    paddingEdited: false,
  },
  {
    year: "2017",
    text: "En 2017, celebramos 15 años en la industria, destacando la persistencia del grupo para lograr objetivos. Este año marcó la apertura de la cuarta concesionaria oficial, Freeway automóviles, dedicada a la reconocida marca Jeep en Adrogué, alcanzando un récord de 450 empleados.",
    image: nave2017,
    widthEdited: true,
    paddingEdited: false,
  },
  {
    year: "2020",
    text: "El difícil año 2020, marcado por la pandemia de Covid-19, puso a prueba al grupo, casi llevándonos al cierre. Sin embargo, superamos la crisis y, en 2021, inauguramos la concesionaria Peugeot en Ramos Mejía. A pesar de los desafíos, nos destacamos entre los 5 mejores grupos concesionarios de Argentina, obteniendo reconocimientos por calidad y ventas.",
    image: nave2020,
    widthEdited: false,
    paddingEdited: true,
  },
  {
    year: "2024",
    text: "En la actualidad, en 2024, seguimos creciendo y aprendiendo día a día. Tras un arduo trabajo, nos lanzamos a Nave.com.ar, dando un salto en la experiencia y satisfacción del cliente.",
    image: nave2024,
    widthEdited: false,
    paddingEdited: true,
  },
];
const Fichero = () => {
  const [selectedFile, setSelectedFile] = useState(0);

  const toggleFileContent = (index) => {
    setSelectedFile(index);
  };

  return (
    <Container className="container">
      <StyledFichero className="col-md-12">
        <div className="file-container">
          {ficha.map((item, index) => (
            <div
              key={index}
              className={`file ${selectedFile === index ? "selected" : ""}`}
              onClick={() => toggleFileContent(index)}
            >
              {selectedFile === index ? (
                <div className="file-content">
                  <div
                    className="year"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    {item.year}
                    {item.image && (
                      <StyledImage
                        src={item.image}
                        alt={item.year}
                        widthEdited={item.widthEdited}
                        paddingEdited={item.paddingEdited}
                      />
                    )}
                  </div>
                  <p>{item.text}</p>
                </div>
              ) : (
                <div className="file-content">
                  <div className="year-empty">{item.year}</div>
                </div>
              )}
            </div>
          ))}
        </div>
      </StyledFichero>
    </Container>
  );
};

export default Fichero;
