import React, { useState } from "react";
import styled from "styled-components";
import "../styles.css";
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const Container = styled.div`
  display: flex;
  position: fixed;
  background-color: white;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  width: 248px;
  height: 232px;
  font-family: var(--font-family);
  font-weight: 700;
  z-index: 1001;
  transform: translate(-50%, -55%);
  top: 50%;
  left: 50%;

  .title {
    display: flex;
    width: 100%;
    height: 81px;
    font-size: 22px;
    line-height: 26px;
    background-color: var(--prime-color);
    color: white;
    justify-content: center;
    align-items: center;
    border-radius: 6px 6px 0 0;
    p {
      padding-top: 25px;
    }
  }

  button {
    border-radius: 6px;
    width: 83px;
    height: 32px;
    font-weight: 700;
    border: none;
    align-items: center;
  }
  .newcolor-name {
    height: 32px;
    width: 180px;
    border-radius: 6px;
    padding-left: 8px;
    font-size: 16px;
    line-height: 18.5px;
    font-weight: 500;
    border: transparent;
    background-color: var(--gris-invisible);
  }
  .color-box {
    display: flex;
    width: 100%;
    justify-content: center;
    input {
      width: 32px;
      margin-left: 8px;
      border: transparent;
    }
  }
  .item {
    align-items: center;
    justify-content: center;
    margin: 8px 0px;
  }
  input:focus {
    border: none;
  }
  .options {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top:24px;
    margin-bottom:12px;
  }
  .color-sample {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid black;
  }
    .color-hexa{
      width:80px !important;
    }
`;

const AgregarColor = ({ closeModal, onColorAdded }) => {
  const [colorName, setColorName] = useState("");
  const [colorCode, setColorCode] = useState("#000000");

  const handleColorNameChange = (e) => {
    setColorName(e.target.value); // Actualiza el estado del nombre del color
  };
  const handleColorHexaChange = (e) => {
    const hex = e.target.value;
    setColorCode(hex); // Actualiza el estado del código hexadecimal del color
  };
  const handleColorPickerChange = (e) => {
    const hex = e.target.value;
    setColorCode(hex); // Actualiza el valor hexadecimal al seleccionar un color
  };

  const handleAddColor = () => {
    // Crea un objeto con los datos del color
    const colorData = {
      name: colorName,
      code: colorCode,
    };
    // Llama a la función proporcionada por el componente padre para pasar los datos del color
    onColorAdded(colorData);
    // Cierra el modal
    closeModal();
  };

  return (
    <>
      <Overlay onClick={closeModal} />
      <Container>
        <div className="title">
          <p>Agregar color</p>
        </div>
        <div className="options">
          <div className="item color-name">
            <input
              type="text"
              placeholder="Nombre del color"
              className="newcolor-name"
              value={colorName}
              onChange={handleColorNameChange} // Escucha cambios en el nombre del color
            />
          </div>
          <div className="item color-box">
            <input
              type="text"
              placeholder="Cód. Hexadecimal"
              className="color-hexa"
              value={colorCode}
              onChange={handleColorHexaChange} // Escucha cambios en el código hexadecimal del color
            />
            <input
              className="color-sample"
              type="color"
              value={colorCode}
              onChange={handleColorPickerChange} // Escucha cambios en el input de color
            />
          </div>
          <button onClick={handleAddColor} className="button primary-btn">
            ok
          </button>
        </div>
      </Container>
    </>
  );
};

export default AgregarColor;
/******/
