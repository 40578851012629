import React from "react";
import styled from "styled-components";
import "../styles.css";
import logofooter from "../images/logomobile.png";
import iconfacef from "../images/iconfacef.svg";
import icontweetf from "../images/icontweetf.svg";
import { Link } from "react-router-dom";

const StyledFooter = styled.footer`
  display: none;
  flex-direction: column;
  width: 100%;
  /* height: 120px; */
  align-items: center;
  justify-content: space-evenly;
  color: black;
  padding: 40px 0px;
  /* margin: 55px 0px; */

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 10px;
    line-height: 7px;
    justify-content: center;
    margin: 10px 0px;

    p {
      margin: 0; // Elimina el margen del párrafo
    }
  }
  .logo {
    max-height: 11px;
    width: auto;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .redes-icons {
    display: flex;

    // Estilo para los enlaces
    a {
      margin-right: 15px; // Ajusta el espacio entre los iconos
      img {
        // Estilo para las imágenes dentro de los enlaces
        width: 13px; // Ajusta el tamaño de la imagen según sea necesario
        height: 13px;
        // Puedes ajustar más estilos según tus necesidades
      }
    }
  }

  @media (max-width: 576px) {
    display: flex;
  }
`;

const FooterMobile = () => {
  return (
    <StyledFooter>
      <div>
        <Link to="/" className="logo">
          <img src={logofooter} alt="Logo" />
        </Link>
      </div>
      <div className="info">
        <p>Área Ventas: +54 9 11 3113-8421</p>
        <br />
        <p>Administración: +54 9 11 2627-1875</p>
        <br />
        <p> Postventa: +54 911 6621-1172</p>
      </div>
      <div className="redes-icons">
        <Link
          to="https://www.facebook.com/Nave.com.ar"
          rel="noopener"
          target="_blank"
        >
          <img src={icontweetf} alt="Facebook" />
        </Link>
        <Link
          to="https://www.instagram.com/nave.com.ar/"
          rel="noopener"
          target="_blank"
        >
          <img src={iconfacef} alt="Instagram" />
        </Link>
      </div>
    </StyledFooter>
  );
};

export default FooterMobile;
