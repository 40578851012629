import React, { useState } from "react";
import styled from "styled-components";
import "../styles.css";
import axios from "axios";
import Swal from "sweetalert2";
import ENDPOINTS from "../apiEndpoints";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const CotizadorForm = styled.form`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 16px;
  z-index: 1000;
  padding: 0 40px;
  width: 388px;
  height: 502px;

  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  .text-box {
    text-align: center;
    font-family: var(--font-family);
    line-height: 18.5px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .bold-text {
    font-weight: 700;
  }

  .form-container {
    /* margin-bottom: 18px; */
    align-items: center;
    justify-content: space-between;
    input {
      font-family: var(--roboto);
      height: 42px;
      width: 300px;
      border-radius: 6px;
      border: 2px solid var(--gris-claro);
      margin-bottom: 18px;
      outline: none;
      padding-left: 8px;
    }
  }
  button {
    width: 197px;
    outline: none;
  }

  @media (max-width: 576px) {
    width: 267px;
    height: 483px;
    border-top: 22px solid var(--prime-color);
    font-size: 13px;
    line-height: 13px;
    padding: 20px;

    .text-box {
      text-align: center;
      line-height: 14px;
      font-size: 14px;
      padding: 0 5px;
      margin: 0;
    }
    .form-container {
      margin-bottom: 5px;
      padding-left: 5px;

      input {
        font-family: var(--roboto);
        height: 42px;
        width: 216px;
        border-radius: 6px;
        border: 2px solid var(--gris-claro);
        margin-bottom: 16px;
      }
    }
    button {
      width: 216px;
    }
  }
`;

const ModalCotizatedPage = ({
  closeModal,
  formularioAutoCotizado,
  resetFormulario,
  motivo
}) => {
  const [formulario, setFormulario] = useState({
    name: "",
    email: "",
    codpostal: "",
    tel: "",
  });

  // Función para manejar el cambio en los inputs
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [id]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const note = `Color: ${formularioAutoCotizado.color} | 
                \nVersión: ${formularioAutoCotizado.version} | 
                \nTransmisión: ${formularioAutoCotizado.transmission} | 
                \nKilometraje: ${formularioAutoCotizado.mileage} | 
                \nMotivo: ${motivo}`;

    const data = {
      fox_api_key: process.env.REACT_APP_FOX_API_KEY || 'ABCDE-FGHIJ-KLMNO',
      fox_api_origin: process.env.REACT_APP_FOX_API_ORIGIN || 'ads',
      fox_api_business_type_id: 1,
      fox_api_first_name: formulario.name,
      fox_api_last_name: '',
      fox_api_phone: formulario.tel,
      fox_api_cellphone: formulario.tel,
      fox_api_email: formulario.email,
      fox_api_notes: note || "Sin mensaje",
      fox_api_brand: formularioAutoCotizado.brand,
      fox_api_model: formularioAutoCotizado.modelo,
      fox_api_country: 'Argentina',
      fox_api_state: 'Buenos Aires',
      fox_api_city: formulario.codpostal,
      fox_api_url: window.location,
      fox_api_dni: '00000000',
      fox_api_vendor: 'Mi nombre de proveedor'
    };

    // Enviar datos del formulario a través de Axios
    axios.post(ENDPOINTS.CRM_API, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((res) => {
      console.log(res)
      const { data } = res
      if (data.error === 1) {
        Swal.fire({
          icon: 'error',
          title: '¡Ha ocurrido un error!',
          text: data.message,
          showConfirmButton: false,
          iconColor: '#dc3545',
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: '¡Formulado enviado con éxito!',
          text: 'Un asesor se comunicará contigo pronto.',

          iconColor: '#1A3EEA',
          showConfirmButton: false,
          timer: 2000,
        });
        setFormulario({
          name: "",
          email: "",
          codpostal: "",
          tel: "",
        });
        resetFormulario();
        closeModal();
      }

    }).catch(err => {
      console.error(err);
      Swal.fire({
        icon: 'error',
        title: '¡Ha ocurrido un error!',
        text: 'No se han podido enviar los datos',
        showConfirmButton: false,
        iconColor: '#dc3545',
        timer: 2000,
      });
    })

  };
  return (
    <>
      <Overlay onClick={closeModal} tabIndex="0" />
      <CotizadorForm onSubmit={handleSubmit}>
        <div className="text-box">
          <p className="bold-text">
            La información que nos brindaste ayuda a que nuestro equipo prepare
            una oferta ideal para tu vehículo y tu solicitud.
          </p>
          <p>Dejanos tus datos y nos pondremos en contacto a la brevedad.</p>
        </div>
        <div className="form-container">
          <input
            type="text"
            id="name"
            placeholder="Nombre Completo"
            autoComplete="none"
            required
            value={formulario.name}
            onChange={handleChange}
          />

          <input
            type="email"
            id="email"
            placeholder="Email"
            autoComplete="none"
            required
            value={formulario.email}
            onChange={handleChange}
          />

          {/* Campo C.Postal */}
          <input
            type="text"
            id="codpostal"
            autoComplete="none"
            placeholder="Código Postal"
            required
            value={formulario.codpostal}
            onChange={handleChange}
          />
          {/* Campo Celular */}
          <input
            type="tel"
            id="tel"
            placeholder="Celular"
            autoComplete="none"
            required
            value={formulario.tel}
            onChange={handleChange}
          />
        </div>
        <button className="primary-btn-big">Enviar datos</button>
      </CotizadorForm>
    </>
  );
};

export default ModalCotizatedPage;
