import React, { useRef, useState } from "react";
import AdminNavbarHome from "../componentes/AdminNavbarHome";
import styled from "styled-components";
import "../styles.css";
import Lupa from "../images/icon_lupa.png";
import ModalAdminAgregarNuevoModelo from "../componentes/ModalAdminAgregarNuevoModelo";
import ModalActualizarPrecios from "../componentes/ModalAdminActualizarPrecios";
import ModalAdminAgregarUsado from "../componentes/ModalAdminAgregarUsado";
import AdminAlert from "../componentes/AdminResponsiveError";
import AdminProductsNuevos from "./AdminProductsNuevos";
import AdminProductsUsados from "./AdminProductsUsados";
import Swal from "sweetalert2";

const Container = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  @media (max-width: 576px) {
    display: none;
  }
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: var(--font-family);

  .text {
    width: 100%;
    display: block;
    margin-top: 48px;
    text-align: left;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
  }
  .subtitle {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
`;

const SearchOptions = styled.div`
  display: flex;
  height: 36px;
  /* justify-content: space-between; */
  margin-top: 32px;
  margin-bottom: 48px;
  width: 100%;
  .add-btn {
    border: none;
  }
  button {
    width: 100%;
    height: 100%;
  }
  .searchInput {
    width: 100%;
    height: 36px;
    color: var(--placeholder-text);
    background-color: var(--placeholder-color);
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    border: none;

    outline: none;
    border-radius: 4px;
    background-image: url(${Lupa});
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 15px;
    padding-left: 40px;
  }

  .new-btn {
    font-weight: 700;
    border: none;
    width: 100%;
    margin: 0;
    height: 36px;
    text-align: center;
    cursor: pointer;
  }
`;

const MainContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 60px;

  button {
    outline: none;
  }
  .catalogo-row {
    height: 500px;
    overflow-y: scroll;
    scrollbar-width: thin;
    width: 100%;
  }
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  padding: 0;
  font-family: var(--font-family);
  border-right: 1px black solid;
`;

const StyledButton = styled.button`
  width: 100%;
  height: 40px;
  padding: 0;
  font-weight: 700;
  font-size: 16px;

  border: transparent;
  background-color: transparent;
  text-align: left;
  margin-bottom: 10px;
  cursor: pointer;
  transition: color 0.3s, border-bottom 0.3s;
`;
const ActiveButton = styled(StyledButton)`
  color: var(--prime-color);
  border-bottom: 1px solid var(--prime-color);
  line-height: 16px;
`;

const InactiveButton = styled(StyledButton)`
  color: var(--gris-medio);
  border-bottom: 1px solid var(--gris-medio);
  line-height: 16px;
`;
const AdminHome = () => {
  // const [visibleProducts, setVisibleProducts] = useState(
  //   data.slice(0, itemsPerPage)
  // );
  const [isModalAgregarOpen, setModalAgregarOpen] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [isModalViewOpen, setModalViewOpen] = useState(false); //estado de boton nuevo producto
  const [isModalAddNew, setModalAddNew] = useState(false);
  const [isModalActualizarPreciosOpen, setModalActualizarPreciosOpen] =
    useState(false);
  const [activeSection, setActiveSection] = useState("nuevos");
  const [activeMenu, setActiveMenu] = useState("nuevos");
  const [searchValueNuevos, setSearchValueNuevos] = useState("");
  const [searchValueUsados, setSearchValueUsados] = useState("");

  const handleMenuChange = (menu) => {
    setActiveMenu(menu);
  };
  const handleSearchNuevos = (value) => {
    setSearchValueNuevos(value);
  };

  const handleSearchUsados = (value) => {
    setSearchValueUsados(value);
  };
  const openModalDelete = () => {
    setModalDeleteOpen(true);
  };

  const openModalAddNew = () => {
    setModalAddNew(true);
  };
  const closeModalAddNew = () => {
    setModalAddNew(false);
  };
  const closeModalDelete = () => {
    setModalDeleteOpen(false);
  };

  const openModalView = () => {
    setModalViewOpen(true);
  };

  const closeModalView = () => {
    setModalViewOpen(false);
  };
  const openActualizarPrecios = () => {
    setModalActualizarPreciosOpen(true);
  };
  const closeActualizarPrecios = () => {
    setModalActualizarPreciosOpen(false);
  };

  const openModalAgregar = () => {
    setModalAgregarOpen(true);
  };
  const closeModalAgregar = () => {
    setModalAgregarOpen(false);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const handleSuccessPricesUpdate = (data) => {
    const { status } = data;
    if (status === 'success') {
    setModalActualizarPreciosOpen(false)
      Swal.fire({
        icon:'success',
        title: 'Precios actualizados correctamente',
        iconColor: '#1A3EEA',
        showConfirmButton: false,
        timer: 2000,
      })
    }else {
      Swal.fire({
        icon:'error',
        title: 'Ha ocurrido un error al actualizar los precios',
        iconColor: '#dc3545',
        showConfirmButton: false,
        timer: 2000,
      })
    }
  }

  return (
    <>
      <AdminAlert />
      <Container className="container-fluid">
        {isModalAddNew && (
          <ModalAdminAgregarNuevoModelo closeModal={closeModalAddNew} />
        )}
        {isModalActualizarPreciosOpen && (
          <ModalActualizarPrecios
            closeModal={closeActualizarPrecios}
            handleUpdateStatus={handleSuccessPricesUpdate}
          />
        )}
        {isModalAgregarOpen && (
          <ModalAdminAgregarUsado closeModal={closeModalAgregar} />
        )}
        <AdminNavbarHome />
        <div className="container">
          <MainContainer>
            {activeMenu === "nuevos" && (
              <div className="menu-nuevos">
                <div className="row">
                  <Titles>
                    <div className="offset-3">
                      <div className="col-6 text">
                        <h2>Gestión de productos: NUEVOS</h2>
                        <p className="subtitle">
                          Agregá, editá o eliminá por modelo-versión
                        </p>
                      </div>
                    </div>
                  </Titles>
                </div>

                <div className="row">
                  <SearchOptions>
                    <div className="offset-3 col-5">
                      <input
                        className="searchInput "
                        type="text"
                        value={searchValueNuevos}
                        id="searchInput"
                        placeholder="Buscá por marca, modelo, etc..."
                        onChange={(e) => handleSearchNuevos(e.target.value)}
                      ></input>
                    </div>
                    <div className="col-2">
                      <button
                        className="new-btn primary-btn"
                        onClick={openModalAddNew}
                      >
                        Nuevo modelo
                      </button>
                    </div>
                  </SearchOptions>
                </div>
              </div>
            )}
            {activeMenu === "usados" && (
              <div className="menu-usados">
                <div className="row">
                  <Titles>
                    <div className="offset-3">
                      <div className=" col-6 text">
                        <h2>Gestión de productos: USADOS</h2>
                        <p className="subtitle">
                          Agregá, editá o eliminá por unidad
                        </p>
                      </div>
                    </div>
                  </Titles>
                </div>
                <div className="row">
                  <SearchOptions>
                    <div className="offset-3 col-3">
                      <input
                        className="searchInput"
                        type="text"
                        id="searchInput"
                        placeholder="Buscá por marca, modelo..."
                        onChange={(e) => handleSearchUsados(e.target.value)}
                      ></input>
                    </div>
                    <div className="col-2">
                      <button
                        className="primary-btn-reverse actualizar-btn"
                        onClick={openActualizarPrecios}
                      >
                        Actualizar Precios
                      </button>
                    </div>
                    <div className="col-2">
                      <button
                        className="primary-btn add-btn"
                        onClick={openModalAgregar}
                      >
                        Nuevo Producto
                      </button>
                    </div>
                  </SearchOptions>
                </div>
              </div>
            )}

            <div className="row">
              <div className="offset-1 col-2">
                <Aside>
                  {activeSection === "usados" ? (
                    <ActiveButton
                      onClick={() => {
                        handleSectionChange("usados");
                        handleMenuChange("usados");
                      }}
                    >
                      Administrar Usados
                    </ActiveButton>
                  ) : (
                    <InactiveButton
                      onClick={() => {
                        handleSectionChange("usados");
                        handleMenuChange("usados");
                      }}
                    >
                      Administrar Usados
                    </InactiveButton>
                  )}
                  {activeSection === "nuevos" ? (
                    <ActiveButton
                      onClick={() => {
                        handleSectionChange("nuevos");
                        handleMenuChange("nuevos");
                      }}
                    >
                      Administrar Nuevos
                    </ActiveButton>
                  ) : (
                    <InactiveButton
                      onClick={() => {
                        handleSectionChange("nuevos");
                        handleMenuChange("nuevos");
                      }}
                    >
                      Administrar Nuevos
                    </InactiveButton>
                  )}
                </Aside>
              </div>
              <div className="catalogo-row col-7">
                {activeSection === "nuevos" ? (
                  <AdminProductsNuevos
                    isModalDeleteOpen={isModalDeleteOpen}
                    openModalDelete={openModalDelete}
                    closeModalDelete={closeModalDelete}
                    isModalViewOpen={isModalViewOpen}
                    closeModalView={closeModalView}
                    openModalView={openModalView}
                    searchValueNuevos={searchValueNuevos}
                  />
                ) : (
                  <AdminProductsUsados
                    isModalDeleteOpen={isModalDeleteOpen}
                    openModalDelete={openModalDelete}
                    closeModalDelete={closeModalDelete}
                    isModalViewOpen={isModalViewOpen}
                    closeModalView={closeModalView}
                    openModalView={openModalView}
                    searchValueUsados={searchValueUsados}
                  />
                )}
              </div>
            </div>
          </MainContainer>
        </div>
        <div style={{ height: "32px" }}></div>
      </Container>
    </>
  );
};

export default AdminHome;
