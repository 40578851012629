import React, { useState } from "react";
import styled from "styled-components";
import Navbar from "../componentes/AdminNavbar";
import { useNavigate } from "react-router-dom";
import ENDPOINTS from "../apiEndpoints";
import axiosInstance from "../utils/ContextAxios";
import { useAuth } from "../utils/AuthContext";
import AdminAlert from "../componentes/AdminResponsiveError";

const StyledLoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;
  width: 100%;

  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  }

  .item-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
  }

  input {
    border: 1px solid black;
    border-radius: 8px;
    height: 42px;
    padding: 8px;
  }

  button {
    width: 100%;
    height: 42px;
    font-weight: 700;
    font-size: 16px;
    margin-top: 25px;
    border: none;
    background-color: var(--prime-color);
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: darkblue;
    }
  }

  .btn-recover-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 15px;
  }

  .btn-recover {
    text-decoration: underline;
    font-weight: 400;
    font-size: 14px;
    width: auto;
    background-color: transparent;
    line-height: 20px;
    color: var(--prime-color);
    &:hover {
      background-color: transparent;
      outline: none;
    }
  }
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
`;

const Container = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const LoginForm = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const { login } = useAuth();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axiosInstance
        .post(ENDPOINTS.LOGIN, {
          username,
          password,
        })
        .then((res) => {
          console.log(res);
          login(res.data.token);
          navigate("/admin/home");
        })
        .catch((err) => setLoginError("Usuario o contraseña inválidos"));
      /* if (response.status === 200) {
        console.log("entre")
        const token = response.data.token;
        console.log(token);
        localStorage.setItem("token", token);
       
      } */
    } catch (error) {
      setLoginError("Usuario o contraseña inválidos");
    }
  };

  // const handleRecoverPass = async () => {
  //   try {
  //     const response = await axiosInstance.post(
  //       "http://localhost:5000/api/recover",
  //       {
  //         username,
  //       }
  //     );
  //     if (response.status === 200) {
  //       setRecoverMessage(
  //         "Correo de recuperación enviado. Por favor, revise su bandeja de entrada."
  //       );
  //     }
  //   } catch (error) {
  //     setRecoverMessage("Error enviando el correo de recuperación.");
  //   }
  //   alert(recoverMessage);
  // };

  return (
    <>
      <AdminAlert />
      <Container className="container-fluid">
        <Navbar />
        <div className="container">
          <StyledLoginForm>
            <div className="row">
              <form className="login-form" onSubmit={handleLogin}>
                <div className="item-form username col-4">
                  <label htmlFor="username">Usuario</label>
                  <input
                    type="text"
                    id="username"
                    autoComplete="off"
                    maxLength={20}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <div className="error-message">
                    {/* {loginError && loginError.includes("Usuario") && (
                    <p>{loginError}</p>
                  )} */}
                  </div>
                </div>
                <div className="item-form password col-4">
                  <label htmlFor="password">Contraseña</label>
                  <input
                    type="password"
                    id="password"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="error-message">
                    {loginError && (
                      <div className="error-message">{loginError}</div>
                    )}
                  </div>
                </div>
                <div className="col-2">
                  <button type="submit" className="primary-btn">
                    Ingresar
                  </button>
                </div>
              </form>
              <div className="btn-recover-container">
                <button className="btn-recover" onClick={{}}>
                  Recuperar contraseña
                </button>
              </div>
            </div>
          </StyledLoginForm>
        </div>
      </Container>
    </>
  );
};

export default LoginForm;
