import React from 'react'
import styled from 'styled-components';
import IconDest from "../images/icon_destacado.png";
import IconDestBLue from "../images/icon_star_blue.png";
import IconDelete from "../images/icon_delete.png";
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 57px;
  align-items: center;
  justify-content: space-around;
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 4px;
  margin: 4px;

  .img-car {
    width: 57px;
    height: 100%;
  }
  .icons {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }
    button {
      border: none;
      background: transparent;
    }
  }
  .file-name {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 180px;
    height: 50px;
  }
  .color-btn {
    width: 100%;
    border: none;
    border-radius: 6px;
    background-color: #eaedf4;
    height: 22px;
    outline: none;
  }
`;

const Thumbnail = (props) => {
    const {
        videoUrl
    } = props;

    const getVideoId = (url) => {
        const urlObj = new URL(url);
        return urlObj.searchParams.get('v');
    }

    const videoId = getVideoId(videoUrl);
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

    return (
        <div>
            <img src={thumbnailUrl} alt='YouTube Video thumbnail' style={{ width: "57px", height: "49px" }} />
        </div>
    )
}

const VideoRow = (props) => {
    const {
        file,
        deleteImage,
    } = props

    const handleDeleteImg = () => {
        deleteImage(file.name);
    };
    return (
        <Container>
            <Thumbnail videoUrl={file.url} />
            <div className="file-name">
                {file.url}
            </div>
            <div className="icons">
                <button className="delete-img" type="button" onClick={handleDeleteImg}>
                    <img src={IconDelete} alt="Eliminar" />
                </button>
            </div>
        </Container>
    )
}

export default VideoRow