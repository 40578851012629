import React, { useEffect, useState } from "react";
import banner from "../images/HeroBannerFinanciacion.png";
import banner2 from "../images/FinanciacionBannerMobile.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Banner = styled.div`
  display: flex;
  position: relative;
  margin-top: 32px;
  margin-bottom: 40px;
  width: 100%;
  height: 388px;
  background-image: url(${banner});
  background-size: cover;
  background-position: center; /* Centra la imagen */
  align-items: end;

  button {
    outline: none;
  }
  .btn-container {
    display: flex;
    width: 100%;
    margin-bottom: 80px;
  }
  @media (max-width: 576px) {
    width: 100%;
    height: 178px;

    .btn-container {
      margin-bottom: 20px;
    }
  }
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Buttons = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  font-family: var(--font-family);
  text-align: center;
  justify-content: center;
  @media (max-width: 576px) {
    /* width: 100px; */
    height: 32px;
  }
`;

const FinanciacionHeroBanner = () => {
  const [isMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();

  const handleLargeButtonClick = () => {
    navigate("/usados");
  };

  const handleSmallButtonClick = () => {
    navigate("/nuevos");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Banner className={isMobile ? "offset-1 col-10" : ""}>
      <BannerContent>
        <div className="row">
          <div className="btn-container">
            <div className="offset-1 col-5 offset-md-4 col-md-2 ">
              <Buttons
                onClick={handleLargeButtonClick}
                className="primary-btn-big "
              >
                USADOS
              </Buttons>
            </div>
            <div className="col-md-2 col-5">
              <Buttons
                onClick={handleSmallButtonClick}
                className="primary-btn-reverse-big "
              >
                NUEVOS
              </Buttons>
            </div>
          </div>
        </div>
      </BannerContent>
    </Banner>
  );
};

export default FinanciacionHeroBanner;
