import React, { useState, useEffect } from "react";
import styled from "styled-components";
import flecha2 from "../images/SliderRow2.png";
import { BASE_URL } from "../utils/ContextAxios";
import ReactPlayer from "react-player";

const MainContainer = styled.div`
  display: flex;
  margin-top: 32px;
  align-items: center;
  min-height: 280px;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  @media (max-width: 576px) {
    display: none;
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  transition: transform 0.3s ease-in-out;
`;

const Slide = styled.div`
  flex: 0 0 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FlechaIzquierda = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(180deg);
  cursor: pointer;
  z-index: 1;
`;

const FlechaDerecha = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
`;

export const SliderCar = ({ producto, productoImg, relatedColor }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    // Lógica para encontrar la imagen favorita
    const favoriteImage = productoImg.find((img) => img.favorite);
    const defaultImageList = favoriteImage
      ? [favoriteImage, ...productoImg.filter((img) => img !== favoriteImage)]
      : productoImg;

    setFilteredImages(defaultImageList);

    if (relatedColor) {
      const colorIndex = defaultImageList.findIndex(
        (img) => img.color === relatedColor._id
      );
      if (colorIndex !== -1) {
        setCurrentIndex(colorIndex);
      }
    }
  }, [relatedColor, productoImg, producto]);

  const handlePrevSlide = () => {
    const prevIndex =
      (currentIndex - 1 + filteredImages.length) % filteredImages.length;
    setCurrentIndex(prevIndex);
  };

  const handleNextSlide = () => {
    const nextIndex = (currentIndex + 1) % filteredImages.length;
    setCurrentIndex(nextIndex);
  };

  return (
    <MainContainer>
      <FlechaIzquierda
        src={flecha2}
        alt="Flecha izquierda"
        onClick={handlePrevSlide}
      />
      <SliderWrapper
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {filteredImages.map((item, index) => (
          <Slide key={index}>
            {item.filetype === 'image' ?
              <SlideImage
                src={`${BASE_URL}/${item.path}`}
                alt={`Slide img ${index}`}
              />
              :<div>
                <ReactPlayer
                  url={item.path}
                />
            </div>
            }
          </Slide>
        ))}
      </SliderWrapper>
      <FlechaDerecha
        src={flecha2}
        alt="Flecha derecha"
        onClick={handleNextSlide}
      />
    </MainContainer>
  );
};

export default SliderCar;
