import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BASE_URL } from "../utils/ContextAxios";

const StyledCard = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  cursor: pointer;

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: white;
    height: 271px;
    margin-bottom: 30px;
    text-decoration: none;
    border: 0px solid transparent;
    width: 100%;
    overflow: hidden;
    border-radius: 0px;
  }
  .card:hover {
    box-shadow: ${(props) =>
      props.$comparadorAbierto ? "0" : "1px 2px 8px 0px rgba(0, 0, 0, 0.15)"};
    border: 0px solid transparent;
    border-radius: 0px 0px 6px 6px;
  }

  .img-box {
    padding: 0;
    width: ${(props) => (props.$comparadorAbierto ? "100%" : "100%")};
    height: 55%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .card h2 {
    /* text-align: center; */
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #080c0d;
    margin: 0;
  }

  .card h5 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    color: #080c0d;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    margin-top: 16px;
  }
  .card p {
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    color: var(--gris-medio);
    margin-top: 14px;
  }

  @media (max-width: 576px) {
    margin-bottom: 30px;
    border-radius: 0px 0px 6px 6px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
    border: 3px solid transparent;
    &:hover {
      border: 3px solid transparent;
      border-radius: 6px;
      .img-box {
        width: 100%;
      }
    }

    span {
      display: none;
    }
    .card h2 {
      text-align: center;
      font-size: 15px;
      font-weight: 700;
      line-height: 15px;
      color: #080c0d;
    }

    .card h5 {
      margin-top: 0.25rem;
      font-size: 14px;
      font-weight: 400;
      color: #080c0d;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* Limita a 1 línea */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    .card p {
      text-align: center;
      padding: 0px 15px;
      font-size: 13px;
      font-weight: 400;
      color: #9e9e9e;
      font-family: var(--roboto);
      margin-bottom: 8px; /* Ajusta el margen inferior del párrafo */
    }
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 188px;
      text-decoration: none;
      margin-bottom: 5px;
      border: none;
    }
    .card:hover {
      border: none;
      box-shadow: ${(props) =>
        props.$comparadorAbierto ? "0" : "2px 4px 4px rgba(0, 0, 0, 0.2)"};
    }
    .img-box {
      img {
        width: 100%;
        height: 95px;
      }
    }
  }
  //active 100%
`;

const Card = ({ producto, comparadorAbierto, originalId }) => {
  const handlePassId = () =>
    localStorage.setItem("ProductoId", JSON.stringify(originalId));
  // console.log("Id original", originalId);

  return (
    <StyledCard
      $comparadorAbierto={comparadorAbierto}
      onClick={handlePassId}
      key={producto._id}
    >
      {comparadorAbierto ? (
        <div className="card">
          <div className="img-box">
            {producto.multimedia && producto.multimedia.length > 0 ? (
              producto.multimedia.some((media) => media.favorite) ? (
                <img
                  src={`${BASE_URL}/${
                    producto.multimedia.find((media) => media.favorite).path
                  }`}
                  alt="Imagen del producto favorita"
                />
              ) : (
                <img
                  src={`${BASE_URL}/${producto.multimedia[0].path}`}
                  alt="Imagen del producto"
                />
              )
            ) : (
              <img
                src={`./images/image-default.jpg`}
                alt="Imagen del producto"
              />
            )}
          </div>
          <div className="info">
            <h2>
              {producto.brand.name} {producto.modelo.name}
            </h2>
            <h5>{producto.version.name}</h5>
            <p>
              {producto.year} | {producto.mileage}km <span>|</span>
              {producto.motor}
              {producto.transmission}
            </p>
          </div>
        </div>
      ) : (
        <Link to={`/usados/${originalId}`} className="card">
          <div className="img-box">
            {producto.multimedia && producto.multimedia.length > 0 ? (
              producto.multimedia.some((media) => media.favorite) ? (
                <img
                  src={`${BASE_URL}/${
                    producto.multimedia.find((media) => media.favorite).path
                  }`}
                  alt="Imagen del producto favorita"
                />
              ) : (
                <img
                  src={`${BASE_URL}/${producto.multimedia[0].path}`}
                  alt="Imagen del producto"
                />
              )
            ) : (
              <img
                src={`./images/image-default.jpg`}
                alt="Imagen del producto"
              />
            )}
          </div>
          <div className="info">
            <h2>
              {producto.brand.name} {producto.modelo.name}
            </h2>
            <h5>{producto.version.name}</h5>
            <p>
              {producto.year} | {producto.mileage}km <span>| </span>
              {producto.motor}
              {producto.transmission}
            </p>
          </div>
        </Link>
      )}
    </StyledCard>
  );
};

export default Card;
