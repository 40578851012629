import React from "react";
import "../styles.css";
import NosotrosBanner from "../images/NosotrosBanner.jpg";
import styled from "styled-components";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import Fichero from "../componentes/NosotrosFichero";
import NosotrosMapLocation from "../componentes/NosotrosMapLocation";
import FooterMobile from "../componentes/FooterMobile";
import FicheroMobile from "../componentes/NosotrosFicheroMobile";
import WhatsAppBtn from "../componentes/WhatsAppBtn";

const MarginNav = styled.div`
  display: block;
  background-color: black;
  padding-top: 114px;
  @media (max-width: 576px) {
    padding-top: 48px;
  }
`;

const ContainerStyles = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  .fichero-mobile {
    display: none;
  }
  @media (max-width: 576px) {
    width: 100%;
    .fichero-desktop {
      display: none;
    }
    .fichero-mobile {
      display: block;
    }
  }
`; // así le quito el espacio blanco de los bordes

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #eaedf4;
  padding-bottom: 48px;

  @media (max-width: 576px) {
    height: auto;
    padding-bottom: 24px;
  }
`;

const StyleBanner = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  align-items: center;
  background-image: url(${NosotrosBanner});
  background-size: cover;
  img {
  }
  @media (max-width: 576px) {
    /* margin-top: 48px; */
    max-height: 166px;
  }
`;

const StyledInfo = styled.div`
  .nosotros-info {
    width: 100%;
    margin-top: 48px;
  }

  p {
    margin-bottom: 32px;
  }

  margin-left: 0px;

  @media (max-width: 576px) {
    justify-content: center;
    text-align: center;
    margin-top: 0px;
    .title {
      font-size: 18px;
      line-height: 20px;
    }

    p {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 24px;
      text-align: center;
    }

    .nosotros-info {
      margin-top: 36px;
    }
  }
`;

const Title = styled.h3`
  margin-bottom: 16px;
  padding: 0;
  font-size: 38px;
  line-height: 40.5px;
`;

const Nosotros = () => {
  return (
    <ContainerStyles className="container-fluid">
      <Navbar />
      <MarginNav />
      <StyleBanner />
      <div className="fichero-mobile">
        <StyledContainer>
          <StyledInfo>
            <div className="nosotros-info offset-1 col-10">
              <div className="">
                <Title className="b-text title">
                  ¡Bienvenidos a Nave.com.ar!
                </Title>
              </div>
              <p className="r-text tx-medium">
                Te ofrecemos una variada selección de vehículos usados y 0KM de
                marcas líderes. Destacamos por nuestra transparencia, calidad y
                atención al cliente.
              </p>
            </div>
            <FicheroMobile />
          </StyledInfo>
        </StyledContainer>
      </div>

      <StyledContainer className="fichero-desktop">
        <StyledInfo>
          <div className="container">
            <div className="row">
              <div className="nosotros-info">
                <div className="col-md-6">
                  <Title className="b-text title">
                    ¡Bienvenidos a Nave.com.ar!
                  </Title>
                </div>
                <p className="r-text tx-medium col-md-6">
                  Te ofrecemos una variada selección de vehículos usados y 0KM
                  de marcas líderes. Destacamos por nuestra transparencia,
                  calidad y atención al cliente.
                </p>
              </div>
            </div>
            <Fichero />
          </div>
        </StyledInfo>
      </StyledContainer>

      <div className="container">
        <NosotrosMapLocation />
      </div>
      <WhatsAppBtn />
      <Footer />
      <FooterMobile />
    </ContainerStyles>
  );
};

export default Nosotros;
