import React, { useEffect, useState } from "react";
import AdminNuevosRow from "../componentes/AdminNuevosRow";
import "../styles.css";
import axiosInstance from "../utils/ContextAxios";
import Spinner from "../componentes/Loader";
import ModalAdminVistaPreviaProduct from "../componentes/ModalAdminVistaPreviaProduct";
import ENDPOINTS from "../apiEndpoints";

const AdminProductsNuevos = ({
  isModalDeleteOpen,
  openModalDelete,
  isModalViewOpen,
  openModalView,
  closeModalView,
  searchValueNuevos,
  closeModalDelete,
}) => {
  const [selectedAuto, setSelectedAuto] = useState(null);

  //Estado del catalogo
  const [startIndex, setStartIndex] = useState(0);
  const cardsPerPage = 200;

  //producto
  const [autosLista, setAutosLista] = useState([]);
  const [update, setUpdate] = useState(true);
  /*------api-------*/

  useEffect( () => {
    if(update){
      axiosInstance.get(ENDPOINTS.GET_NEW_CARS)
        .then((res) => {
          console.log(res.data);
          setAutosLista(res.data.data);
        }).catch((err) => {
          console.log(err);
        }).finally(() => setUpdate(false));
    }
  }, [update]);

  if (!autosLista) {
    return <Spinner />; // Muestra un mensaje mientras se carga la data
  }

  const filteredProductsNuevos = autosLista.filter(
    (producto) =>
      producto.modelo.name
        .toLowerCase()
        .includes(searchValueNuevos.toLowerCase()) ||
      producto.brand.name
        .toLowerCase()
        .includes(searchValueNuevos.toLowerCase()) ||
      producto.version.name
        .toLowerCase()
        .includes(searchValueNuevos.toLowerCase())
  );

  const visibleCards = filteredProductsNuevos.slice(
    startIndex,
    startIndex + cardsPerPage
  );

  return (
    <div className="">
      {isModalViewOpen && (
        <ModalAdminVistaPreviaProduct
          closeModalView={closeModalView}
          producto={selectedAuto}
        />
      )}
      {visibleCards.map((producto) => (
        <AdminNuevosRow
          key={producto._id}
          productoData={producto}
          openModalDelete={openModalDelete}
          isModalDeleteOpen={isModalDeleteOpen}
          isModalViewOpen={isModalViewOpen}
          closeModalDelete={closeModalDelete}
          openModalView={() => {
            setSelectedAuto(producto);
            openModalView();
          }}
          closeModalView={closeModalView}
          updateList = {setUpdate}
        />
      ))}
    </div>
  );
};

export default AdminProductsNuevos;
