import React, { useState } from "react";
import styled from "styled-components";
import ImgTestimonio1 from "../images/Testimonio1.jpg";
import ImgTestimonio2 from "../images/Testimonio2.jpg";
import ImgTestimonio3 from "../images/Testimonio3.jpg";
import RightArrow from "../images/SliderRow1.png";

const testimonies = [
  {
    id: 1,
    name: "Norma Perez",
    text: "“Siempre muy buena predisposicion, buen trato, y los trámites muy rápidos.”",
    image: ImgTestimonio1,
  },
  {
    id: 2,
    name: "Julia Molina",
    text: "“¡El auto muy bien! Cumplió mis expectativas y por suerte ningun inconveniente, como siempre el vendedor 10 puntos, al igual que el sector administrativo.”",
    image: ImgTestimonio2,
  },
  {
    id: 3,
    name: "Omar Oro",
    text: "“Excelente experiencia, sigan así que hacen muy bien su trabajo.”",
    image: ImgTestimonio3,
  },
  // Agrega más objetos de testimonios según sea necesario
];

const Container = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  height: 200px;
  justify-content: center;
  font-family: var(--font-family);
  border-top: 1px solid black;
  button {
    outline: none;
  }
  @media (max-width: 576px) {
    display: flex;
  }
`;

const StyledCarousel = styled.div`
  overflow: hidden;
  padding: 20px 0;
`;

const TestimonyImage = styled.img`
  height: 106px;
  width: 100%;
  border-radius: 5%;
  object-fit: cover;
`;

const TestimonyText = styled.p`
  font-family: var(--roboto);
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  text-align: left;
  width: 100%;
`;

const TestimonyName = styled.p`
  font-weight: bold;
  margin-top: 10px;
  font-family: var(--font-family);
  margin-top: 5px;
`;

const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${(props) =>
    props.$active === "true" ? "black" : "white"};
  border-radius: 50%;
  border: 1px solid black;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
`;

const HomeTestimonyMobile = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Container className="container">
      <StyledCarousel>
        <div className="row">
          <div className="offset-1 col-4">
            <TestimonyImage
              src={testimonies[currentSlide].image}
              alt={testimonies[currentSlide].name}
            />
          </div>
          <div className="col-6">
            <TestimonyText>{testimonies[currentSlide].text}</TestimonyText>
            <TestimonyName>{testimonies[currentSlide].name}</TestimonyName>
          </div>
        </div>
      </StyledCarousel>

      <div style={{ textAlign: "center" }}>
        {testimonies.map((_, index) => (
          <Dot
            key={index}
            $active={index === currentSlide ? "true" : "false"}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </Container>
  );
};

export default HomeTestimonyMobile;
