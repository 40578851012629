import React, { useState } from "react";
import { saveAs } from "file-saver";
import styled from "styled-components";
import PorcenImg from "../images/filtro_porcentaje.png";
import "../styles.css";
import axios from "axios";
import axiosInstance, { useAxiosWithAuth } from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const ModalActualizar = styled.div`
  position: fixed;
  z-index: 1001;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  width: 556px;
  height: 307px;
  font-size: 18px;
  font-family: var(--font-family);
  line-height: 22px;
  /* border-radius: 6px; */
  background-color: white;

  button {
    outline: none;
  }
  .prime-text {
    color: var(--prime-color);
  }

  .nav-options {
    display: flex;
    width: 100%;
    /* align-items: center; */
    border-bottom: none;
    max-height: 42px;
    height: 100%;
    border-radius: 6px 6px 0px 0px;

    .files-titles {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-around;
      background-color: var(--gris-claro);
    }
    .close-btn {
      border: none;
      outline: none;
      height: 42px;
      right: 0;
      padding-right: 10px;
      background-color: var(--gris-claro);
    }
    .close-btn-container {
      height: 100%;
    }
  }

  .option-title {
    cursor: pointer;
    width: 80px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    padding: 10px 20px; /* Añadir padding para separar las solapas */
    border-radius: 6px 6px 0 0;
    background-color: transparent; /* Fondo transparente */
    transition: background-color 0.3s ease; /* Transición suave */
  }

  .option-title.active {
    background-color: white;
    /* Color de fondo cuando activa */
    border-bottom: 1px solid transparent;
  }
  .porcentaje-item {
    /* border-bottom: 1px solid black; */
    z-index: 2;
  }
  .porcentaje-item.active {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 2px solid white;
  }
  .archivo-item {
    margin-bottom: 1px solid black;
    z-index: 2;
  }
  .archivo-item.active {
    border-right: 1px solid black;
    border-bottom: 2px solid white;
  }
  .content {
    padding: 30px 40px;
    text-align: center;
    height: 500px;
    border-top: 1px solid black;
    border-radius: 6px 6px 0;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: var(--roboto);
      font-size: 14px;
      line-height: 16px;
      margin: 0;
      padding-bottom: 8px;
    }
    label {
      width: 201px;
      justify-content: center;
      align-items: center;
      margin-right: 22px;
      padding-top: 9px;
      text-align: center;
    }

    .confirm-btn {
      height: 42px;
      width: 87px;
      margin-left: 12px;
      margin-right: 4px;
      border: none;
    }
  }
  .text-box {
    padding: 8px 12px;
    span {
      font-weight: 700;
    }
  }
  .download-list {
    margin-top: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px;
    text-decoration: underline;
  }

  .filter {
    align-items: center;
    padding: 0;
    margin: 0;
    height: 42px;

    img {
      height: 42px;
    }
  }
  .filter-input {
    height: 100%;
    background-color: var(--gris-claro);
    border: none;
    outline: none;

    padding-left: 6px;
  }
`;

const ModalActualizarPrecios = ({ closeModal, handleUpdateStatus }) => {
  const [activeTab, setActiveTab] = useState("archivo");
  const [porcentaje, setPorcentaje] = useState(""); // Estado para almacenar el valor del porcentaje
  const [porcentajeValido, setPorcentajeValido] = useState(true); // Estado para validar el porcentaje
  const [file, setFile] = useState(null); //
  const [isLoading, setIsLoading] = useState(false)
  const [ error, setError] = useState(null)
  const axiosInstanceWithAuth = useAxiosWithAuth();


  const handlePorcentajeChange = (e) => {
    setPorcentaje(e.target.value);
  };
  const handleDownload = async () => {
    try {
      const response = await axios.get("/download-excel", {
        responseType: "blob",
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1].split(";")[0]
        : "archivo_descargado";
      saveAs(response.data, fileName);
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
      alert("No hay archivo disponible");
    }
  };

  const handleConfirmPorcentaje = async () => {
        
    const percentage = parseInt(porcentaje)
    if(isNaN(percentage) ) {
      console.log('No es un numero')
      setError('El dato ingresado no es un número')
      return;
    }
    setError(null);
    setIsLoading(true);
    await axiosInstanceWithAuth.post(
      ENDPOINTS.UPDATE_ALL_PRICES,
      {
        percentage: porcentaje
      }
    ).then((res)=> {
      console.log(res)
      handleUpdateStatus({status:'success'})
    })
    .catch((err)=> {
      console.log(err)
      handleUpdateStatus({status:'error'})
    })
    .finally(() => setIsLoading(false))
  };

  const handleAddFile = (e) => {
    const file = Array.from(e.target.files);
    setFile(file)
  }

  const handleConfirmUpdate = async () => {
    setIsLoading(true)

    const formData = new FormData()
    formData.append('file', file[0])

    await axiosInstanceWithAuth.post(
      ENDPOINTS.UPDATE_PRICES, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    ).then((res) => {
      console.log(res)
      const {updateCount} = res;
      if(updateCount && updateCount > 0) {
        handleUpdateStatus({status:'success', updateCount})
      }else{
        handleUpdateStatus({status:'success'})
      }
      setFile(null)
    }).catch((err) => handleUpdateStatus({status:'error'}))
    .finally(() => setIsLoading(false))
  }

  return (
    <>
      <Overlay onClick={closeModal} />
      <ModalActualizar>
        <div className="nav-options">
          <div className="files-titles">
            <div
              className={`option-title archivo-item ${
                activeTab === "archivo" ? "active" : ""
              }`}
              onClick={() => setActiveTab("archivo")}
            >
              Con archivo
            </div>
            <div
              className={`option-title porcentaje-item ${
                activeTab === "porcentaje" ? "active" : ""
              }`}
              onClick={() => setActiveTab("porcentaje")}
            >
              Por porcentaje
            </div>
          </div>
          <div className="close-btn-container">
            <button className="close-btn" onClick={closeModal}>
              X
            </button>
          </div>
        </div>
        <div className="content">
          {activeTab === "archivo" && (
            <div className="archivo-box">
              <div className="text-box">
                <p>Subí el archivo Excel con los nuevos precios.</p>
                <p className="prime-text">
                  Advertencia: esta acción es irreversible y afectará a todos
                  los productos <span>usados</span>.
                </p>
              </div>
              <div className="btn-container">
                {!file ?
                <>
                <label htmlFor="add-file" className="primary-btn-big">
                  Seleccionar archivo
                  <input
                    type="file"
                    className="primary-btn-big"
                    multiple
                    id="add-file"
                    style={{ display: "none" }}
                    accept=".xls, .xlsx, .csv"
                    onChange={handleAddFile}
                  />
                </label>
                <p>nombre del archivo</p>
                </>
                : 
                  <div className="d-flex align-items-center mb-2">
                    <button disabled={isLoading} className="primary-btn-big px-4 mr-4" onClick={handleConfirmUpdate}>
                      {isLoading ? "Cargando..." : "Confirmar"}
                    </button>
                    <p className="p-0">{file[0].name}</p>
                    <button onClick={() => setFile(null)} className="btn m-0 d-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                      </svg></button>
                  </div>
                }
              </div>
              <div className="listado-container">
                <button className="download-list" onClick={handleDownload}>
                  Descargar listado vigente
                </button>
              </div>
            </div>
          )}
          {activeTab === "porcentaje" && (
            <div className="porcentaje-box">
              <div className="text-box">
                <p>
                  Indicá el porcentaje de ajuste para actualizar los precios por
                  incremento o decremento.
                </p>
                <p className="prime-text">
                  Advertencia: este valor se aplicará a todos los
                  <span> usados</span> de manera irreversible.
                </p>
              </div>
              <div className="btn-container">
                <div className="filter d-flex align-items-center">
                  <img src={PorcenImg} alt="%" />

                  <input
                    type="number"
                    id="filter"
                    className={`filter-input ${
                      porcentajeValido ? "" : "invalid"
                    }`}
                    placeholder="Ingrese un valor"
                    value={porcentaje}
                    onChange={handlePorcentajeChange}
                    min="0"
                  />
                </div>
                <div>
                  <button
                    className="primary-btn confirm-btn"
                    onClick={handleConfirmPorcentaje}
                  >
                    Ok
                  </button>
                </div>
                
              </div>
              <div >
                {error && <small className="text-danger fs-1">{error}</small>}
              </div>
            </div>
          )}
        </div>
      </ModalActualizar>
    </>
  );
};

export default ModalActualizarPrecios;
