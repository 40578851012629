import React from "react";
import Navbar from "../componentes/Navbar";

import HomeSlider from "../componentes/HomeServiceSlider";
import HomeOportunity from "../componentes/HomeOportunity";
import Faq from "../componentes/HeroFAQ";
import Footer from "../componentes/Footer";
import FooterMobile from "../componentes/FooterMobile";
import HomeTestimony from "../componentes/HomeTestimony";
import styled from "styled-components";
import HomeTestimonyMobile from "../componentes/HomeTestomonyMobile";
import HomeHeroBannerPili from "../componentes/HomeHeroBannerPili";
import WhatsAppBtn from "../componentes/WhatsAppBtn";

const Container = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  justify-content: center;
  align-items: center;
  position: relative;
  button {
    outline: none;
  }
`;

const MarginNav = styled.div`
  display: block;
  background-color: black;
  padding-top: 114px;
  @media (max-width: 576px) {
    padding-top: 48px;
  }
`;

const Home = () => {
  return (
    <Container className="container-fluid ">
      <Navbar />
      <MarginNav />
      <HomeHeroBannerPili />
      <HomeSlider />
      <HomeOportunity />
      <Faq />
      <HomeTestimony />
      <HomeTestimonyMobile />
      <WhatsAppBtn />
      <FooterMobile />
      <Footer />
    </Container>
  );
};

export default Home;
