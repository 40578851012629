import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IconCarBlue from "../images/icon_car_blue.png";
import IconExpanse from "../images/icon_expande.png";
import CarImg from "../images/CarAdminVistaPrevia.png";
import { useNavigate } from "react-router-dom";
import "../styles.css";
import { BASE_URL } from "../utils/ContextAxios";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 752px;
  height: 725px;
  font-family: var(--font-family);
  position: fixed;
  background-color: white;
  justify-content: center;
  z-index: 1001;
  transform: scale(0.9) translate(-50%, -55%);
  border-radius: 16px;
  top: 50%;
  left: 50%;
  padding: 50px 40px;

  .product-title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: var(--prime-color);
    margin: 0px 22px;
    margin-bottom: 20px;

    .logo {
      width: 24px;
      height: 20px;
      margin-top: 3px;
      margin-right: 10px;
    }
    .exp-btn {
      background-color: transparent;
      border: none;
    }
  }
  .product-name {
    display: inline-flex;
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    /* margin-left: 8px; */
    p {
      /* margin-left: -10px; */
    }
  }
  .exp-btn {
    span {
      color: white;
    }
  }
  .exp-btn:hover {
    span {
      color: var(--prime-color);
    }
  }
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
`;

const ButtonRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .img-box {
    height: 200px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .form-box {
    display: flex;
    flex-direction: column;
    max-width: 304px;
    width: 100%;
    margin: 0px 20px;
    margin-bottom: 5px;

    .ficha-tecnica {
      button {
        height: 32px;
        border: none;
        background: none;
        cursor: pointer;
        font-weight: 700; /* Texto en negrita */
        text-decoration: underline; /* Subrayar el texto */
        line-height: 20px;
        font-size: 14px;
      }
    }
    .item-box {
      max-height: 60px;
      height: 100%;
      width: 100%;
      padding: 0;
      margin-bottom: 10px;
    }
    .placeholder {
      background-color: var(--gris-invisible);
      font-style: italic;
      height: 34px;
      width: 100%;
      border-radius: 6px;
      font-size: 16px;
      line-height: 19px;
      padding-left: 8px;
      justify-content: center;
      padding-top: 6px;
    }
    label {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
    }
  }

  .form-accesorios {
    height: 60px;
    width: 100%;
    margin: 0px 20px;
    margin-bottom: 30px;
  }
  .placeholder {
    background-color: var(--gris-invisible);
    font-style: italic;
    height: 34px;
    width: 100%;
    border-radius: 6px;
    padding-top: 8px;
    padding-left: 6px;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    justify-content: center;
  }
  label {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  }
  .color-container {
    .text-box {
      height: 100px;
      background-color: var(--gris-invisible);
      font-style: italic;
      border-radius: 6px;
    }
    p {
      padding-left: 8px;
      padding-top: 10px;
    }
  }
  .btn-container {
    width: 320px;
    display: flex;
    justify-content: space-between;
    margin-right: 12px;

    button {
      height: 32px;
      width: 49%;
      border-radius: 6px;
      margin-left: 8px;
      margin-right: 8px;
    }
    .close-btn {
      border: none;
    }
  }
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pasajeros {
    width: 83px;
  }
  .placeholder-pasajeros {
    width: 83px;
    background-color: var(--gris-invisible);
    font-style: italic;
    height: 32px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 19px;
    padding-left: 8px;
    justify-content: center;
  }
  .material-asientos {
    width: 193px;
    margin-left: 5px;
  }
  .placeholder-asientos {
    width: 193px;
    background-color: var(--gris-invisible);
    font-style: italic;
    height: 32px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 19px;
    padding-left: 8px;
    justify-content: center;
  }
`;

const ModalAdminVistaPreviaProduct = ({ closeModalView, producto }) => {
  const navigate = useNavigate();

  // console.log(producto);

  const handleEditButton = () => {
    const ruta = producto.newCar;

    // Definir las rutas de edición para nuevos y usados
    const rutaNuevos = `/admin/edit0km/${producto._id}`;
    const rutaUsados = `/admin/editusado/${producto._id}`;

    // Determinar la ruta de edición basada en el valor de 'ruta'
    const rutaEdicion = ruta === 1 ? rutaNuevos : rutaUsados;

    // Navegar a la ruta de edición correspondiente
    navigate(rutaEdicion);
    console.log(rutaEdicion);
  };

  const handleFichaTecnicaClick = async () => {
    const url = `${BASE_URL}/${pdf[0].path}`; // URL del archivo PDF
    try {
      const response = await fetch(url); // Realizar la solicitud GET
      const blob = await response.blob(); // Obtener el contenido como Blob

      // Crear un objeto URL para el Blob y generar un enlace de descarga
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = pdf[0].filename; // Nombre del archivo a descargar
      a.click(); // Simular clic en el enlace para iniciar la descarga
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };
  const [img, setImg] = useState(producto.multimedia[0].path)
  const [pdf, setPdf] = useState([])
  useEffect(()=> {
    const imgFavorite = producto.multimedia.filter( elem => elem.favorite)
    console.log(producto)
    if(imgFavorite.length > 0) {
      setImg(imgFavorite[0].path)
    }
    const hasPdf = producto.multimedia.filter( elem => elem.mimetype === 'application/pdf')
    if(hasPdf){
      setPdf(hasPdf)
    }
  },[])

  const handleExpBtn = () => {
    const ruta = producto.newCar;

    // Definir las rutas de edición para nuevos y usados
    const rutaNuevos = `/nuevos/${producto._id}`;
    const rutaUsados = `/usados/${producto._id}`;

    // Determinar la ruta de edición basada en el valor de 'ruta'
    const rutaEdicion = ruta === 1 ? rutaNuevos : rutaUsados;

    localStorage.setItem("ProductoId", JSON.stringify(producto._id));
    console.log("Id original", producto._id);
    window.open(rutaEdicion, "_blank", "noopener");
  };

  return (
    <>
      <Overlay onClick={closeModalView} />
      <ModalContainer>
        <div className="row">
          <div className="product-title">
            <div className="product-name">
              <img src={IconCarBlue} alt="logo" className="logo" />
              <p>
                {producto.brand.name} {producto.modelo.name}{" "}
                {producto.version.name}
              </p>
            </div>
            <div className="btn-exp">
              <button
                className="exp-btn"
                onClick={handleExpBtn}
                target="_blank"
                rel="noopener"
              >
                <span>Ver en la web </span>
                <img src={IconExpanse} alt="exp-img" />
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <Form>
            <FormContainer>
              <div className="form-box">
                <div className="img-box">
                  <img
                    src={`${BASE_URL}/${img}`}
                    alt="car"
                  />
                </div>
              </div>
              <div className="form-box">
                <div className="row">
                  <div className="item-box categoria">
                    <label htmlFor="categoria">Categoría</label>
                    <div className="placeholder">
                      <p>{producto.category.name}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="item-box transmision">
                    <label htmlFor="transmision">Transmisión</label>
                    <div className="placeholder">
                      <p>{producto.transmission}</p>
                    </div>
                  </div>
                </div>
              </div>
            </FormContainer>
            <FormContainer>
              <div className="form-box">
                <div className="color-container">
                  <label htmlFor="color">Color</label>
                  <div className="text-box">
                    <p className="color-list">
                      {producto.newCar === 1
                        ? producto.colors.map((color) => (
                            <span key={color._id}>{color.name} </span>
                          ))
                        : producto.color.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-box">
                <div className="row">
                  <div className="item-box traccion">
                    <label htmlFor="traccion">Tracción</label>
                    <div className="placeholder">
                      <p>{producto.traction}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="item-box combustible">
                    <label htmlFor="combustible">Combustible</label>
                    <div className="placeholder">
                      <p>{producto.fuel}</p>
                    </div>
                  </div>
                </div>
              </div>
            </FormContainer>

            <FormContainer>
              <div className="form-box">
                <RowContainer>
                  <div className="item-box pasajeros">
                    <label htmlFor="pasajeros">Pasajeros</label>
                    <div className="placeholder-pasajeros">
                      <p>
                        {producto.newCar === 1
                          ? producto.passengers
                          : producto.seating}
                      </p>
                    </div>
                  </div>
                  <div className="item-box material-asientos">
                    <label htmlFor="matasientos">Material de Asientos</label>
                    <div className="placeholder-asientos">
                      <p>{producto.seatMaterial}</p>
                    </div>
                  </div>
                </RowContainer>
                <div className="item-box garantia">
                  <label htmlFor="garantia">Garantia</label>
                  <div className="placeholder">
                    <p>{producto.warranty}</p>
                  </div>
                </div>
              </div>
              <div className="form-box">
                <div className="row">
                  <div className="item-box motor">
                    <label htmlFor="motor">Motor</label>
                    <div className="placeholder">
                      <p>{producto.motor}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="item-box potencia">
                    <label htmlFor="potencia">Potencia</label>
                    <div className="placeholder">
                      <p>{producto.cv}</p>
                    </div>
                  </div>
                </div>
              </div>
            </FormContainer>

            <FormContainer>
              <div className="form-accesorios">
                <div className="item-box  accesorios">
                  <label htmlFor="accesorios">Accesorios</label>
                  <div className="placeholder">
                    <p>{producto.accessories}</p>
                  </div>
                </div>
              </div>
            </FormContainer>
            <ButtonRowContainer>
              {/* <div className="form-box">
                <div className="ficha-tecnica">
                  <button onClick={handleFichaTecnicaClick}>
                    Ver ficha técnica
                  </button>
                </div>
              </div> */}

              <div className="btn-container">
                <button
                  className="primary-btn-reverse"
                  onClick={handleEditButton}
                >
                  Editar
                </button>
                <button
                  className="primary-btn close-btn"
                  onClick={closeModalView}
                >
                  Cerrar
                </button>
              </div>
            </ButtonRowContainer>
          </Form>
        </div>
      </ModalContainer>
    </>
  );
};

export default ModalAdminVistaPreviaProduct;
