import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IconWhatsApp from "../images/icon_whatsapp_blue.png";
import IconOne from "../images/icon_1blue.png";
import IconTwo from "../images/icon_2blue.png";
import IconThree from "../images/icon_3blue.png";
import IconFour from "../images/icon_4blue.png";
import IconFive from "../images/icon_5blue.png";
import checkbox from "../images/checkbox.png";
import check from "../images/check.png";
import axiosInstance from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";

const AsideProduct = styled.aside`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0;
  margin-bottom: 45px;
  @media (max-width: 576px) {
    display: none;
  }

  .info-title {
    font-family: var(--font-family);
    text-align: start;
    padding: 0;
    margin: 0;
  }

  .product-name {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    padding: 0;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .slang {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
  }

  @media (max-width: 576px) {
    .info-title {
      display: none;
    }
  }
`;

const InfoForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 16px;

  .title {
    display: flex;
    height: 21px;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 16px;
    line-height: 18px;
    img {
      margin-right: 8px;
    }
  }
  .title-lg {
    display: flex;
    height: 21px;
    margin-bottom: 28px;
    margin-top: 15px;
    font-size: 16px;
    line-height: 18px;
    img {
      margin-right: 8px;
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    /* margin-top: 30px; */

    button {
      width: 100%;
      padding: 10px;
      background-color: var(--gris-claro);
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      color: var(--prime-color);
      line-height: 18px;
      border: 1px solid transparent;
      cursor: pointer;
      border-radius: 6px;
      outline: none;
    }

    button:disabled{
      opacity:0.8;
      color:#9E9E9E;
    }

    button:disabled:hover{
      cursor:not-allowed;
      color:#9E9E9E;
      background-color:#EAEDF4;
      border: 1px solid transparent;
    }

    button:hover {
      background-color: white;
      border: 1px solid var(--prime-color);
    }

    button.isActive {
      color: white;
      background-color: #595e62;
      border-color: transparent;
    }
  }

  .colors-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  /* Estilo para ocultar el input */
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* Estilo para el contenedor del checkbox personalizado */
  .polarizado-option {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  /* Estilo para la imagen del checkbox */
  .polarizado-option label img {
    max-width: 19px;
    min-width: 19px;
    min-height: 19px;
    max-height: 19px;

    margin-right: 8px;
    cursor: pointer;
    margin-bottom: 4px;
  }

  .polarizado-option label {
    cursor: pointer;
    font-size: 16px;
    line-height: 18px;
  }

  .form-btns {
    margin-top: 20px;
  }
`;

const StyledColorOption = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    border: 4px solid #1a3eea;
    height: 45px;
    width: 45px;
  }
  &.active {
    border: 4px solid #1a3eea;
    height: 45px;
    width: 45px;
  }
`;
const StyledBtnAsesor = styled.button`
  display: flex;
  color: var(--prime-color);
  border-radius: 6px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 15px;
  background-color: transparent;
  border: 1px solid var(--prime-color);

  &:hover {
    background-color: #595e62;
    color: white;
    border: 1px solid transparent;
    img {
      filter: brightness(0) invert(1);
    }
  }
  img {
    margin-right: 5px;
  }
`;
const StyledBtn = styled.button`
  display: flex;
  background-color: var(--prime-color);
  color: white;
  border-radius: 6px;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid transparent;
  line-height: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 15px;

  &:hover {
    background-color: #595e62;
    color: white;
    border: 1px solid transparent;
  }
`;
const AsideProduct0km = ({
  handleCotizadorClick,
  handleMeInteresaClick,
  producto,
  formData,
  versionId,
  versionsBack,
  transmissionId,
  setRelatedColor,
}) => {
  //almacena los datos que se envian por whatsapp
  const [formulario, setFormulario] = useState({
    transmission: "",
    version: "",
    colors: "",
    plotted: false,
    cotization: "",
    financiation: "",
  });
  const [activeButton, setActiveButton] = useState(null);
  const [selectedColor, setSelectedColor] = useState("");
  const [transmission, setTransmision] = useState("");
  const [allTransmissions, setAllTransmissions] = useState(producto.transmission);
  const [version, setVersion] = useState("");
  const [versions, setVersions] = useState([]);
  const [plotted, setPlotted] = useState(false);
  const [cotizacion, setCotizacion] = useState("");
  const [financiacion, setFinanciacion] = useState("");
  
  useEffect(() => {
    axiosInstance.get(ENDPOINTS.GET_TRANSMISSIONS_BY_MODELO(producto.modelo._id))
    .then((res) => {
      const { data } = res.data
      setAllTransmissions(data.transmission)
    });
    axiosInstance.get(ENDPOINTS.GET_VERSIONS_BY_MODELID(producto.modelo._id))
    .then((res) => {
      const { data } = res
      data.map(version => {
        return {...version, isActive:false}
      })
      setVersions(data);
    }).catch((err) => console.error(err));
  }, [])
  /************************************************* */

  /**************************** */
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName === activeButton ? null : buttonName);
  };
  const handleTransmissionChange = (newTransmision) => {
    setFormulario({ ...formulario, transmision: newTransmision });
    setTransmision(newTransmision);
    setVersion("");
    versions.map( item => {
      if(item.transmissions.includes(newTransmision))
      {
        item.isActive = true;
      }
      else{
        item.isActive = false;
      }
      return item;
    })
  };

  const handleVersionChange = (newVersion) => {
    setFormulario({ ...formulario, version: newVersion.name });
    setVersion(newVersion.name);
    versionId(newVersion._id);
  };

  const handleColorClick = (color) => {
    if (selectedColor === color) {
      setFormulario({ ...formulario, selectedColor: "" });
      setSelectedColor("");
      setRelatedColor("");
    } else {
      setFormulario({ ...formulario, selectedColor: color });
      setSelectedColor(color);
      setRelatedColor(color);
    }
  };

  const handlePolarizadoChange = () => {
    const newPolarizado = !plotted;
    setFormulario({ ...formulario, plotted: newPolarizado });
    setPlotted(newPolarizado);
  };

  const handleCotizacionChange = (option) => {
    const cotizacionValue = option === "Si";
    setFormulario({ ...formulario, cotizacion: cotizacionValue });
    setCotizacion(option);
  };

  const handleFinanciacionChange = (option) => {
    const financiacionValue = option === "Si";
    setFormulario({ ...formulario, financiacion: financiacionValue });
    setFinanciacion(option);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleMeInteresaClick();
  };

  const handleWhatsAppClick = () => {
    const color = (formulario.selectedColor && formulario.selectedColor.name) ? formulario.selectedColor.name : 'Cualquier color';

    let mensaje = `Hola, estoy interesado en ${producto.brand.name} ${
      producto.modelo.name
    }(Nuevo):
    - Transmisión: ${producto.transmission}
    - Versión: ${producto.version.name}
    - Color: ${color}
    - Polarizado: ${formulario.plotted ? "Si" : "No"}`;

    if (formData && cotizacion === "Si") {
      const { brand, modelo, version, year, transmission, color, mileage } =
        formData;
      mensaje += `
 Cotización: ${cotizacion}

- Marca: ${brand}
- Modelo: ${modelo}
- Versión: ${version}
- Año: ${year}
- Transmisión: ${transmission}
- Color: ${color}
- Kilometraje: ${mileage};
      
 Financiación: ${financiacion}`;
    } else {
      // Agregar cotización y financiación al mensaje
      mensaje += `
- Cotización: ${cotizacion}
- Financiación: ${financiacion}`;
    }

    const whatsappNumber = "5491131138421";
    // Construye la URL de WhatsApp
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      mensaje
    )}`;

    // Abre la URL de WhatsApp
    window.open(whatsappURL, "_blank");
  };

  return (
    <AsideProduct>
      <div className="info-title">
        <div className="product-name">
          <p>
            {producto.brand.name} {producto.modelo.name}
          </p>
        </div>
        <div className="slang">
          <p>Personalizá tu vehículo y elegí cómo pagarlo</p>
        </div>
      </div>
      <InfoForm>
        <div className="form-transmision">
          <div className="title">
            <img src={IconOne} alt="" /> <p>Elegí la transimisión</p>
          </div>

          <div className="btn-container">
            {allTransmissions.map((value, index) => (
              <button
                key={index}
                className={transmission === value ? "isActive" : ""}
                onClick={() => handleTransmissionChange(value)}
              >
                {value}
              </button>
            ))}
          </div>
        </div>
        <div className="form-version">
          <div className="title">
            <img src={IconTwo} alt="" />
            <p>Elegí la versión</p>
          </div>
          <div className="btn-container">
            {versions.map((versionItem, index) => (
              <button
                key={index}
                className={version === versionItem.name ? "isActive" : "version-btn"}
                disabled={!versionItem.isActive}
                onClick={() => {
                  handleVersionChange(versionItem);
                }}
              >
                {versionItem.name}
              </button>
            ))}
          </div>
        </div>
        <div className="form-colors">
          <div className="title">
            <img src={IconThree} alt="3" />
            <p>Elegí el color</p>
          </div>
          <div className="colors-container">
            {producto.colors.map((color, index) => (
              <StyledColorOption
                key={index}
                style={{ backgroundColor: color.code.toLowerCase() }}
                className={color === selectedColor ? "active" : ""}
                onClick={() => handleColorClick(color)}
              />
            ))}
          </div>

          <div className="polarizado-option">
            <label htmlFor="polarizado-option">
              <img src={plotted ? check : checkbox} alt="Checkbox" />
              Vidrios polarizados
            </label>
            <input
              type="checkbox"
              id="polarizado-option"
              checked={plotted}
              onChange={handlePolarizadoChange}
            />
          </div>
        </div>

        <div className="form-cotizador">
          <div className="title title-lg">
            <img src={IconFour} alt="" />
            <p>¿Te interesa entregar tu auto como parte de pago?</p>
          </div>

          <div className="btn-container">
            <button
              onClick={() => {
                handleCotizacionChange("Si");
                handleCotizadorClick();
              }}
              className={cotizacion === "Si" ? "isActive" : ""}
            >
              Sí
            </button>
            <button
              onClick={() => {
                handleCotizacionChange("No");
              }}
              className={cotizacion === "No" ? "isActive" : ""}
            >
              No
            </button>
          </div>
        </div>
        <div className="form-financiador">
          <div className="title title-lg">
            <img src={IconFive} alt="5" />
            <p>¿Te interesa explorar opciones de financiación?</p>
          </div>

          <div className="btn-container">
            <button
              onClick={() => {
                handleButtonClick("Si");
                handleFinanciacionChange("Si");
              }}
              className={financiacion === "Si" ? "isActive" : ""}
            >
              Sí
            </button>
            <button
              onClick={() => {
                handleButtonClick("No");
                handleFinanciacionChange("No");
              }}
              className={financiacion === "No" ? "isActive" : ""}
            >
              No
            </button>
          </div>
        </div>
        <div className="form-btns">
          <StyledBtn className="btn-whatsapp" onClick={handleMeInteresaClick}>
            Quiero que me llame un asesor
          </StyledBtn>
          <StyledBtnAsesor onClick={handleWhatsAppClick}>
            <img src={IconWhatsApp} alt="icon_whatsapp" />
            {/*Al cliquear el boton, se enviaran todos los datos del formulario a un numero de whatsapp: +54 9 11-31138421*/}
            Hablar por whatsapp
          </StyledBtnAsesor>
        </div>
      </InfoForm>
    </AsideProduct>
  );
};

export default AsideProduct0km;
