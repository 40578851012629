import React, { useState } from "react";
import styled from "styled-components";
import "../styles.css";
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const Container = styled.div`
  display: flex;
  position: fixed;
  background-color: white;
  flex-direction: column;
  border-radius: 6px;
  width: 457px;
  height: 267px;
  font-family: var(--font-family);
  font-weight: 700;
  z-index: 1001;
  transform: translate(-50%, -55%);
  top: 50%;
  left: 50%;

  .options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    display: flex;
    width: 100%;
    height: 81px;
    font-size: 22px;
    line-height: 26px;
    background-color: var(--prime-color);
    color: white;
    justify-content: center;
    align-items: center;
    border-radius: 6px 6px 0 0;
    p {
      padding-top: 25px;
    }
  }

  .link {
    display: flex;
    flex-direction: column;
    padding: 24px;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      text-align: start;
    }
    input {
      width: 305px;
      height: 42px;
      background-color: #f6f6f6;
      font-size: 16px;
      line-height: 18.5px;
      font-weight: 500;
      border: none;
    }
  }

  button {
    border-radius: 6px;
    width: 195px;
    height: 36px;
    font-weight: 700;
    border: none;
  }
`;

const AgregarVideo = ({ closeModal, addData }) => {
  const [url, setUrl] = useState(null)
  const handleSetUrl = (e) => {
    console.log(e.target.value)
    setUrl(e.target.value)
  }

  const handleContinue = (e) => {
    const file = new File([url], "video.txt", {
      type: "text/plain",
    });
    file['url'] = url;
    console.log(file)
    addData(file)
    closeModal()
  }
  return (
    <>
      <Overlay onClick={closeModal} />
      <Container>
        <div className="title">
          <p>Agregar video</p>
        </div>
        <div className="options">
          <div className="link">
            <h3>Link</h3>
            <input type="text" placeholder=" Copie la url del video" onChange={handleSetUrl}  value={url}/>
          </div>
          <button onClick={handleContinue} className="button primary-btn">
            Continuar
          </button>
        </div>
      </Container>
    </>
  );
};

export default AgregarVideo;
