import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import iconInfo from "../images/infoButton.png";
import iconInfoHover from "../images/icon_info_hover.png";
import ModalCotizatedPage from "./ModalInternoCotizadorScreen";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .instruction {
    position: absolute;
    bottom: 145px; /* Adjust this value as needed */
    right: -100px;
    width: 282px;
    height: 135px;
    background-color: black;
    color: white;
    font-family: var(--roboto);
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
    line-height: 14px;
    transition: all 0.3s ease-in-out;
    z-index: 222; /* Ensure the popup appears above other elements */

    button {
      display: none;
    }
    input {
      display: none;
      cursor: pointer;
    }
  }

  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    select {
      width: 100%;
      height: 42px;
      margin-bottom: 16px;
      font-family: var(--font-family);
      outline: none;
      border-radius: 8px;
      border: 1px solid #ddd;
      background: #fff;
      padding: 6px 10px;

      option:disabled {
        display: none;
      }

      /* Styles for the options in the selectors */
      option {
        font-size: 14px; /* Font size */
        padding: 8px 10px; /* Inner spacing */
      }
    }

    @media (max-width: 576px) {
      select {
        height: 36px; /* Reduced height for mobile devices */
        padding: 6px 10px; /* Inner spacing */
        font-size: 14px; /* Font size */
        max-width: 90%; /* Maximum width of the selector */
      }

      option {
        padding: 6px 8px; /* Inner spacing for options */
      }
    }
  }

  @media (max-width: 576px) {
    margin-top: 45px;

    .instruction {
      bottom: 175px; /* Adjust this value as needed */
      right: 50px;
      align-items: center;
      text-align: center;
      width: 210px;
      height: 219px;
      background-color: black;
      color: white;
      font-family: var(--roboto);
      border-radius: 8px;
      padding: 10px;
      font-size: 14px;
      line-height: 14px;
      transition: all 0.3s ease-in-out;
      z-index: 1; /* Ensure the popup appears above other elements */

      .instruction-inside-btn {
        display: block;
        background-color: transparent;
        border: none;
        font-family: var(--font-family);
        outline: none;
        color: white;
        width: 137px;
        height: 32px;
        padding-top: 5px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        text-decoration: underline;
        margin-left: 25px;
      }
    }
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  button {
    outline: none;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  font-family: var(--font-family);
  outline: none;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 6px 10px;
  height: 42px;
  font-weight: 400;

  &::placeholder {
    font-style: italic;
    /* background-color: #595e62; */
  }
  /* Estilos para resaltar la palabra "marca" en negrita */
`;

const ImagesButton = styled.div`
  display: flex;
  height: 42px;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
  font-family: var(--font-family);
  cursor: pointer;

  .terciary-btn {
    height: 100%;
    justify-content: center;
    padding: 6px;
    padding-bottom: 6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    background-color: #eaedf4;
    color: var(--prime-color) !important;
    text-align: center;
    border-radius: 8px 0px 0px 8px;
    text-decoration: none !important;
    margin: 0px !important;
    width: 100%;
  }
  .terciary-btn-mobile {
    height: 100%;
    display: none;
    justify-content: center;
    padding: 6px;
    padding-bottom: 6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    background-color: #eaedf4;
    color: var(--prime-color) !important;
    text-align: center;
    border-radius: 8px 0px 0px 8px;
    text-decoration: none !important;
    margin: 0px !important;
    width: 100%;
  }
  p {
    margin-top: 8px;
  }
  img {
    border-radius: 0px 8px 8px 0px;
    transition: filter 0.5s ease-in;
    overflow-x: hidden;
    width: 42px; /* Ajusta el tamaño de la imagen */
  }

  &:hover img {
    filter: brightness(50%);
    content: url(${iconInfoHover});
  }
  label {
    padding-top: 8px;
  }
  input {
    display: none;
  }
  @media (max-width: 576px) {
    img {
      display: none;
    }
    .terciary-btn {
      display: none;
    }
    .terciary-btn-mobile {
      border-radius: 8px;
      display: block;
    }
    label {
      padding-top: 0;
    }
  }
`;
const SubmitButton = styled.button`
  width: 100%;
  /* justify-self: flex-end; */
  margin-top: 20px;
  background-color: var(--prime-color);
  color: white;
  border-radius: 8px;
  height: 42px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  border: none;
  line-height: 20px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #595e62;
  }
  @media (max-width: 576px) {
    align-self: center;
    height: 42px;
    margin-bottom: 30px;
  }
`;

const FormCotizador1 = ({ refreshWindow, setRefreshWindow, motivo }) => {
  const containerRef = useRef(null);
  const instructionRef = useRef(null);
  const [showInfo, setShowInfo] = useState(false); // Estado para controlar la visibilidad de la ventana de información
  const [refresh, setRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formulario, setFormulario] = useState({
    brand: "",
    modelo: "",
    version: "",
    year: "",
    transmission: "",
    color: "",
    mileage: "",
  });

  // Estado para almacenar las imágenes cargadas
  const [loadedImages, setLoadedImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true); // Mostrar el modal si todos los campos están completos
    //resetFormulario();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        instructionRef.current &&
        !instructionRef.current.contains(event.target)
      ) {
        setShowInfo(false);
      }
    };

    if (showInfo) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showInfo]);
  //manejador carga de imagenes
  const handleAddImages = (e) => {
    const files = Array.from(e.target.files);
    setLoadedImages([...loadedImages, ...files]);
    const urls = files.map((file) => URL.createObjectURL(file));
    setImageURLs((prevURLs) => [...prevURLs, ...urls]);
  };
  const handleInfoHover = () => {
    setShowInfo(true); // Mostrar la ventana de información al hacer hover sobre el icono
  };

  const handleInfoLeave = () => {
    setShowInfo(false); // Ocultar la ventana de información al dejar de hacer hover sobre el icono
  };

  //cerra modal cotizador
  const closeModal = () => {
    setShowModal(false);
    //setRefreshWindow(true);
  };

  const resetFormulario = () => {
    setFormulario({
      brand: "",
      modelo: "",
      version: "",
      year: "",
      transmission: "",
      color: "",
      mileage: "",
    });
    setLoadedImages([]);
    setImageURLs([]);
  };

  const opciones = {
    transmission: ["Automática", "Manual"],
    year: [
      "2021",
      "2020",
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012",
      "2011",
      "2010",
      "2009",
      "2008",
      "2007",
      "2006",
      "2005",
      "2004",
      "2003",
      "2002",
      "2001",
      "2000",
      "1999",
      "1998",
      "1997",
      "1996",
      "1995",
      "1994",
      "1993",
      "1992",
      "1991",
      "1990",
    ],
  };
  // Función para manejar el cambio en los inputs
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [id]: value,
    }));
  };
  /*------------------------- */

  return (
    <Container
      className="offset-md-1 col-md-4 offset-1 col-10"
      ref={containerRef}
    >
      {showModal && (
        <ModalCotizatedPage
          formularioAutoCotizado={formulario}
          loadedImages={imageURLs}
          closeModal={closeModal}
          onModalClose={() => setRefresh(true)}
          resetFormulario={resetFormulario}
          motivo={motivo}
        />
      )}
      <StyledContainer>
        <form onSubmit={handleSubmit}>
          <div>
            <select
              id="year"
              type="number"
              required
              value={formulario.year}
              onChange={handleChange}
            >
              <option value="" disabled>
                Año
              </option>
              {opciones.year.map((opcion) => (
                <option key={opcion} value={opcion}>
                  {opcion}
                </option>
              ))}
            </select>
          </div>
          <div>
            <StyledInput
              type="text"
              id="brand"
              autoComplete="none"
              required
              value={formulario.brand}
              onChange={handleChange}
              placeholder="Indicá la marca de tu vehículo"
            />
          </div>
          <div>
            <StyledInput
              type="text"
              id="modelo"
              autoComplete="none"
              value={formulario.modelo}
              required
              placeholder="Indicá el modelo de tu vehículo"
              onChange={handleChange}
            />
          </div>
          <div>
            <StyledInput
              type="text"
              id="version"
              autoComplete="none"
              value={formulario.version}
              required
              placeholder="Indicá la versión de tu vehículo"
              onChange={handleChange}
            />
          </div>
          <div>
            <select
              name="transmission"
              required
              value={formulario.transmission}
              autoComplete="none"
              id="transmission"
              onChange={handleChange}
              placeholder="Transmision"
            >
              <option value="" disabled>
                Transmisión
              </option>
              {opciones.transmission.map((opcion) => (
                <option key={opcion} value={opcion}>
                  {opcion}
                </option>
              ))}
            </select>
          </div>
          <div>
            <StyledInput
              type="text"
              autoComplete="none"
              id="color"
              required
              value={formulario.color}
              placeholder="¿Cuál es el color de tu vehículo?"
              onChange={handleChange}
            />
          </div>
          <div>
            <StyledInput
              type="number"
              value={formulario.mileage}
              required
              placeholder="Indicá el kilometraje"
              id="mileage"
              onChange={handleChange}
            />
          </div>
          <ImagesButton>
            {showInfo && (
              <div className="instruction" ref={instructionRef}>
                Buscá siempre una buena iluminación, siempre es mejor la luz
                natural. Variá los ángulos de las fotos, asegurándote de
                capturar el frente, lateral y parte trasera. Enfocá los detalles
                exteriores, tales como las ruedas, la pintura y cualquier
                posible daño visible. No te olvides del interior: abrí las
                puertas y tomá fotos detalladas del tablero y los asientos.
                <button className="instruction-inside-btn">
                  <label htmlFor="upload-images">Continuar</label>

                  <input
                    id="upload-images"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleAddImages}
                  />
                </button>
              </div>
            )}
             {/*<div className="terciary-btn load-btn">
              <label htmlFor="upload-images">
                Agregar fotos
                <input
                  id="upload-images"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleAddImages}
                />
              </label>
            </div> */}
          {/*   <div className="terciary-btn-mobile" onClick={handleInfoHover}>
              <p>Cargar fotos</p>
            </div>
            <div
              className="instruction-icon"
              onMouseEnter={handleInfoHover}
              onMouseLeave={handleInfoLeave}
            >
              <img src={iconInfo} alt="icon-img" />
            </div> */}
          </ImagesButton>
          <div className="row justify-content-end">
            <div className="col-md-6 ">
              <SubmitButton type="submit">Finalizar</SubmitButton>
            </div>
          </div>
        </form>
      </StyledContainer>
    </Container>
  );
};

export default FormCotizador1;
