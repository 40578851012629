import React, { useState } from "react";
import styled from "styled-components";
import IconWhatsApp from "../images/icon_whatsapp_blue.png";
import IconFour from "../images/icon_4blue.png";
import IconFive from "../images/icon_5blue.png";
import "../styles.css";
import FooterMobile from "./FooterMobile";
import Price from "./Price";

const AsideUsados = styled.aside`
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  width: 100%;
  margin-top: 32px;
  gap: 25px;
  text-align: start;
  padding: 0;
  button {
    outline: none;
  }
  .title {
    display: flex;
    img {
      display: none;
      height: 20px;
      margin-right: 8px;
      @media (max-width: 576px) {
        display: flex;
      }
    }
  }
  .info-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    line-height: 18px;
    font-weight: 700;
    font-size: 16px;
    gap: 30px;
  }

  .info-title {
    display: flex;
    flex-direction: column;
    font-size: 45px;
    line-height: 48px;
    font-weight: 700;
    text-align: start;
    justify-content: center;

    p {
      padding: 0;
      margin: 0;
      padding-bottom: 5px;
    }
  }
  .direction {
    font-style: italic;
    font-size: 22px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 25px;
  }
  .kms-yrs {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
  }
  .info-price {
    margin: 10px 0;

    font-size: 38px;
    line-height: 40px;
    font-weight: 700;
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    /* margin-top: 30px; */

    button {
      width: 100%;
      padding: 10px;
      background-color: var(--gris-claro);
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      color: var(--prime-color);
      line-height: 18px;
      border: 1px solid transparent;
      cursor: pointer;
      border-radius: 6px;
      outline: none;
    }

    button:hover {
      background-color: white;
      border: 1px solid var(--prime-color);
    }

    button.isActive {
      color: white;
      background-color: #595e62;
      border-color: transparent;
    }
  }
  @media (max-width: 576px) {
    margin-top: 15px;
    .info-directions,
    .info-price,
    .info-title {
      display: none;
    }
    .info-form {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;
const StyledBtnAsesor = styled.button`
  display: flex;
  color: var(--prime-color);
  border-radius: 6px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 15px;
  background-color: transparent;
  border: 1px solid var(--prime-color);

  &:hover {
    background-color: #595e62;
    color: white;
    border: 1px solid transparent;
    img {
      filter: brightness(0) invert(1);
    }
  }
  img {
    margin-right: 5px;
  }
`;
const StyledBtn = styled.button`
  display: flex;
  background-color: var(--prime-color);
  color: white;
  border-radius: 6px;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid transparent;
  line-height: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 15px;

  &:hover {
    background-color: #595e62;
    color: white;
    border: 1px solid transparent;
  }
`;
const AsideProductUsados = ({
  handleCotizadorClick,
  handleMeInteresaClick,
  handleFinanciacionClick,
  producto,
  formData,
}) => {
  const [activeButton, setActiveButton] = useState(null);
  const [showCotizadorButtons, setShowCotizadorButtons] = useState(false);
  const [showFinanciacionButtons, setShowFinanciacionButtons] = useState(false);
  // Variables de estado para opciones de cotización y financiación
  const [cotizacion, setCotizacion] = useState("");
  const [financiacion, setFinanciacion] = useState("");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName === activeButton ? null : buttonName);
    setShowCotizadorButtons(false);
    setShowFinanciacionButtons(false);
  };

  const handleCotizacionChange = (option) => {
    setCotizacion(option);
  };

  const handleFinanciacionChange = (option) => {
    setFinanciacion(option);
  };

  const handleWhatsAppClick = () => {
    let mensaje = `Hola, estoy interesado en el ${producto.brand.name} ${producto.modelo.name}${producto.version.name}(Usado):`;

    // Si formData tiene datos, agregarlos al mensaje
    if (formData && cotizacion === "Si") {
      const { brand, modelo, version, year, transmission, color, mileage } =
        formData;
      mensaje += `
 Cotización: ${cotizacion}

- Marca: ${brand}
- Modelo: ${modelo}
- Versión: ${version}
- Año: ${year}
- Transmisión: ${transmission}
- Color: ${color}
- Kilometraje: ${mileage};
      
 Financiación: ${financiacion}`;
    } else {
      // Agregar cotización y financiación al mensaje
      mensaje += `
- Cotización: ${cotizacion}
- Financiación: ${financiacion}`;
    }

    const whatsappNumber = "5491131138421";
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      mensaje
    )}`;

    window.open(whatsappURL, "_blank");
  };

  return (
    <AsideUsados>
      <div className="aside-header">
        <div className="info-title">
          <p>
            {producto.brand.name} {producto.modelo.name}
          </p>
          <p>{producto.version.name}</p>
        </div>
        <div className="info-directions">
          <div className="direction">{producto.location.name}</div>
          <div className="kms-yrs">
            {producto.mileage} km - {producto.year}
          </div>
        </div>
        <div className="info-price"><Price value={producto.price} /></div>
      </div>
      <div className="info-form">
        <div className="form-cotizador">
          <div className="title title-lg">
            <img src={IconFour} alt="" />
            <p>¿Te interesa entregar tu auto como parte de pago?</p>
          </div>

          <div className="btn-container">
            <button
              onClick={() => {
                handleCotizacionChange("Si");
                handleCotizadorClick();
              }}
              className={cotizacion === "Si" ? "isActive" : ""}
            >
              Sí
            </button>
            <button
              onClick={() => {
                handleCotizacionChange("No");
              }}
              className={cotizacion === "No" ? "isActive" : ""}
            >
              No
            </button>
          </div>
        </div>
        <div className="form-financiador">
          <div className="title title-lg">
            <img src={IconFive} alt="5" />
            <p>¿Te interesa explorar opciones de financiación?</p>
          </div>

          <div className="btn-container">
            <button
              onClick={() => {
                handleFinanciacionChange("Si");
                handleButtonClick("Si");
                // handleFinanciacionClick();
              }}
              className={financiacion === "Si" ? "isActive" : ""}
            >
              Sí
            </button>
            <button
              onClick={() => {
                handleFinanciacionChange("No");
                handleButtonClick("No");
              }}
              className={financiacion === "No" ? "isActive" : ""}
            >
              No
            </button>
          </div>
        </div>
        <div className="form-btns">
          <StyledBtn className="btn-whatsapp" onClick={handleMeInteresaClick}>
            Quiero que me llame un asesor
          </StyledBtn>
          <StyledBtnAsesor onClick={handleWhatsAppClick}>
            <img src={IconWhatsApp} alt="icon_whatsapp" />
            Hablar por whatsapp
          </StyledBtnAsesor>
        </div>
      </div>
      <FooterMobile />
    </AsideUsados>
  );
};

export default AsideProductUsados;
