import React, { useState } from "react";
import styled from "styled-components";
import PreguntaIcon from "../images/Ask_icon.png";
import "../styles.css";
import FaqMobile from "./FaqMobile";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 64px;
  margin-bottom: 52px;
  font-family: var(--font-family);

  @media (max-width: 576px) {
    display: none;
  }
`;

const Title = styled.p`
  display: inline;
  font-size: 38px;
  line-height: 40px;
  font-weight: 700;
  padding: 10px;
`;

const FaqList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
  margin-top: 24px;
`;

const FaqItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  width: fit-content;
  margin-bottom: 12px;
  align-items: flex-start;
`;

const FaqItem = styled.div`
  font-size: 16px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-weight: ${({ $isSelected }) => ($isSelected ? "700" : "400")};
  color: ${({ $isSelected }) => ($isSelected ? "black" : "gray")};

  span {
    display: inline-block;
    justify-content: center;
    background-color: ${({ $isSelected }) =>
      $isSelected ? "" : "transparent"};
    color: inherit;
    line-height: 16px;
    border-radius: 6px;
    padding: 8px;
    width: 100%;
  }

  img {
    margin-right: 6px;
    width: 24px;
    height: 24px;
    margin-top: 7px;
  }
`;

const Answer = styled.div`
  font-size: 16px;
  text-align: left;
  margin-top: 24px;
  height: 100%;
  width: 100%;
`;

const Faq = () => {
  const [faqItems, setFaqItems] = useState([
    {
      question:
        "¿En qué momento se entrega la unidad usada durante la operación de compra?",
      answer: (
        <>
          La entrega de la unidad usada varía según el tipo de vehículo
          adquirido:
          <ul>
            <li>
              Compra de un vehículo usado o 0 km patentado: En estos casos, se
              lleva a cabo un intercambio "llave por llave", lo que implica que
              la entrega de la unidad usada se realiza simultáneamente con la
              recepción del nuevo vehículo o del 0 km patentado.
            </li>
            <li>
              Compra de un vehículo 0 km: En el caso de la compra de un vehículo
              0 km, la entrega de la unidad usada se efectúa en el momento de la
              firma del patentamiento.
            </li>
          </ul>
        </>
      ),
      isOpen: true,
    },
    {
      question: "¿Cuándo es necesario presentar la Licitud de Fondos?",
      answer: (
        <>
          <ul>
            <li>
              La presentación de la Licitud de Fondos es requerida en función
              del monto establecido por la Dirección Nacional de Registro de la
              Propiedad del Automotor (DNRPA).
            </li>
            <li>
              La Licitud de Fondos es un documento que certifica la procedencia
              lícita de los fondos utilizados para realizar una transacción,
              especialmente en casos de compras de vehículos que involucran
              sumas significativas de dinero.
            </li>
            <li>
              Nuestro equipo se encargará de proporcionar orientación y
              asistencia para cumplir con todos los requisitos necesarios para
              llevar a cabo una transacción segura y conforme a la normativa
              aplicable.
            </li>
          </ul>
        </>
      ),
      isOpen: false,
    },
    {
      question:
        "¿Qué documentación se requiere al momento de la entrega del vehículo usado?",
      answer: (
        <>
          <ul>
            <li>Manual del vehículo</li>
            <li>Llave duplicada</li>
            <li>Título de propiedad</li>
            <li>Cédulas de identificación del vehículo</li>
            <li>Verificación Técnica Vehicular (VTV)</li>
            <li>Oblea de gas</li>
            <li>Formulario 08</li>
            <li>Informe de dominio y multas</li>
            <li>Comprobante de pago de patentes</li>
            <li>Grabado de autopartes</li>
          </ul>
        </>
      ),
      isOpen: false,
    },
    {
      question: "¿Cuánto tiempo demora la transferencia/patentamiento?",
      answer:
        "Se estima un período de 7 a 10 días hábiles para completar el proceso de transferencia o patentamiento.",
      isOpen: false,
    },
    {
      question: "¿Quién puede ser titular de la unidad?",
      answer: (
        <>
          <p>
            El titular de la unidad puede ser cualquier persona mayor de 18
            años.
          </p>
          <p>
            Si es menor de edad, se requiere la autorización de los padres o
            representantes legales, o bien, la emancipación legal del menor.
          </p>
          <p>
            Además, es importante destacar que la titularidad del vehículo no
            está limitada a una sola persona. Pueden existir múltiples
            titulares, lo que permite compartir la responsabilidad y los
            beneficios asociados a la propiedad del vehículo entre varias
            personas.
          </p>
        </>
      ),
      isOpen: false,
    },
    {
      question: "¿Las unidades salen aseguradas?",
      answer:
        "No, la responsabilidad de asegurar la unidad recae en los compradores. Antes de retirar el vehículo, se les proporciona la documentación necesaria para que gestionen el seguro con las aseguradoras de su elección.",
      isOpen: false,
    },
    {
      question:
        "¿Una vez terminadas las cuotas de mi crédito, cuáles son los pasos a seguir?",
      answer:
        "Los pasos a seguir una vez terminadas las cuotas del crédito dependen de la entidad financiera. Se informarán los pasos específicos el día de la entrega de la unidad.",
      isOpen: false,
    },
    {
      question: "¿Qué tipo de financiación ofrecen?",
      answer:
        "Ofrecemos diferentes opciones de financiación, que incluyen financiamiento directo a través de las financieras asociadas de cada marca, así como opciones a través de bancos o financieras independientes.",
      isOpen: false,
    },
    {
      question: "¿Las unidades tienen garantía?",
      answer: "Sí, todas las unidades cuentan con una garantía de 3 meses.",
      isOpen: false,
    },
    {
      question: "¿Qué cubre el pago de la transferencia?",
      answer:
        "El pago de la transferencia cubre todos los gastos relacionados con la presentación del trámite en el registro correspondiente. Sin embargo, no incluye el pago de patentes ni las cédulas azules.",
      isOpen: false,
    },
  ]);

  const toggleItem = (index) => {
    const updatedFaqItems = faqItems.map((item, i) => ({
      ...item,
      isOpen: i === index ? !item.isOpen : false, // Cerrar todas las demás respuestas al abrir una nueva
    }));

    // Verificar si todas las respuestas están cerradas y abrir la primera si es necesario
    const allClosed = updatedFaqItems.every((item) => !item.isOpen);
    if (allClosed && updatedFaqItems.length > 0) {
      updatedFaqItems[0].isOpen = true;
    }

    setFaqItems(updatedFaqItems);
  };

  return (
    <div className="container">
      <StyledContainer>
        <div className="row">
          <div className="offset-md-2 col-md-8">
            <Title>Despejá tus inquietudes</Title>
          </div>
        </div>
        <div className="row">
          <div className="offset-md-2 col-md-4">
            <FaqList>
              {faqItems.map((item, index) => (
                <div key={index}>
                  <FaqItem
                    onClick={() => toggleItem(index)}
                    $isSelected={item.isOpen}
                  >
                    <FaqItemContainer>
                      <img src={PreguntaIcon} alt="icon-ask" />
                      <span>{item.question}</span>
                    </FaqItemContainer>
                  </FaqItem>
                </div>
              ))}
            </FaqList>
          </div>
          <Answer className="answer col-md-4">
            {faqItems.map(
              (item, index) =>
                item.isOpen && <div key={index}>{item.answer}</div>
            )}
          </Answer>
        </div>
      </StyledContainer>
      <FaqMobile />
    </div>
  );
};

export default Faq;
