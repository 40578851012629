import React from "react";
import styled from "styled-components";
import "../styles.css";
import axiosInstance, { token, useAxiosWithAuth } from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;
const ModalContainer = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  background: white;
  width: 554px;
  height: 180px;
  transform: translate(-50%, -85%);
  top: 50%;
  left: 50%;
  justify-content: center;
  border-radius: 6px;

  font-family: var(--font-family);
  z-index: 1001;
  button {
    outline: none;
  }
  .titles {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  span {
    font-weight: 700;
    line-height: 20px;
  }

  .btn-container {
    display: flex;
    justify-content: space-around;
    height: 45px;
    margin-top: 16px;
    button {
      width: 195px;
    }
  }
`;

const ModalAdminEliminar = ({ closeModalDelete, producto, updateList }) => {
  const axiosInstanceWithAuth = useAxiosWithAuth();
  const navigate = useNavigate();
  const handleDeleteMarca = async () => {
    const endpoint =
      producto.newCar === 1
        ? ENDPOINTS.DELETE_NEW_CAR
        : ENDPOINTS.DELETE_USED_CAR;

    await axiosInstanceWithAuth
      .delete(`${endpoint}/${producto._id}`)
      .then((res) => {
        closeModalDelete();
        Swal.fire({
          icon: "success",
          title: "Producto eliminado con éxito",
          iconColor: "#1A3EEA",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => updateList(true));
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Ha ocurrido un error. No se puede eliminar el vehiculo",
          iconColor: "#1A3EEA",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  return (
    <>
      <Overlay onClick={closeModalDelete} />
      <ModalContainer>
        <div className="titles">
          <p>
            <span>Estás a punto de eliminar un producto</span>
            <br />
            ¿Confirmás la eliminación?
          </p>
        </div>
        <div className="btn-container">
          <button className="primary-btn-reverse" onClick={closeModalDelete}>
            No
          </button>
          <button
            className="primary-btn"
            style={{ border: "none" }}
            onClick={handleDeleteMarca}
          >
            Si
          </button>
        </div>
      </ModalContainer>
    </>
  );
};

export default ModalAdminEliminar;
