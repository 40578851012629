import React, { useState } from "react";
import styled from "styled-components";
import IconTraccion from "../images/icon_traccion.png";
import IconCar from "../images/icon_car.png";
import IconPasajeros from "../images/icon_pasajeros.png";
import IconAlarma from "../images/icon_alarma.png";
import IconCombBlack from "../images/icon_combustibleBlack.png";
import IconRetroceso from "../images/icon_retroceso.png";
import IconRadio from "../images/icon_radio.png";
import IconSensor from "../images/icon_sensorEstacionamiento.png";
import IconSeguridad from "../images/icon_security1.png";
import IconVelocidad from "../images/icon_velocidad.png";
import IconAirbag from "../images/icon_airbag.png";
import IconLevantaCristales from "../images/icon_levantaCristales.png";
import IconLlantas from "../images/icon_llantas1.png";
import IconColor from "../images/icon_color.png";
import ArrowRight from "../images/icon_arrow_aside.png";
import ArrowDown from "../images/icon_arrowblue_down.png";

const Container = styled.div`
  display: none;
  flex-direction: column;
  @media (max-width: 576px) {
    display: block;
  }
`;
const CaracteristicasContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin: 10px 0px;

  .title {
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    height: 20px;
    cursor: pointer;

    img {
      margin-top: 3px;
      margin-right: 5px;
      width: 8px;
      height: 8px;
    }
  }

  .icons {
    display: column;
    width: 100%;
    justify-content: space-between;
  }

  .icon-div {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0px;
  }
  .icon-div p {
    margin-bottom: -5px;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

export const ProdutcUsadoCaracteristicasMobile = ({ producto }) => {
  /*----------------------------------------------- */
  //FUNCIONALIDAD DE LAS CARACTERISTICAS ABRIR Y CERRAR
  const [showExterior, setShowExterior] = useState(false);
  const [showCadicionales, setShowCadicionales] = useState(false);
  const [showSeguridad, setShowSeguridad] = useState(false);
  const [showEntretenimiento, setShowEntretenimiento] = useState(false);
  const [showCaracteristicas, setShowCaracteristicas] = useState(false);

  const handleExteriorClick = () => {
    setShowExterior(!showExterior);
  };

  const handleCaracteristicasClick = () => {
    setShowCaracteristicas(!showCaracteristicas);
  };

  const handleCadicionalesClick = () => {
    setShowCadicionales(!showCadicionales);
  };

  const handleSeguridadClick = () => {
    setShowSeguridad(!showSeguridad);
  };

  const handleEntretenimientoClick = () => {
    setShowEntretenimiento(!showEntretenimiento);
  };
  /*------------------------------------------------ */
  return (
    <Container className="container offset-1 col-10">
      <div className="row">
        <CaracteristicasContainer>
          <div className="title" onClick={handleCaracteristicasClick}>
            <img
              src={showCaracteristicas ? ArrowDown : ArrowRight}
              alt="arrow"
            />
            <p>Características Principales</p>
          </div>
          {showCaracteristicas && (
            <>
              <div className="icons">
                <div className="icon-div">
                  <img src={IconCar} alt="iconCar" />
                  <div>
                    <p>Tipo: {producto.category.name}</p>
                  </div>
                </div>
                <div className="icon-div">
                  <img src={IconCombBlack} alt="iconCombustible" />
                  <div>
                    <p>Combustible: {producto.fuel}</p>
                  </div>
                </div>
              </div>
              <div className="icons">
                <div className="icon-div">
                  <img src={IconTraccion} alt="iconTraccion" />
                  <div>
                    <p>Traccion: {producto.traction}</p>
                  </div>
                </div>
                <div className="icon-div">
                  <img src={IconPasajeros} alt="iconPasajeros" />
                  <div>
                    <p>Pasajeros: {producto.seating}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </CaracteristicasContainer>
        <CaracteristicasContainer>
          <div className="title" onClick={handleExteriorClick}>
            <img src={showExterior ? ArrowDown : ArrowRight} alt="arrow" />
            <p>Exterior</p>
          </div>
          {showExterior && (
            <div className="icons">
              <div className="icon-div">
                <img src={IconLlantas} alt="iconLlantas" />
                <div>
                  <p>Llantas: {producto.tyreMaterial}</p>
                </div>
              </div>
              <div className="icon-div">
                <img src={IconColor} alt="iconColor" />
                <div>
                  <p>Color: {producto.color.name}</p>
                </div>
              </div>
            </div>
          )}
        </CaracteristicasContainer>
        <CaracteristicasContainer>
          <div className="title" onClick={handleCadicionalesClick}>
            <img src={showCadicionales ? ArrowDown : ArrowRight} alt="arrow" />
            <p>Características adicionales</p>
          </div>
          {showCadicionales && (
            <>
              <div className="icons">
                <div className="icon-div">
                  <img src={IconLevantaCristales} alt="iconLevantaCristales" />
                  <div>
                    <p>
                      Levanta cristales eléctricos:{" "}
                      {producto.liftsWindows === "true" ? "Si" : "No"}
                    </p>
                  </div>
                </div>
                <div className="icon-div">
                  <img src={IconVelocidad} alt="iconVelocidad" />
                  <div>
                    <p>
                      Control de velocidad:{" "}
                      {producto.speedControl === "true" ? "Si" : "No"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="icons">
                <div className="icon-div">
                  <img src={IconRetroceso} alt="iconRetroceso" />
                  <div>
                    <p>
                      Cámara de retroceso:{" "}
                      {producto.backupCamera === "true" ? "Si" : "No"}
                    </p>
                  </div>
                </div>
                <div className="icon-div">
                  <img src={IconAlarma} alt="iconAlarma" />
                  <div>
                    <p>Alarma: {producto.alarm === "true" ? "Si" : "No"}</p>
                  </div>
                </div>
              </div>
              <div className="icons">
                <div className="icon-div">
                  <img src={IconSensor} alt="iconSensor" />
                  <div>
                    <p>
                      Sensor de estacionamiento:{" "}
                      {producto.parkingSensor === "true" ? "Si" : "No"}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </CaracteristicasContainer>
        <CaracteristicasContainer>
          <div className="title" onClick={handleSeguridadClick}>
            <img src={showSeguridad ? ArrowDown : ArrowRight} alt="arrow" />
            <p>Seguridad</p>
          </div>
          {showSeguridad && (
            <div className="icons">
              <div className="icon-div">
                <img src={IconSeguridad} alt="iconSeguridad" />
                <div>
                  <p>ABS: {producto.abs === "true" ? "Si" : "No"}</p>
                </div>
              </div>
              <div className="icon-div">
                <img src={IconAirbag} alt="iconAirbag" />
                <div>
                  <p>Airbargs: {producto.airbag === "true" ? "Si" : "No"}</p>
                </div>
              </div>
            </div>
          )}
        </CaracteristicasContainer>
        <CaracteristicasContainer>
          <div className="title" onClick={handleEntretenimientoClick}>
            <img
              src={showEntretenimiento ? ArrowDown : ArrowRight}
              alt="arrow"
            />
            <p>Entretenimiento</p>
          </div>
          {showEntretenimiento && (
            <div className="icons">
              <div className="icon-div">
                <img src={IconRadio} alt="iconRadio" />
                <div>
                  <p>Radio: {producto.radio === "true" ? "Si" : "No"}</p>
                </div>
              </div>
              <div className="icon-div">
                <img src={IconRadio} alt="iconRadio" />
                <div>
                  <p>{producto.entertainment}</p>
                </div>
              </div>
            </div>
          )}
        </CaracteristicasContainer>
      </div>
    </Container>
  );
};
