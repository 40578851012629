import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProductActionMenu from "./ProductActionMenu";
import "../styles.css";
import { BASE_URL } from "../utils/ContextAxios";
import carNoImg from "../images/no-img.jpg";

const NuevosRow = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #d0d4de;
  text-align: left;

  align-items: center;
  justify-content: space-between;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  /* border-color: var(--border-row); */
  .img-car {
    object-fit: cover;
    height: 48px;
    width: 80px;
    left: 0;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  p {
    margin: 0;
    width: 100%;
  }
  .btn-icon {
    border: none;
    background-color: transparent;
  }
`;

const AdminNuevosRow = ({
  productoData,
  openModalDelete,
  openModalView,
  isModalDeleteOpen,
  closeModalDelete,
  updateList
}) => {

  const[ img, setImg] = useState(null)

  useEffect(()=> {
    const onlyImages = productoData.multimedia.filter( elem => (elem.filetype === "image"))
    setImg(onlyImages.length > 0 ? onlyImages[0].path: []) 
    const imgFavorite = onlyImages.filter( elem => (elem.favorite && elem.filetype === "image"))
    if(imgFavorite.length > 0) {
      setImg(imgFavorite[0].path)
    }
  },[])

  return (
    <NuevosRow>
      <div className="">
        <img
          src={`${BASE_URL}/${
            productoData.multimedia && productoData.multimedia.length > 0
              ? productoData.multimedia.find((media) => media.favorite)?.path ||
                productoData.multimedia[0].path
              : "path/to/default-image.jpg"
          }`}
          alt="newRow"
          className="img-car"
        />
      </div>
      <div className="col-3">
        <p className=" title">
          {productoData.brand.name} {productoData.modelo.name}
        </p>
      </div>
      <div className=" col-2">
        <div className="car-type"></div>
        <p>{productoData.version.name}</p>
      </div>
      <div className="">
        <ProductActionMenu
          openModalDelete={openModalDelete}
          openModalView={openModalView}
          producto={productoData}
          closeModalDelete={closeModalDelete}
          isModalDeleteOpen={isModalDeleteOpen}
          updateList={updateList}
        />
      </div>
    </NuevosRow>
  );
};

export default AdminNuevosRow;
