import React, { useState, useRef } from "react";
import styled from "styled-components";
import "../styles.css";
import nave2007 from "../images/nave2007.png";
import nave2012 from "../images/nave2012.png";
import nave2015 from "../images/nave2015.png";
import nave2017 from "../images/nave2017.png";
import nave2020 from "../images/nave2020.png";
import nave2024 from "../images/nave2024.jpg";
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFichero = styled.div`
  /* display: flex;*/
  flex-direction: row;
  align-items: center;
`;

const File = styled.div`
  height: fit-content;
  display: ${(props) => (props.$isSelected ? "flex" : "none")};
  flex-direction: column;
  cursor: pointer;
  transition: width 0.3s;
  align-items: center;
  background-color: var(--prime-color);
  font-family: var(--roboto);
  font-size: 13px;
  line-height: 13px;
  box-shadow: -3px 0px 3px 0px rgb(0 0 0 / 6%);
  border-radius: 6px;
  color: white;
  text-align: center;
  padding: 15px;
  overflow-y: scroll;
`;

const Year = styled.div`
  text-align: center;
  font-weight: 700;
  font-family: var(--font-family);
  font-size: 15px;
  line-height: 15px;
  padding-bottom: 4px;
`;

const FileContent = styled.div`
  /* display: block; */
  padding-top: 4px;
  /* height: 151px; */
  width: 180px;
  padding-bottom: 4px;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.$active ? "blue" : "gray")};
  margin: 0 5px;
  cursor: pointer;
`;
const StyledImage = styled.img`
  width: 100px;
  margin: 10px;
  background-color: #ffffff;
  display: flex;

  padding: ${(props) => (props.paddingEdited ? "0px" : "5px")};
  // width: ${(props) => (props.widthEdited ? "120px" : "170px")};
`;
const ficha = [
  {
    year: "2002",
    text: "Navemotors nace en 2002 en medio de la crisis del 2001 en Argentina. Desde el principio, enfrentando la desconfianza generalizada, iniciamos la venta de vehículos usados y 0km. A lo largo de 21 años, hemos mantenido nuestro compromiso de brindar soluciones a los clientes, destacando la transparencia como un objetivo primordial.",
  },
  {
    year: "2007",
    text: "En 2007, dimos un gran paso al abrir nuestro primer concesionario oficial en Quilmes, abocado a la marca Citroën. Este logro fue el resultado de 5 años de trabajo arduo y una apuesta significativa para expandir el Grupo.",
    image: nave2007,
    paddingEdited: false,
  },
  {
    year: "2012",
    text: "Cinco años después, en 2012, alcanzamos otro hito al inaugurar nuestra segunda concesionaria oficial, Lumiere, especializada en Renault y ubicada también en Quilmes.",
    image: nave2012,
    paddingEdited: false,
  },
  {
    year: "2015",
    text: " A medida que los años avanzaban, el grupo creció, llegando a ser entre las 3 empresas hasta el momento, más de 200 personas trabajando. En 2015, se incorporó la tercera marca oficial, Fiat, con Verona en Avellaneda.",
    image: nave2015,
    paddingEdited: false,
  },
  {
    year: "2017",
    text: "En 2017, celebramos 15 años en la industria, destacando la persistencia del grupo para lograr objetivos. Este año marcó la apertura de la cuarta concesionaria oficial, Freeway automóviles, dedicada a la reconocida marca Jeep en Adrogué, alcanzando un récord de 450 empleados.",
    image: nave2017,
    paddingEdited: false,
  },
  {
    year: "2020",
    text: "El difícil año 2020, marcado por la pandemia de Covid-19, puso a prueba al grupo, casi llevándonos al cierre. Sin embargo, superamos la crisis y, en 2021, inauguramos la concesionaria Peugeot en Ramos Mejía. A pesar de los desafíos, nos destacamos entre los 5 mejores grupos concesionarios de Argentina, obteniendo reconocimientos por calidad y ventas.",
    image: nave2020,
    paddingEdited: false,
  },
  {
    year: "2024",
    text: "En la actualidad, en 2024, seguimos creciendo y aprendiendo día a día. Tras un arduo trabajo, nos lanzamos a Nave.com.ar, dando un salto en la experiencia y satisfacción del cliente.",
    image: nave2024,
    paddingEdited: true,
  },
];

const FicheroMobile = () => {
  const [selectedFile, setSelectedFile] = useState(0);
  const ficheroRef = useRef(null);

  const toggleFileContent = (index) => {
    setSelectedFile(index);
  };

  const handleTouchStart = (event) => {
    ficheroRef.current.startX = event.touches[0].clientX;
  };

  const handleTouchEnd = (event) => {
    const touchEndX = event.changedTouches[0].clientX;
    const startX = ficheroRef.current.startX;
    const touchDiff = startX - touchEndX;
    const threshold = 50;

    if (touchDiff > threshold && selectedFile < 6) {
      setSelectedFile(selectedFile + 1);
    } else if (touchDiff < -threshold && selectedFile > 0) {
      setSelectedFile(selectedFile - 1);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="offset-2 col-8">
          <Container>
            <StyledFichero
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              ref={ficheroRef}
            >
              {ficha.map((item, index) => (
                <File
                  key={index}
                  $isSelected={selectedFile === index}
                  onClick={() => toggleFileContent(index)}
                >
                  <Year>{item.year}</Year>
                  {item.image && (
                    <StyledImage
                      src={item.image}
                      alt={item.year}
                      widthEdited={item.widthEdited}
                      paddingEdited={item.paddingEdited}
                    />
                  )}
                  <FileContent $isSelected={selectedFile === index}>
                    {item.text}
                  </FileContent>
                </File>
              ))}
            </StyledFichero>
            <DotContainer>
              {[...Array(7)].map((_, index) => (
                <Dot
                  key={index}
                  $active={selectedFile === index}
                  onClick={() => toggleFileContent(index)}
                />
              ))}
            </DotContainer>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default FicheroMobile;
