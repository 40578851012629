import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import NavLogo from "../images/NavLogo.png";
import HambIcon from "../images/icon_hamburguer_menu.png";
import IconX from "../images/icon_navbar_close.png";
import styled from "styled-components";
import "../styles.css";

// La barra de navegación queda fija cuando se scroll y reduce su tamaño a 58px!!!

//Navbar sticky y cuando se scrolea cambia su tamaño.

const Container = styled.div`
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
`;

const NavMenu = styled.nav`
  align-items: center;
  display: flex;
  background-color: black;
  justify-content: space-between;
  height: ${(props) => (props.$isScrolling ? "58px" : "114px")};
  transition: height 0.5s ease; /* Agrega una transición suave al cambio de altura */
  width: 100%;
  font-family: var(--font-family);
  font-weight: 600;

  .logo img {
    width: ${(props) => (props.isScrolling ? "135px" : "269px")};
    height: auto;
    transition: all 0.4s ease-in-out;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: ${(props) =>
      props.$isScrolling
        ? "28px"
        : "46px"}; /* Ajusta el tamaño del logo según el estado de desplazamiento */
    list-style: none;
  }

  ul li {
    display: inline; /* Mostrar elementos de lista en línea */
    position: relative;
    height: 100%;
  }
  ul li:hover .sub-menu {
    display: block;
  }

  ul li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
  }

  ul li a:hover {
    color: var(--prime-color);
  }

  ul li a.activeNavLink {
    color: var(--prime-color) !important;
  }

  .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    background-color: transparent;
    z-index: 999;
    padding: 0;
  }

  .sub-menu li {
    display: block;
    text-align: center;
    background-color: var(--gris-claro);
    width: 100%;
    height: 35px;
    margin-bottom: 6px;
    margin-top: 4px;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }
  .sub-menu li:hover {
    background-color: var(--prime-color);
  }

  .sub-menu a {
    color: black;
    width: 100%;
    text-decoration: none;
    padding-top: 5px;
    align-items: center;
  }
  .sub-menu a:hover {
    color: white;
  }
  .btn-hamburger {
    display: none; /* Mostrar el botón de hamburguesa */
  }
  .vehiculos-mobile {
    display: none;
  }
  .close-li {
    display: none;
  }
  /* Media Query para dispositivos móviles */
  @media (max-width: 576px) {
    background-color: black;
    z-index: 999;
    height: 48px;
    transition: none;
    width: 100%;
    padding: 0px 16px;
    align-items: center;

    .logo img {
      width: 127px;
      height: 19px;
    }
    .close-li {
      display: inline;
      cursor: pointer;
    }
    .vehiculos {
      display: none;
    }
    .vehiculos-mobile {
      display: inline;
      font-size: 14px;
      line-height: 14px;
      color: #595e62;
    }
    .financiacion-mobile {
      padding-top: 45px;
    }
    .cotizador-mobile {
      padding-top: 10px;
    }
    .nosotros-mobile {
      padding-bottom: 35px;
    }
    .menu {
      display: ${(props) => (props.$menuOpen ? "flex" : "none")};
      flex-direction: column;
      position: fixed;
      z-index: 999;
      background-color: black;
      width: 50%;
      height: 294px;
      top: 0;
      right: 0;
      justify-content: space-around;
    }

    .sub-menu {
      padding: 0.5rem 0;
      display: flex;
      flex-direction: column;
      background-color: transparent;
    }

    .sub-menu li {
      display: block;
      width: 100%;
      height: 20px;
      font-weight: 400;
      background-color: transparent;
      margin: 4px 0;
      padding-right: 0.5rem;
      font-size: 16px;
      line-height: 16px;
      text-align: right;
    }
    .sub-menu li:hover {
      background-color: black;
    }
    .sub-menu a {
      color: white;
      font-weight: 400;
      text-decoration: none;
    }
    .sub-menu a:hover {
      color: var(--prime-color);
    }
    ul li {
      display: inline;
      height: 20px;
      width: 100%;
      margin: 0;
      padding-right: 0.5rem;
      text-align: right;
    }

    ul li a {
      text-decoration: none;
      color: #ffffff;
      font-size: 16px;
      line-height: 16px;
      display: inline-block;
      font-weight: 400;
    }
    .btn-hamburger {
      display: block;
      background: transparent;
      outline: none;
      border: 0;
      margin-top: 8px;
    }
  }
`;

const Navbar = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolling(scrollTop > 0); // Actualiza el estado según el desplazamiento
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const location = useLocation();
  // Abrir el menú hamburguesa
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Función para determinar si la ruta actual coincide con la ruta del enlace
  const isNavLinkActive = (path) => {
    return location.pathname === path;
  };

  return (
    <Container style={{ backgroundColor: "black" }}>
      <div className="container">
        <div className="row">
          <NavMenu $menuOpen={menuOpen} $isScrolling={isScrolling}>
            <div className="logo col-md-3">
              <NavLink to={"/"}>
                <img src={NavLogo} alt="Logo" />
              </NavLink>
            </div>
            <button className="btn-hamburger" onClick={toggleMenu}>
              <img src={HambIcon} alt="hamburguer" />
            </button>
            <ul className={`col-md-5 menu`}>
              <li className="close-li">
                <img src={IconX} alt="icon-x" onClick={toggleMenu} />
              </li>
              <li>
                <NavLink
                  className={`vehiculos ${
                    isNavLinkActive("") ? "activeNavLink" : ""
                  }`}
                >
                  VEHÍCULOS
                </NavLink>
                <NavLink
                  className={`vehiculos-mobile ${
                    isNavLinkActive("") ? "activeNavLink" : ""
                  }`}
                >
                  Vehículos
                </NavLink>
                <ul className="sub-menu col-12 ">
                  <li>
                    <NavLink
                      to={"/nuevos"}
                      className={
                        isNavLinkActive("/nuevos/:productId")
                          ? "activeNavLink"
                          : ""
                      }
                    >
                      Nuevos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/usados"}
                      className={
                        isNavLinkActive("/usados/:productId")
                          ? "activeNavLink"
                          : ""
                      }
                    >
                      Usados
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="financiacion-mobile">
                <NavLink
                  to={"/financiacion"}
                  className={
                    isNavLinkActive("/financiacion") ? "activeNavLink" : ""
                  }
                >
                  FINANCIACIÓN
                </NavLink>
              </li>
              <li className="cotizador-mobile">
                <NavLink
                  to={"/cotizador"}
                  className={
                    isNavLinkActive("/cotizador") ? "activeNavLink" : ""
                  }
                >
                  COTIZADOR
                </NavLink>
              </li>
              <li className="nosotros-mobile">
                <NavLink
                  to={"/nosotros"}
                  className={
                    isNavLinkActive("/nosotros") ? "activeNavLink" : ""
                  }
                >
                  NOSOTROS
                </NavLink>
              </li>
            </ul>
          </NavMenu>
        </div>
      </div>
    </Container>
  );
};

export default Navbar;
