import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../utils/ContextAxios";
import { ReactComponent as ArrowLeft } from "../images/arrow-right.svg"; 
import { ReactComponent as ArrowRight } from "../images/arrow-left.svg"; 
const StyledContainer = styled.div`
  display: none;
  height: 275px;
  flex-direction: column;
  align-items: center;

  .btn-box {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 576px) {
    display: flex;

    .btn-box{
      border: 1px solid #8d8d8d;
      border-radius: 28px;
      width: 50%;
      height: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: center;
      padding: 0px 0px 3px 0px;
    }
    .botones{
      display:flex;
    }

    .flechita{
      color:white;
      scale:1.25;
    }
  }
`;

const StyledCardContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin: 20px 0px;
  overflow-x: auto;
  cursor: grab;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCard = styled.div`
  width: 140px;
  height: 155px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  padding-right: 0px !important;
  padding-left: 0px !important;
`;

const CardImage = styled.img`
  width: 100%;
  height: 62%;
  object-fit: cover;
  padding: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const InfoWrapper = styled.div`
  margin-top: 8px;
  height: 33%;
  width: 100%;
`;

const Title = styled.h3`
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  margin: 0;
  padding-left: 8px;
`;

const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 14x;
  padding-left: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limita a 1 línea */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const RadioInput = styled.input`
  display: none;
`;

const RadioLabel = styled.label`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: ${(props) => (props.$checked ? "white" : "transparent")};
  margin: 0 5px;
  cursor: pointer;

  @media (max-width: 576px) {
    border: 1px solid #8d8d8d;
    background-color: ${(props) => (props.$checked ? "#8d8d8d" : "transparent")};
  }
`;

const Carousel = ({ sliceData }) => {
  const navigate = useNavigate();

  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dataSlide, setDataSlide] = useState([]);

  const handleClick = (producto) => {

    const ruta = producto.newCar;

    // Definir las rutas de edición para nuevos y usados
    const rutaNuevos = `/nuevos/${producto._id}`;
    const rutaUsados = `/usados/${producto._id}`;

    // Determinar la ruta de edición basada en el valor de 'ruta'
    const rutaProduct = ruta === 1 ? rutaNuevos : rutaUsados;

    navigate(rutaProduct);
  };

  const handleTouchStart = (event) => {
    /* setTouchStartX(event.touches[0].clientX);*/
    setIsDragging(true); 
  };

  const handleTouchMove = (event) => {
    if (!isDragging) return;
    setTouchEndX(event.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    const touchDiff = touchStartX - touchEndX;
    if (touchDiff > 50 && startIndex < sliceData.length - 2) {
      setStartIndex(startIndex + 2);
    } else if (touchDiff < -50 && startIndex > 0) {
      setStartIndex(startIndex - 2);
    }
    setIsDragging(false);
    setTouchStartX(0);
    setTouchEndX(0);
  };

  useEffect(()=>{
    if(sliceData.length>0){
      const aux = sliceData.slice(startIndex, startIndex + 2);
      setDataSlide(aux);
    }
  },[startIndex])

  return (
    <StyledContainer>
      <StyledCardContainer
        ref={containerRef}
        $drag="x"
        $dragConstraints={{ left: 0, right: 0 }}
        $dragElastic={0.8}
        $transition={{ ease: "easeOut", duration: 0.5 }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {dataSlide.map((producto, index) => (
          <StyledCard key={index} onClick={() => handleClick(producto)}>
            <CardImage
              src={`${BASE_URL}/${
                producto.multimedia && producto.multimedia.length > 0
                  ? producto.multimedia.find((media) => media.favorite)?.path ||
                    producto.multimedia[0].path
                  : "path/to/default-image.jpg"
              }`}
              alt="card"
            />
            <InfoWrapper>
              <Title>
                {producto.brand.name} {producto.modelo.name}
              </Title>
              <Description>{producto.version.name}</Description>
            </InfoWrapper>
          </StyledCard>
        ))}
      </StyledCardContainer>
      <div className="btn-box">
      <div className="flechita" onTouchStart={()=> setStartIndex(0)}><ArrowLeft /></div>
      <div className="botones">
        {[0, 1].map((index) => (
          <div key={index}>
            <RadioInput
              type="radio"
              id={`radio-${index}`}
              name="card-selector"
              value={index}
              $checked={startIndex === index * 2}
              //onChange={() => setStartIndex(index * 2)}
            />
            <RadioLabel
              htmlFor={`radio-${index}`}
              $checked={startIndex === index * 2}
            />
          </div>
        ))}
        </div>
        <div className="flechita" onTouchStart={()=> setStartIndex(2)}><ArrowRight /></div>
      </div>
    </StyledContainer>
  );
};

export default Carousel;
